import {defaultHttpResHandler} from "@/store/helpers/defaultHttpResHandler";
import {makeDeleteReq, makeGetReq, makePostReq, makePutReq} from "@/store/helpers/request";
import {appConfig} from "@/lib/appConfig";


export const fetchPresentations = async (token) => await defaultHttpResHandler(async () => await makeGetReq(appConfig.domain + '/presentation', {token}));

export const deletePresentation = async (token, id) => await defaultHttpResHandler(async () => await makeDeleteReq(appConfig.domain + '/presentation/' + id, null, {token}));

export const deletedSlides = async (token, data) => await defaultHttpResHandler(async () => await makeDeleteReq(appConfig.domain + '/presentation', data, {
    token,
    data: true
}));


export const savePresentations = async (token, data) => await defaultHttpResHandler(async () => await makePostReq(appConfig.domain + '/presentation', data, {
    token,
    data: true
}));


export const updatePresentation = async (token, data) => await defaultHttpResHandler(async () => await makePutReq(appConfig.domain + '/presentation', data, {
    token,
    data: true
}));


export const fetchSceneData = async (token, id) => await defaultHttpResHandler(async () => await makeGetReq(appConfig.domain + '/scene/' + id, {token}));


export const startPresentation = async (token, data) => await defaultHttpResHandler(async () => await makePostReq(appConfig.domain + '/scene', data, {
    token,
    data: true
}));



