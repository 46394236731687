import {defaultHttpResHandler} from "@/store/helpers/defaultHttpResHandler";
import {makeGetReq, makePostReq, getVideoReq} from "@/store/helpers/request";
import {appConfig} from "@/lib/appConfig";

export const fetchCalendarEvents = async (token, page, limit, type, id, myEvents, onlyNew) => defaultHttpResHandler(async () => makeGetReq(`${appConfig.domain}/event?page=${page ? page : 1}&limit=${limit ? limit : 10}&type=${type ? type : ''}&id=${id ? id : ''}&myEvents=${myEvents != null ? myEvents : ''}&onlyNew=${onlyNew != null ? onlyNew : ''}`, {token}));

export const fetchEventsForLanding = async (page, limit, type) => defaultHttpResHandler(async () => makeGetReq(`${appConfig.domain}/event_view?page=${page ?? 1}&limit=${limit ?? 10}&type=${type ?? ''}&onlyNew=true`, {}));

export const bookedEvent = async (token, data) => defaultHttpResHandler(async () => makePostReq(`${appConfig.domain}/event/signup`, data, {
    token,
    data: true
}));

export const isVideoRecordingAvailable = async (token, url) => defaultHttpResHandler(async () => getVideoReq(url, {token}));

export const saveEvent = async (token, data) => await defaultHttpResHandler(async () => await makePostReq(`${appConfig.domain}/event`, data, {
    token,
    data: true
}));


export const fetchCurrentEvent = async (token, id) => await defaultHttpResHandler(async () => await makeGetReq(`${appConfig.domain}/event?id=${id}`, {token}));

export const fetchCurrentEventByCode = async (token, code) => await defaultHttpResHandler(async () => await makeGetReq(`${appConfig.domain}/event?enterCode=${code}`, {token}));


export const fetchEventPlacements = async (token, id) => await defaultHttpResHandler(async () => await makeGetReq(`${appConfig.domain}/event/placement/${id}`, {token}));
export const bookingEventPlace = async (token, data) => await defaultHttpResHandler(async () => await makePostReq(`${appConfig.domain}/event/placement`, data, {
    data: true,
    token
}));



