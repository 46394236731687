export function sendYmParams(params = {}) {
  if (!window.ym) {
    return;
  }

  const createNestedObject = (values) => {
    const filteredValues = values.filter(value => value !== '');

    const createNested = (index) => {
      if (index === filteredValues.length - 1) {
        return filteredValues[index];
      }
      return { [filteredValues[index]]: createNested(index + 1) };
    };

    return createNested(0);
  };

  const defaultValues = ['Services', 'meta_vdnh'];
  const allValues = [...defaultValues, ...Object.values(params)];

  const nestedParams = createNestedObject(allValues);

  window.ym(97993667, 'params', nestedParams);
}

export const targetActionsMap = new Map([
  [1, () => sendYmParams({
    par3: 'Header',
    par4: 'Клик',
    par5: 'Кнопка "Посетить МетаВДНХ"'
  })],
  [2, () => sendYmParams({
    par3: 'Header',
    par4: 'Клик',
    par5: 'Иконка "Техническая поддержка"'
  })],
  [3, () => sendYmParams({
    par3: 'Header',
    par4: 'Клик',
    par5: 'Ссылка "Экскурсия с Ваней Дмитриенко"'
  })],
  [4, () => sendYmParams({
    par3: 'Header',
    par4: 'Клик',
    par5: 'Ссылка "Прогулка по МетаВДНХ"'
  })],
  [5, () => sendYmParams({
    par3: 'Header',
    par4: 'Клик',
    par5: 'Ссылка "Часто задаваемые вопросы"'
  })],
  [6, (eventName) => sendYmParams({
    par3: 'Главная страница',
    par4: 'Блок "Ближайшие события"',
    par5: 'Клик',
    par6: 'Кнопка "Записаться"',
    par7: eventName
  })],
  [7, (type) => sendYmParams({
    par3: 'Главная страница',
    par4: 'Блок "Ближайшие события"',
    par5: 'Фильтры по типу экскурсии',
    par6: 'Клик',
    par7: type
  })],
  [8, () => sendYmParams({
    par3: 'Главная страница',
    par4: 'Блок "Виртуальная экскурсия с Ваней Дмитриенко"',
    par5: 'Клик',
    par6: 'Кнопка "Выбрать дату"'
  })],
  [9, () => sendYmParams({
    par3: 'Главная страница',
    par4: 'Блок "Виртуальная экскурсия с Ваней Дмитриенко"',
    par5: 'Клик',
    par6: 'Запуск видео'
  })],
  [10, (cardName) => sendYmParams({
    par3: 'Главная страница',
    par4: 'Блок "Виртуальная экскурсия с Ваней Дмитриенко"',
    par5: 'Клик',
    par6: 'Кнопка "Узнать"',
    par7: cardName
  })],
  [11, () => sendYmParams({
    par3: 'Главная страница',
    par4: 'Блок "Виртуальная экскурсия с Ваней Дмитриенко"',
    par5: 'Слайдер карточек',
    par6: 'Стрелка влево'
  })],
  [12, () => sendYmParams({
    par3: 'Главная страница',
    par4: 'Блок "Виртуальная экскурсия с Ваней Дмитриенко"',
    par5: 'Слайдер карточек',
    par6: 'Стрелка вправо'
  })],
  [13, () => sendYmParams({
    par3: 'Главная страница',
    par4: 'Блок "Прогулка в МетаВДНХ"',
    par5: 'Клик',
    par6: 'Кнопка "Выбрать дату"'
  })],
  [14, () => sendYmParams({
    par3: 'Главная страница',
    par4: 'Блок "Прогулка в МетаВДНХ"',
    par5: 'Клик',
    par6: 'Запуск видео'
  })],
  [15, () => sendYmParams({
    par3: 'Главная страница',
    par4: 'Блок "Без предварительной записи"',
    par5: 'Клик',
    par6: 'Кнопка "Скачать ПО"'
  })],
  [16, (questionName) => sendYmParams({
    par3: 'Главная страница',
    par4: 'Блок "Ответы на часто задаваемые вопросы"',
    par5: 'Клик',
    par7: `Вопрос: ${questionName}`,
  })],
  [17, () => sendYmParams({
    par3: 'Главная страница',
    par4: 'Блок "Ближайшие события"',
    par5: 'Доскролл'
  })],
  [18, () => sendYmParams({
    par3: 'Главная страница',
    par4: 'Блок "Виртуальная экскурсия с Ваней Дмитриенко"',
    par5: 'Доскролл'
  })],
  [19, () => sendYmParams({
    par3: 'Главная страница',
    par4: 'Блок "Прогулка в МетаВДНХ"',
    par5: 'Доскролл'
  })],
  [20, () => sendYmParams({
    par3: 'Главная страница',
    par4: 'Блок "Как попасть на экскурсию"',
    par5: 'Доскролл'
  })],
  [21, () => sendYmParams({
    par3: 'Главная страница',
    par4: 'Блок "Без предварительной записи"',
    par5: 'Доскролл'
  })],
  [22, () => sendYmParams({
    par3: 'Главная страница',
    par4: 'Блок "Ответы на часто задаваемые вопросы"',
    par5: 'Доскролл'
  })],
  [23, (eventName, date) => sendYmParams({
    par3: 'Страница выбора билетов',
    par4: eventName,
    par5: 'Клик на дату',
    par6: date
  })],
  [24, (eventName, date, time) => sendYmParams({
    par3: 'Страница выбора билетов',
    par4: eventName,
    par5: 'Клик на время',
    par6: date,
    par7: time,
  })],
  [25, (eventName, date, time) => sendYmParams({
    par3: 'Страница выбора билетов',
    par4: eventName,
    par5: 'Клик',
    par6: 'Кнопка "Записаться"',
    par7: date,
    par8: time
  })],
  [26, (eventName, date, time, buttonName) => sendYmParams({
    par3: 'Страница выбора билетов',
    par4: eventName,
    par5: 'Клик',
    par6: `Кнопка "${buttonName}"`,
    par7: date,
    par8: time
  })],
  [27, () => sendYmParams({
    par3: 'Техническая поддержка (pop-up)',
    par4: 'Клик',
    par5: 'Кнопка "Отправить"'
  })],
  [28, () => sendYmParams({
    par3: 'Техническая поддержка (pop-up)',
    par4: 'Клик',
    par5: 'Иконка "Закрыть окно"'
  })],
  [29, (eventName) => sendYmParams({
    par3: 'Личный кабинет',
    par4: 'Блок "Ближайшие события"',
    par5: 'Клик',
    par6: 'Кнопка "Записаться"',
    par7: eventName
  })],
  [30, (type) => sendYmParams({
    par3: 'Личный кабинет',
    par4: 'Блок "Ближайшие события"',
    par5: 'Фильтры по типу экскурсии',
    par6: 'Клик',
    par7: type
  })],
  [31, () => sendYmParams({
    par3: 'Личный кабинет',
    par4: 'Блок "ПО"',
    par5: 'Клик',
    par6: 'Кнопка "Скачать ПО"'
  })],
  [32, () => sendYmParams({
    par3: 'Личный кабинет',
    par4: 'Верхняя часть с ФИО',
    par5: 'Клик',
    par6: 'Кнопка "Выйти"'
  })],
  [33, (eventName, date, time) => sendYmParams({
    par3: 'Предыгровая страница (lobby)',
    par4: eventName,
    par5: 'Клик',
    par6: 'Кнопка "Начать"',
    par7: date,
    par8: time
  })],
  [34, () => sendYmParams({
    par3: 'Предыгровая страница (lobby)',
    par4: 'Клик',
    par5: 'Кнопка "Выйти"'
  })],
  [35, (eventName, date, time, buttonName) => sendYmParams({
    par3: 'Личный кабинет',
    par4: eventName,
    par5: 'Клик',
    par6: `Кнопка "${buttonName}"`,
    par7: date,
    par8: time,
  })],
]);

