export function mixAudioWithVideo(videoStream, audioStream) {
  try {
    if (videoStream.getAudioTracks().length && audioStream.getAudioTracks().length) {
      const audioContext = new AudioContext();
      const videoMediaNode = audioContext.createMediaStreamSource(videoStream);
      const audioMediaNode = audioContext.createMediaStreamSource(audioStream);
      const audioGainNode = audioContext.createGain();
      audioGainNode.gain.value = 1;

      videoMediaNode.connect(audioGainNode);
      audioMediaNode.connect(audioGainNode);

      const destinationNode = audioContext.createMediaStreamDestination();
      audioGainNode.connect(destinationNode);
      return new MediaStream([
        ...videoStream.getVideoTracks(),
        ...destinationNode.stream.getAudioTracks()
      ]);
    }

    return new MediaStream([
      ...videoStream.getTracks(),
      ...audioStream.getTracks()
    ]);
  } catch (error) {
    console.error('Error mixing audio with video:', error);
    throw error;
  }
}