<template>
  <div class=""></div>
</template>
<script>
export default {
  name: 'DashboardPage'
}
</script>


<style scoped>

</style>