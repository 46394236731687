import {getShortName} from "@/lib/textParser";

export const messages = {
    actions: {
        async sendNewMessage({getters, commit}, {body, receiver}) {
            getters.webSocket.send(JSON.stringify({
                action: "NEW_MESSAGE",
                body,
                token: getters.userToken,
                type: getters.chatType,
                receiverId: getters.receiverId
            }));
            if (getters.chatType === 'event') return
            commit('addNewMessageInList', {
                avatarUrl: null,
                body,
                createDate: new Date(),
                name: getters.userData?.name,
                self: true,
                userId: getters.userData?.id
            });


            commit('addNewDialogue', {
                userId: getters.userData?.id,
                createDate: new Date(),
                body: body,
                name: getters.userData?.name,
                avatarUrl: null,
                receiverId: (receiver?.id ? receiver.id : (receiver.self ? receiver?.receiverId : receiver?.userId)),
                receiverAvatarUrl: (receiver?.id ? JSON.stringify({headSkin: receiver?.headSkin}) : (receiver.self ? receiver.receiverAvatarUrl : receiver.avatarUrl)),
                receiverName: (receiver?.id ? receiver.name : (receiver.self ? receiver?.receiverName : receiver?.name)),
                self: true
            });
        },

        async fetchDialogues({getters}) {
            if (getters.chatType === 'event') {
                this.dispatch('fetchMessages')
                return;
            }
            getters.webSocket.send(JSON.stringify({
                action: 'GET_DIALOGUES',
                limit: 100,
                page: 1,
                token: getters.userToken
            }))
        },

        async fetchMessages({getters}) {
            getters.webSocket?.send(JSON.stringify({
                action: 'GET_MESSAGES',
                limit: 100, page: 1,
                type: getters.chatType,
                receiverId: getters.receiverId,
                token: getters.userToken,
                slotId: sessionStorage.getItem('slotId')
            }));
        },
        async fetchVote({getters}) {
            getters?.webSocket?.send(JSON.stringify({
                action: 'GET_VOTE',
                eventId: sessionStorage.getItem('eventId'),
                token: getters.userToken
            }));
        },
        async sendChatRequest({getters}, {receiverId, receiverName, type, isAccepted}) {
            getters.webSocket?.send(JSON.stringify({
                action: 'INVITE_CHAT',
                token: getters.userToken,
                receiverId,
                receiverName,
                type,
                isAccepted
            }))
        }

    },
    mutations: {
        addNewMessageInList(state, data) {
            if (data.self) {
                state.messagesList.push(data);
            } else {
                state.messagesList.push(data);
                state.unreadMessages.push(data);
            }
        },
        addNewDialogue(state, data) {
            if (state.chatType === 'event') return;
            const index = state.dialogues.map(item => (item.self ? item.receiverId : item.userId)).indexOf(data.self ? data.receiverId : data.userId);
            if (index !== -1) {
                state.dialogues[index] = data;
            } else {
                state.dialogues.unshift(data);
            }
        },
        addDialogues(state, data) {

            data = data?.map((item) => {
                if (item?.name) {
                    item.name = getShortName(item?.name);
                }

                if (item?.receiverName) {
                    item.receiverName = getShortName(item?.receiverName);
                }

                return item;
            })

            state.dialogues = [...state.dialogues, ...data];
        },
        addMessagesInList(state, data) {
            if (state.receiverId === 'educationTestUser') {
                return;
            }

            if (state.chatType !== 'event' && !data?.length && state.chatReason !== 'request' && state.chatReason !== 'answer') {
                this.commit('setChatInvite', true);
                this.commit('setChatReason', 'request');
                return
            }

            state.messagesList = [...state.messagesList, ...data];
        },
        setChatParams(state, {receiverId, chatType, receiverName}) {
            if (state.receiverId === receiverId) return;
            state.receiverId = receiverId;
            state.receiverName = receiverName;
            state.chatType = chatType;
            state.messagesList = [];
            state.unreadMessages = state.unreadMessages.filter(message => (message.self ? message.receiverId : message?.userId) !== receiverId);
        },
        clearChat(state) {
            state.messagesList = [];
            state.receiverId = null;
            state.chatType = null;
            state.receiverName = null;
        },
        clearDialogues(state) {
            state.dialogues = [];
            state.unreadMessages = [];
        },
        setChatInvite(state, data) {
            state.chatInvite = data;
        },
        setChatReason(state, data) {
            state.chatReason = data;
        },
        resetUnreadMessages(state) {
            state.unreadMessages = [];
        }
    },
    state: {
        messagesList: [],
        unreadMessages: [],
        dialogues: [],
        receiverId: null,
        chatType: null,
        receiverName: null,
        chatInvite: false,
        chatReason: null
    },
    getters: {
        messagesList: state => state.messagesList,
        receiverId: state => state.receiverId,
        chatType: state => state.chatType,
        unreadMessages: state => state.unreadMessages,
        dialogues: state => state.dialogues,
        receiverName: state => state.receiverName,
        chatInvite: state => state.chatInvite,
        chatReason: state => state.chatReason,


    }
}