import badWords from "@/lib/badWords.json";
import {getShortName} from "@/lib/textParser";

const escapeRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

export const cleanBadWord = (text) => {
    let maskedText = text;
    badWords.forEach((word) => {
        const regex = new RegExp(`(?<!\\p{L})${escapeRegExp(word)}(?![\\p{L}\\p{N}_])`, "giu");
        maskedText = maskedText.replace(regex, "*".repeat(word.length));
    });
    return maskedText;
}

export const cleanBadMessage = (msg) => {
    const {name, body, ...rest} = msg;
    const cleanName = cleanBadWord(getShortName(name));
    const cleanBody = cleanBadWord(body);
    return {
        ...rest,
        name: cleanName,
        body: cleanBody,
    };
}
