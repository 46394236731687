import Peer from "peerjs";

export const chat = {
    actions: {
        async dropCall({getters, commit}) {
            if (!getters.userCall && !getters.expectedCall) return;

            getters.webSocket?.send(JSON.stringify(
                {
                    action: 'DROP_CALL',
                    token: getters.userToken,
                    callToUser: getters.userCall?.peer || getters.expectedCall?.peer
                }));
            commit('dropCall');
        }
    },
    mutations: {
        createPeer(state, userId) {
            if (state.myPeer) {
                state.myPeer.reconnect();
                return;
            }
            const peer = new Peer(userId, {
                config: {
                    'iceServers': [
                        {
                            url: 'turn:82.202.199.170',
                            credential: 'chang3me',
                            username: 'unrealD3mo'
                        },
                    ]
                }
            });
            this.commit('initializePeerHandlers', peer);
            state.myPeer = peer;
        },

        createMediaStream(state) {
            navigator?.mediaDevices?.getUserMedia({
                audio: true,
                video: false
            }).then((stream) => {
                state.myMediaStream = stream;
                state.myMediaStream?.getAudioTracks().forEach(track => {
                    track.enabled = false;
                });

                state.myMediaStream?.addEventListener('changeMicroStatus', function (event) {
                    this.getAudioTracks().forEach(track => {
                        track.enabled = event.detail;
                    })
                });

                console.log('Media stream for call, created')
            }).catch((e) => {
                console.log('СТРИМ НЕ СОЗДАН', e);
            });
        },

        initializePeerHandlers(state, peer) {
            peer.on('open', (id) => {
                this.commit('createMediaStream');
                console.log('PEER open', id)
            });
            peer.on('call', (call) => {
                if (sessionStorage.getItem('eventId')) {
                    this.commit('answerEventCalls', call);
                    return;
                }
                if (state.userCall || state.expectedCall) return;
                this.commit('setExpectedCall', call);
            });
            peer.on('error', (err) => {
                console.log(err)
                if (state.userCall) {
                    window.dispatchEvent(new CustomEvent('setCallModalReason', {detail: 'callError'}));
                    this.commit('dropCall');
                }

            })

        },
        callUp(state, {userId, userName}) {
            if (state.myMediaStream) {
                try {
                    if (userId === state.myPeer?.id) return;
                    console.log(`Попытка ПОЗВОНИТЬ ${userId}`);
                    state.userCall = state.myPeer?.call(userId, state.myMediaStream, {metadata: {userName}});
                    state.userCall?.on('stream', (stream) => {
                        if (state.reconnectCount) state.reconnectCount = 0;
                        const audioElement = document.createElement('audio');
                        audioElement.id = userId;
                        audioElement.srcObject = stream;
                        audioElement.addEventListener('loadedmetadata', async () => {
                            await audioElement.play();
                            document.getElementById('audioChatContainer')?.appendChild(audioElement)
                        });
                        window.dispatchEvent(new CustomEvent('setCallModalReason', {detail: 'callSuccess'}));
                    });
                    state.userCall?.on('close', () => {
                        console.log('Завершение звонка callUp', state.userCall)
                    });
                } catch (e) {
                    console.log(`Ошибка при попытке ПОЗВОНИТЬ ${userId}`, e);
                }
            } else {
                if (state.reconnectCount < 3) {
                    setTimeout(() => {
                        state.reconnectCount++;
                        this.commit('callUp', {userId, userName});
                    }, 3000);
                }
            }
        },
        answerEventCalls(state, call) {
            try {
                if (call?.peer === state.myPeer?.id) return;
                console.log(`Попытка ОТВЕТИТЬ (МЕРОПРИЯТИЯ) ${call.peer}`);
                call?.answer(state.myMediaStream);
                call?.on('close', () => {
                    console.log('Завершение звонка (МЕРОПРИЯТИЕ)', call)
                });
            } catch (e) {
                console.log(`Ошибка при попытке ОТВЕТИТЬ (МЕРОПРИЯТИЕ)`, e);
            }
        },

        answerCall(state) {
            if (state.myMediaStream) {
                try {
                    state.userCall = state.expectedCall
                    console.log(`Попытка ОТВЕТИТЬ ${state.userCall.peer}`);
                    state.userCall.answer(state.myMediaStream);
                    state.userCall.on('stream', (stream) => {
                        const audioElement = document.createElement('audio');
                        audioElement.id = state.userCall.peer;
                        audioElement.srcObject = stream;
                        audioElement.addEventListener('loadedmetadata', async () => {
                            await audioElement.play();
                            document.getElementById('audioChatContainer')?.appendChild(audioElement)
                        });
                    });

                    state.userCall.on('close', () => {
                        console.log('Завершение звонка answerCall', state.userCall)
                    });
                    this.commit('setExpectedCall', null);
                } catch (e) {
                    console.log(`Ошибка при попытке ОТВЕТИТЬ`, e);
                }
            }
        },
        dropCall(state, id) {
            if (id) {
                if (state.userCall?.peer !== id) return;
                window.dispatchEvent(new CustomEvent('setCallModalReason', {detail: 'dropCall'}));
            }
            state.userCall?.close();
            state.expectedCall?.close();
            state.expectedCall = null;
            state.userCall = null;
        },
        closeAudioChat(state) {
            this.dispatch('dropCall');
            state.myPeer?.disconnect();
            state.myPeer = null;
            state.myMediaStream?.getAudioTracks().forEach(track => track.stop());
            state.myMediaStream = null;
            console.log('PEER disconnect')
        },

        setExpectedCall(state, val) {
            state.expectedCall = val;
        }

    },
    state: {
        myPeer: null,
        userCall: null,
        myMediaStream: null,
        expectedCall: null,
        reconnectCount: 0

    },
    getters: {
        myMediaStream: state => state.myMediaStream,
        userCall: state => state.userCall,
        expectedCall: state => state.expectedCall
    }
}