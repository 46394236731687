<template>
  <div class="invitation">
    <div class="invitation-filling">
      <!--    <div class="invitation-caption">-->
      <!--      &lt;!&ndash;      <div class="invitation-icon-wrapper">&ndash;&gt;-->
      <!--      &lt;!&ndash;        <img v-if="reason==='callUp'" src="@/assets/icons/game/micro-icon.svg" alt="" class="invitation-icon">&ndash;&gt;-->
      <!--      &lt;!&ndash;        <img v-if="reason==='callError'|| reason==='dropCall'" src="@/assets/icons/game/drop-call.svg" alt=""&ndash;&gt;-->
      <!--      &lt;!&ndash;             class="invitation-icon">&ndash;&gt;-->
      <!--      &lt;!&ndash;      </div>&ndash;&gt;-->
      <!--      <button class="close" @click="cancelCall">Закрыть-->
      <!--        <img src="@/assets/icons/game/close-small.svg" alt="" class="close-icon">-->
      <!--      </button>-->
      <!--    </div>-->
      <p class="invitation-text">
        {{
          reason === 'request' ? (isSendRequest ? 'Ожидаем ответа' : `Пригласить ${receiverName ? receiverName :
            'пользователя'} в чат ?`) : reason === 'answer' ? (`Пользователь ${receiverName} хочет написать вам`) :
          'Пользователь отклонил диалог'
        }}
      </p>

      <!--    <p class="invitation-text" v-if="reason==='callError'">Не удалось связаться с пользователем</p>-->

      <!--    <p class="invitation-text" v-if="reason==='dropCall'">Пользователь завершил звонок</p>-->
      <div class="invitation-activity">
        <button class="activity-close activity-btn" @click="cancelInviteChat" v-if="reason === 'cancel'">
          Ок
        </button>

        <button class="activity-close activity-btn" v-if="reason === 'request'" @click="cancelInviteChat">Отмена</button>
        <button class="activity-invite activity-btn" v-if="!isSendRequest && reason === 'request'"
          @click="sendChatRequest">
          Пригласить
        </button>


        <button class="activity-close activity-btn" v-if="reason === 'answer'" @click="answerInvite(false)">Отклонить
        </button>
        <button class="activity-invite activity-btn" v-if="reason === 'answer'" @click="answerInvite(true)">
          Принять
        </button>
      </div>
    </div>
  </div>
</template>

<script>


import { useStore } from "vuex";
import { ref } from "vue";

export default {
  name: "MessagesChatInvitation",
  props: ['receiverId', 'receiverName', 'myName', 'reason'],
  setup(props, { emit }) {
    const store = useStore();

    const isSendRequest = ref(false);


    const sendChatRequest = () => {
      isSendRequest.value = true;
      store.dispatch('sendChatRequest', {
        receiverId: props.receiverId,
        receiverName: props.myName,
        type: 'request',
      });
    }


    const cancelInviteChat = () => {
      emit('cancelInviteChat');
      if (isSendRequest.value && props.reason !== 'cancel') {
        store.dispatch('sendChatRequest', {
          receiverId: props.receiverId,
          type: 'cancel',
        });
      }
    }


    const answerInvite = (isAccepted) => {
      if (isAccepted) {
        store.commit('setChatParams', {
          receiverId: props.receiverId,
          chatType: 'user',
          receiverName: props.receiverName
        })
      } else {
        store.commit('clearChat');
      }

      store.dispatch('sendChatRequest', {
        receiverId: props.receiverId,
        type: 'answer',
        isAccepted
      });
      emit('closeInviteChat')
    }


    return {
      cancelInviteChat,
      sendChatRequest,
      answerInvite,
      isSendRequest
    }
  }

};
</script>

<style scoped>
.invitation {
  position: absolute;
  padding: 0.62rem;
  border-radius: 0.5rem;
  background: rgba(157, 196, 248, 0.30);
  backdrop-filter: blur(4px);
  z-index: 100000;
  max-width: 31rem;
  width: 100%;
  font-family: "GolosText";
}

.invitation-filling {
  max-width: 100%;
  width: 100%;
  border-radius: 0.5rem;
  background: #FFF;
  padding: 1.5rem;
}

.invitation-caption {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.invitation-icon-wrapper {
  width: 68px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 100%;
}


.close {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #FFF;
  font-size: 17px;
  font-weight: 400;
  line-height: 20px;
}

.close-icon {
  width: 20px;
  height: 20px;
}

.invitation-text {
  text-align: center;
  color: #000;
  font-size: 1.5rem;
  font-weight: 600;
}

.invitation-activity {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.activity-close {
  background: #ECF2FA;
  color: #8B98AA;
}

.activity-btn {
  max-width: 40%;
  width: 100%;
  padding: 0.7rem;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 1rem;
  border-radius: 0.5rem;
}

.activity-invite {
  background: #003099;
  color: #FFFFFF;
}
</style>
