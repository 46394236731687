import {createStore} from 'vuex'
import {user} from "./user";
import {application} from "./application";
import {calendar} from "@/store/calendar";
import {messages} from "@/store/messages";
import {presentations} from "@/store/presentations";
import {chat} from "@/store/chat/chat";
import {record} from "@/store/record"
import {vote} from "@/store/vote"


// creating Vuex store instance
const store = createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        user, application, calendar, messages, presentations, chat, record, vote
    }
});

//store.dispatch('loadUserToken');

export default store;