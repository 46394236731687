import {config, mixAudioWithVideo, stopRecord} from "@/lib/record";
import {v4} from "uuid";

const screenRecorderLogger = (...msg) => {
  if (process.env?.NODE_ENV === "development") {
    console.log(`%c[SCREEN RECORDER] ${msg.join(" ")}`, "color: white; background: blue");
  }
};

export const record = {
  state: {
    recorder: null,
    screenStream: null,
    audioStream: null,
    isRecording: false,
    desktopMicrophone: false,
  },
  getters: {
    isRecording: (state) => state.isRecording,
    desktopMicrophone: (state) => state.desktopMicrophone,
  },
  mutations: {
    setIsRecording(state, value) {
      screenRecorderLogger(`isRecording: ${state.isRecording} -> ${value}`);
      state.isRecording = value;
    },
    setStreamSources(state, {recorder, screenStream, audioStream}) {
      state.recorder = recorder;
      state.screenStream = screenStream;
      state.audioStream = audioStream;
    },
    resetState(state) {
      state.recorder = null;
      state.screenStream = null;
      state.audioStream = null;
      state.isRecording = false;
    },
    setDesktopMicrophoneStatus(state, status) {
      document.dispatchEvent(new CustomEvent('Voice', {detail: status}));
      state.desktopMicrophone = status;
    }
  },
  actions: {
    async startRecording({commit, dispatch}, eventId, turnOffMicrophone) {
      if (!eventId) return;
      screenRecorderLogger("start recording");
      try {
        const screenStream = await navigator.mediaDevices.getDisplayMedia(config.displayMedia);
        const audioStream = await navigator.mediaDevices.getUserMedia(config.userMedia);

        audioStream.getAudioTracks()[0].enabled = turnOffMicrophone;

        const combined = mixAudioWithVideo(screenStream, audioStream);
        const recorder = new MediaRecorder(combined);

        commit("setStreamSources", {screenStream, audioStream, recorder});
        screenRecorderLogger("Recorder started");

        commit("setIsRecording", true);

        recorder.addEventListener("dataavailable", async ({data}) => {
          const download = document.createElement("a");
          download.href = URL.createObjectURL(data);
          download.download = `${v4()}.webm`;
          download.click();
        });

        screenStream.getTracks().forEach((track) => {
          track.addEventListener("ended", () => {
            screenRecorderLogger("Access to the screen has been blocked");
            dispatch("stopRecording");
            // add request for delete file
          }, { once: true });
        });

        recorder.start();
      } catch (error) {
        screenRecorderLogger("stop recording");
        dispatch("stopRecording");
      }
    },
    onMuteMicrophone({state}) {
      if (!state.audioStream) return;
      state.audioStream.getAudioTracks()[0].enabled = false;
    },
    offMuteMicrophone({state}) {
      if (!state.audioStream) return;
      state.audioStream.getAudioTracks()[0].enabled = true;
    },
    closeConnection({getters, commit}) {
      if (getters.isRecording) {
        commit("stopRecording");
      }
    },
    stopRecording({ state, commit }) {
      state.recorder?.stop();
      stopRecord(state.screenStream);
      stopRecord(state.audioStream);
      commit("resetState");
    },
  },
};