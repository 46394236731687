import {useToast} from "vue-toastification";
import router from "@/router";
import store from "@/store";

const toast = useToast();

const notification = {
    params: {
        position: "top-center",
        timeout: 3000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false
    }, successNotification(app, msg) {
        toast.success(msg, this.params);
    }, errorNotification(app, msg) {
        toast.error(msg, this.params);
    }, warningNotification(app, msg) {
        toast.warning(msg, this.params);
    }, infoNotification(app, msg) {
        toast.info(msg, this.params);
    }
};

export function errorHandler(event) {

    // if (event.status && event.status !== 200) {
    //     console.log(event.meta.data);
    // }

    if (event.type === 'error') {
        notification.errorNotification(this, event.message);
    }

    if (event.type === 'success') {
        notification.successNotification(this, 'Успешно')
    }


    if (event?.status === 403 && !(event.meta && event.meta.data && event.meta.data.no_permission && event.meta.data.no_permission === 'already_in_use')) {
        // notification.infoNotification(this, 'Требуется авторизация');
        store.dispatch('logout')
        return;
    }
    if (event?.status === 500) {
        notification.errorNotification(this, 'Error');
        return;
    }

    switch (event.name) {
        case 'subscribe-error':
            notification.errorNotification(this, event.message);
            break
        case 'mos':
            notification.errorNotification(this, 'Ошибка авторизации, попробуйте еще раз');
            break;
        case 'info':
            notification.infoNotification(this, event.message);
            break;
        case 'success':
            notification.successNotification(this, 'Успешно');
            break;
        case 'participantsLimit':
            notification.errorNotification(this, 'Событие достигло лимита участников');
            break;
        case 'supportFormSuccess':
            notification.successNotification(this, event.message);
            break;
        case 'supportFormError':
            notification.errorNotification(this, event.message);
            break;
        case 'tempRegistrationMsg':
            notification.successNotification(this, 'Спасибо за регистрацию! Мы уведомим вас о появлении возможности доступа.');
            break
        case 'lobbyNotFound':
            notification.infoNotification(this, 'Лобии не найдены');
            break
        case 'timeSlotExist':
            notification.infoNotification(this, `Вы уже забронировали слот ${event?.time}`);
            break
        case 'copyLobbyCode':
            if (event.status === 200) {
                notification.successNotification(this, 'Код лобии скопирован');
            } else {
                notification.infoNotification(this, 'Не удалось скопировать код лобии');
            }
            break

        case 'password':
            if (event.statusCode === 'invalid') {
                notification.infoNotification(this, 'Пароль заполнен некорректно');
            }
            if (event.statusCode === 'repeat') {
                notification.infoNotification(this, 'Подтверждение пароля заполнено некорректно');
            }
            break;
        case 'videoRecordingDoesNotExist':
            notification.infoNotification(this, 'Запись мероприятия недоступна');
            break;

        case 'eventBooked':
            if (event.status === 200) {
                notification.successNotification(this, 'Ваше место забронировано');
            } else {
                notification.infoNotification(this, 'Неудалось забронировать место');

            }
            break;
        case 'eventSubscribe':
            if (event.status === 200) {
                notification.successNotification(this, 'Вы записались на мероприятие');
            } else {
                notification.infoNotification(this, 'Неудалось записаться на мероприятие');

            }
            break;
        case 'eventBookedExist':
            notification.warningNotification(this, 'Вы уже выбрали место на данном мероприятии');
            break;


        case 'slotsNotFound':
            notification.infoNotification(this, 'Ваш билет не найден');
            break;

        case 'lockedAccess':
            notification.infoNotification(this, 'Доступ появится позже');
            break;

        case 'eventEnd':
            notification.infoNotification(this, 'Мероприятие закончилось');
            break;

        case 'slotTimeExpired':
            notification.infoNotification(this, 'Время брони закончилось');
            break;

        case 'eventNotStarted':
            notification.infoNotification(this, 'Мероприятие еще не началось');
            break;

        case 'eventStatus':
            notification.infoNotification(this, event?.status === 'notStarted' ? 'Мероприятие не началось' : 'Мероприятие закончилось');
            break;

        case 'LECTOR_ENTERED':
            notification.successNotification(this, 'Лектор присоединился к аудио-чату');
            break;
        case 'LECTOR_EXITED':
            notification.infoNotification(this, 'Лектор отсоединился от аудио-чата');
            break;

        case 'chatConnect':
            if (event.status === 300) notification.warningNotification(this, 'Не удалось подключиться к чату');
            break;

        case 'audioChat':
            if (event.status === 300) notification.warningNotification(this, 'Соединение было потеряно. Перезагрузите страницу');
            break;

        case 'microphone':
            notification.warningNotification(this, 'Нет доступа к микрофону.');
            break;


        case 'registration':
            switch (event.status) {
                case 200:
                    // notification.successNotification(this, 'Успешно');
                    router.push({name: 'authorizationPage'});
                    break;
                case 402:
                    notification.warningNotification(this, 'Пользователь с такой почтой или номером телефона уже существует');
                    break;
                case 300:
                    switch (event.meta.name) {
                        case 'name':
                            notification.infoNotification(this, 'ФИО заполнено некорректно');
                            break;
                        case 'password':
                            notification.infoNotification(this, 'Пароль заполнен некорректно');
                            break;
                        case 'email':
                            notification.infoNotification(this, 'Email заполнен некорректно');
                            break;
                        case 'phone':
                            notification.infoNotification(this, 'Номер телефона заполнен некорректно');
                            break;
                        default:
                            notification.infoNotification(this, 'Не все данные заполнены');
                            break;
                    }

                    break;
                case 401:
                    if (event.meta.data.validation && event.meta.data.validation === 'pass_too_week') {
                        notification.infoNotification(this, 'Слабый пароль');
                    }
                    if (event.meta.data.not_unique && event.meta.data.not_unique === 'email') {
                        notification.infoNotification(this, 'Аккаунт с таким email уже существует');
                    }
                    if (event.meta.data.not_unique && event.meta.data.not_unique === 'phone') {
                        notification.infoNotification(this, 'Аккаунт с таким номером телефона уже существует');
                    }
                    break;

                default:
                    notification.errorNotification(this, 'Error');
            }
            break;

        case 'presentation':
            switch (event.status) {
                case   200:
                    notification.infoNotification(this, 'Презентация началась');
                    break;

                default:
                    notification.errorNotification(this, 'Error');
            }
            break;

        case 'validation':
            switch (event.status) {
                case 300:
                    switch (event.meta.name) {
                        case 'name':
                            notification.infoNotification(this, 'ФИО заполнено некорректно');
                            break;
                        case 'password':
                            notification.infoNotification(this, 'Пароль заполнен некорректно');
                            break;
                        case 'email':
                            notification.infoNotification(this, 'Email заполнен некорректно');
                            break;
                        case 'phone':
                            notification.infoNotification(this, 'Номер телефона заполнен некорректно');
                            break;
                        case 'category':
                            notification.infoNotification(this, 'Категория не выбрана');
                            break;
                        case 'message':
                            notification.infoNotification(this, 'Сообщение пустое');
                            break;
                        case 'sex':
                            notification.infoNotification(this, 'Пол не выбран');
                            break;
                        case 'login':
                            notification.infoNotification(this, 'Логин заполнен некорректно');
                            break;
                        case 'birthDate':
                            notification.infoNotification(this, 'Некорректная дата рождения');
                            break;
                        case 'aboutMe':
                            notification.infoNotification(this, '"О себе" заполнено некорректно');
                            break;


                        default:
                            notification.infoNotification(this, 'Не все данные заполнены');
                            break;
                    }
                    break;
                default:
                    notification.errorNotification(this, 'Error');
            }
            break;

        case 'instance':
            switch (event.status) {
                case 401:
                    notification.infoNotification(this, 'Нет свободных сессий');
                    break;
                case 300:
                    notification.infoNotification(this, 'Сессия открыта в другой вкладке');
                    break;
            }
            break;


        case 'confirmEmail':
            switch (event.status) {
                case 200:
                    notification.successNotification(this, 'Аккаунт подтвержден');
                    router.push({name: 'authorizationPage'})
                    break;

                case 401:
                    if (event.meta && event.meta.data && event.meta.data.not_found && event.meta.data.not_found === 'user') {
                        notification.infoNotification(this, 'Неверный код');
                    }
                    break;
                default:
                    notification.errorNotification(this, 'Error');
            }
            break;

        case 'update':
            switch (event.status) {
                case 200:
                    // notification.successNotification(this, 'Успешно обновлено');
                    if (event.meta) {
                        if (event.meta.routeName) {
                            router.push({name: event.meta.routeName})
                        }
                    }
                    break;
                default:
                    notification.errorNotification(this, 'Error');
            }
            break;
        case 'delete':
            switch (event.status) {
                case 200:
                    // notification.infoNotification(this, 'Удалено');

                    if (event.meta) {
                        if (event.meta.back) {
                            router.push(-1);
                        }
                    }
                    break;
                default:
                    notification.errorNotification(this, 'Error');
            }
            break;

        case 'forms':
            switch (event.status) {
                case 200:
                    if (event.meta.data.already_executed && event.meta.data.already_executed === 'create_discussion') {
                        notification.infoNotification(this, 'Заявка уже отправлена. Повторите попытку позже');
                    }
                    // } else {
                    //     notification.successNotification(this, 'Успешно отправлено');
                    // }
                    break;
                default:
                    notification.errorNotification(this, 'Error');
            }
            break;


        case 'authorization':
            switch (event.status) {
                case 200:
                    // notification.successNotification(this, 'Успешно');
                    router.push({name: 'mainPage'})
                    break;

                case 401:
                    if (event.meta.data.no_permission) {
                        notification.infoNotification(this, 'Аккаунт не подтвержден');
                    }
                    if (event.meta.data.not_found) {
                        notification.infoNotification(this, 'Такого аккаунта не существует');
                    }
                    if (event.meta.data.invalid) {
                        if (event.meta.data.invalid === 'password') {
                            notification.infoNotification(this, 'Неверный пароль');
                        }
                    }
                    break;
                case 403:
                    if (event.meta.data.no_permission) {
                        if (event.meta.data.no_permission === 'unapproved') {
                            notification.infoNotification(this, 'Аккаунт не подтвержден');
                        }
                        if (event.meta.data.no_permission === 'banned') {
                            notification.infoNotification(this, 'Аккаунт заблокирован');
                        }
                        if (event.meta.data.no_permission === 'deleted') {
                            notification.infoNotification(this, 'Аккаунт недоступен');
                        }
                    }
                    break;
                default:
                    notification.errorNotification(this, 'Error');
            }
            break;

        case 'fastAuthorization':
            switch (event.status) {
                case 200:
                    // notification.successNotification(this, 'Успешно');
                    break;
                case 403:
                    if (event.meta.data.no_permission) {
                        if (event.meta.data.no_permission === 'unapproved') {
                            notification.infoNotification(this, 'Аккаунт не подтвержден');
                        }
                        if (event.meta.data.no_permission === 'banned') {
                            notification.infoNotification(this, 'Аккаунт заблокирован');
                        }
                        if (event.meta.data.no_permission === 'deleted') {
                            notification.infoNotification(this, 'Аккаунт недоступен');
                        }
                    }
                    break;
            }
            break;

        case 'save':
            switch (event.status) {
                case 200:
                    // notification.successNotification(this, 'Успешно');
                    if (event.meta) {
                        if (event.meta.routeName) {
                            router.push({name: event.meta.routeName});
                        }
                    }
                    break;
                default:
                    notification.errorNotification(this, 'Error');
            }
            break;
        case 'questWin':
            switch (event.status) {
                case 200:
                    notification.successNotification(this, 'Вы выиграли');
                    break;
                case 300:
                    notification.warningNotification(this, 'Вы не успели');
                    break;
                default:
                    notification.errorNotification(this, 'Error');
            }
            break;
        case 'logout':
            router.push('/');
            break;
    }
}