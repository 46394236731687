<script setup>
// import html2canvas from 'html2canvas';
import { useStore } from 'vuex';
import { ref, computed, onMounted, onBeforeMount } from 'vue';
// import { errorHandler } from "@/lib/errorHandler";


const store = useStore();

const showScoreBoard = computed(() => store.getters.showScoreBoard);

const data = computed(() => store.getters.scoreBoardData);

const listRef = ref();

onMounted(() => {
  window.addEventListener('keydown', onKeyDownEscape);
});

onBeforeMount(() => {
  window.removeEventListener('keydown', onKeyDownEscape);
});

function closeTotal() {
  store.commit('setShowQuestionBoard', true)
  store.commit('setScoreBoardState', null);
}

// async function onShareTotal() {
//   try {
//     const canvas = await html2canvas(listRef.value);
//     const imgData = canvas.toDataURL('image/png');
//     const link = document.createElement('a');

//     link.download = 'result.png';
//     link.href = imgData;
//     link.click();
//   } catch (error) {
//     errorHandler({
//       type: 'error',
//       message: 'Ошибка',
//     });
//   }
// }

function onKeyDownEscape(event) {
  if (event.key === 'Escape') {
    closeTotal();
  }
}
</script>

<template>
  <Teleport to="body">
    <Transition name="fade">
      <div
        v-if="showScoreBoard"
        class="score-board"
      >
        <div class="modal">
          <div ref="listRef" class="list">
            <div
              v-for="({ score, name, id, avatarUrl }, index) in data"
              :key="id"
              class="element"
            >
              <div class="element-content">
                <p class="element__number">{{ index + 1 }}</p>
                <div class="element__border"></div>
                <div class="profile">
                  <img
                    :src="avatarUrl ?? require('@/assets/new-icons/default-avatar.png')"
                    alt=""
                    class="profile__img"
                  >
                  <p class="profile__name">{{ name }}</p>
                </div>
              </div>
              <div class="element-score">
                <img src="@/assets/new-icons/score-blue.svg" alt="" class="element-score__icon">
                <p class="element-score__text">{{ score }}</p>
              </div>
            </div>
          </div>
          <div class="actions">
            <!-- <button class="actions__btn actions__btn--share link-default" @click="onShareTotal">Поделиться</button> -->
            <button class="link-default actions__btn actions__btn--close" @click="closeTotal">Закрыть</button>
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<style scoped>
.score-board {
  padding: 1rem;
  min-width: 50vw;
  position: fixed;
  z-index: 6;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 100dvh;
  align-items: center;
  display: flex;
  background: none;
}

.modal {
  width: 100%;
  margin: auto;
  border-radius: 1.5rem;
  backdrop-filter: blur(80px);
  background: rgba(229, 229, 229, 0.3);
  padding: 1.5rem;
  display: flex;
  flex-direction: column;

}

.list {
  max-height: 70vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  scrollbar-width: thin;
  gap: 0.5rem;
}

.element {
  display: flex;
  gap: 0.5rem;
}

.element-content {
  max-width: 100%;
  width: 100%;
  display: flex;

}

.element__border {
  height: 100%;
  width: 1px;
  backdrop-filter: blur(80px);
  background: rgba(229, 229, 229, 0.3);
}

.element__number {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 130%;
  letter-spacing: 0.01em;
  text-align: center;
  color: #003099;
  max-width: 4.12rem;
  width: 100%;
  border-radius: 1rem 0 0 1rem;
  background: #fff;
}

.profile {
  display: flex;
  align-items: center;
  padding: 0.75rem 1.25rem;
  border-radius: 0 1rem 1rem 0;
  background: #fff;
  max-width: 100%;
  width: 100%;
  gap: 1rem;
}

.profile__img {
  flex: 0 0 4rem;
  width: 4rem;
  height: 4rem;
  object-fit: cover;
  border-radius: 100%;
  border: 2px solid #A5C3F0;
}

.profile__name {
  max-width: 100%;
  font-weight: 500;
  font-size: 1.5rem;
  letter-spacing: -0.01em;
  color: #000;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-wrap: anywhere;
  -webkit-line-clamp: 1;
  word-break: break-all;
}

.element-score {
  display: flex;
  max-width: 9.06rem;
  width: 100%;
  align-items: center;
  background: #fff;
  border-radius: 1rem;
  justify-content: center;
}

.actions {
  margin-top: 1.5rem;
  display: flex;
  gap: 1rem;
}

.actions__btn {
  max-width: 100%;
  width: 100%;
  font-size: 1rem;
}

.actions__btn--close {
  background: #ecf2fa;
  color: #8b98aa;
}
</style>
