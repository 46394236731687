<script setup>
import { useRoute } from 'vue-router';
import { computed, onBeforeMount } from 'vue';
import { useStore } from 'vuex';


const store = useStore();
const route = useRoute();
const src = computed(() => store?.state?.calendar?.currentEvent?.translationUrl);

onBeforeMount(async () => {
    await store.dispatch('fetchCurrentEvent', route.params.id);
})
</script>

<template>
  <div class="block">
    <video v-if="src" controls>
        <source
          :src="src"
          type="video/webm"
        />
      </video>
  </div>
</template>


<style scoped>
.block {
  padding: 1rem 2.5rem;
}
video {
  position: static;
  border: 1px solid black;
}
</style>
