import store from "@/store";
import { i18n } from "@/main";
import { appConfig } from "@/lib/appConfig";
import router from "@/router/index";

// check if user has token - authorized
export const canEnterToUserPortal = async (callback) => {
  i18n.global.locale = localStorage.getItem("locale")
    ? localStorage.getItem("locale")
    : "ru";
  await store.dispatch("loadUserToken");
  callback(store.getters.userToken);
};

export const connectedSSE = (isAvatar) => {
  if (
    appConfig.appState.mode === "prod" &&
    appConfig.appState.isLockedSomeActions &&
    !appConfig.appState.acceptedEmails.includes(store.getters.userData?.email)
  ) {
    router.push({ name: "mainPage" }).then(() => {
      localStorage.setItem("notification", "lockedAccess");
      window.location.reload();
    });
    return;
  }

  // if (store.getters.userData?.id === 'a909cdb4-5845-4a68-b764-15e6d07aeda2') {
  //     if (isAvatar) {
  //         localStorage.setItem('avatar_socket', "stream.metamsk.ru:9008");
  //     } else {
  //         localStorage.setItem('game_socket', "stream.metamsk.ru:9008");
  //     }
  //     return;
  // }
  //
  //
  // if (store.getters.userData?.id === 'c2645ad6-4510-488b-b13f-2893819f33cf') {
  //     if (isAvatar) {
  //         localStorage.setItem('avatar_socket', "stream.metamsk.ru:9009");
  //     } else {
  //         localStorage.setItem('game_socket', "stream.metamsk.ru:9009");
  //     }
  //     return;
  // }

  localStorage.removeItem("game_status");
  store.commit("setLoader", true);

  if (appConfig.isDesktopApp) {
    const desktopSocket = new WebSocket(appConfig.desktopSocket);
    const closeSocket = () => desktopSocket.close();

    desktopSocket.onopen = () => {
      localStorage.setItem(
        isAvatar ? "avatar_socket" : "game_socket",
        appConfig.desktopSocket,
      );
      window.addEventListener("closeLocalWS", closeSocket);
    };

    desktopSocket.onclose = () => {
      window.removeEventListener("closeLocalWS", closeSocket);
    };
    return;
  }

  if (
    !isAvatar &&
    sessionStorage.getItem("soloLobby") !== "true" &&
    sessionStorage.getItem("eventType") !== "recordedTour"
  ) {
    store.commit("createWS");
    if (sessionStorage.getItem("eventType") !== "vdnh_excursion") {
      store.commit("createPeer", store.getters.userData?.id);
    }
  }

  if (
    !isAvatar &&
    sessionStorage.getItem("eventId") &&
    sessionStorage.getItem("eventType") !== "exhibition" &&
    store.getters.userData?.role !== "guide" &&
    store.getters.userData?.role !== "lecture" &&
    !["dedicatedTour", "recordedTour"].includes(
      sessionStorage.getItem("eventType"),
    )
  ) {
    const timer = setInterval(() => {
      if (store.getters?.webSocket?.readyState) {
        store.dispatch("joinEvent", {
          eventId: sessionStorage.getItem("eventId"),
        });
        clearInterval(timer);
      }
    }, 1000);
  } else {
    store.dispatch("fetchInstance", "main").then((socketUrl) => {
      localStorage.setItem(
        isAvatar ? "avatar_socket" : "game_socket",
        socketUrl,
      );
    });
  }
};

const initSocketWithRepeat = async (r = 4) => {
  let repeat = r;

  const socket = new WebSocket(appConfig.desktopSocket);

  return await new Promise((resolve) => {
    appConfig.isDesktopAppChecked = true;

    socket.onopen = () => {
      localStorage.setItem("isDesktopApp", "true");
      document.dispatchEvent(new CustomEvent("isDesktopApp", {
        detail: true,
      }));
      socket.close();
      resolve(true);
    };

    socket.onerror = () => {
      if (repeat) {
        const timeout = setTimeout(() => {
          resolve(initSocketWithRepeat(repeat - 1));
          clearTimeout(timeout);
        }, 3000);
      }

      localStorage.setItem("isDesktopApp", "false");
      document.dispatchEvent(new CustomEvent("isDesktopApp", {
        detail: false,
      }));
      resolve(false);
    };
  });
};

export const checkIsDesktopApp = async () => {
  if (appConfig.isDesktopAppChecked) return;

  return await initSocketWithRepeat();
};

export const setDesktopStatus = (value) => {
  appConfig.isDesktopApp = value;
};
