<script setup>
import { computed, ref, watch, defineEmits } from "vue";
import { useStore } from "vuex";
import { characterModels, clothesColors, defaultModel, parseModelLegs, parseModelTorso } from "@/lib/userModelSkins";

const emit = defineEmits(['close']);

const store = useStore();


const user = computed(() => store.getters.userData);

const sideData = ref(JSON.parse(user.value.sideData));

const currentChangeModel = ref(null);

const userModel = ref({
  SkinHead: sideData.value?.userModel?.SkinHead ?? defaultModel.SkinHead,
  SkinTorso: sideData.value?.userModel?.SkinTorso ?? defaultModel.SkinTorso,
  SkinLegs: sideData.value?.userModel?.SkinLegs ?? defaultModel.SkinLegs,
  SkinShoes: sideData.value?.userModel?.SkinShoes ?? defaultModel.SkinShoes,
  Sex: sideData.value?.userModel?.Sex ?? defaultModel.Sex,
  Character: ""
});

const currentModelSplit = computed(() => {
  if (!modelSubType.value) return [];

  return userModel.value[modelSubType.value]?.split('-');
});


const userHeadIndex = computed({
  get: () => Number(userModel.value?.SkinHead?.split('-')[0]),
  set: (val) => {
    if (userModel.value.Sex === '1') {
      userModel.value.SkinHead = `${val}-${userHairIndex.value}`;
      if (userLegsIndex.value === 9) {
        userLegsIndex.value = Number(`${userLegsIndex.value}`);
      }
    } else {
      userModel.value.SkinHead = `${val}-${userHairIndex.value}-${userUsiIndex.value}-${userBorodaIndex.value}`;
    }

    userTorsoIndex.value = Number(`${userTorsoIndex.value}`);
  }
});

const userHairIndex = computed({
  get: () => Number(userModel.value?.SkinHead?.split('-')[1]),
  set: (val) => {
    if (userModel.value.Sex === '1') {
      userModel.value.SkinHead = `${userHeadIndex.value}-${val}`;
    } else {
      userModel.value.SkinHead = `${userHeadIndex.value}-${val}-${userUsiIndex.value}-${userBorodaIndex.value}`;
    }
  }
});

const userUsiIndex = computed({
  get: () => userModel.value?.Sex === '0' ? Number(userModel.value?.SkinHead?.split('-')[2] ?? 0) : null,
  set: (val) => {
    userModel.value.SkinHead = `${userHeadIndex.value}-${userHairIndex.value}-${val}-${userBorodaIndex.value}`;
  }
});

const userBorodaIndex = computed({
  get: () => userModel.value?.Sex === '0' ? Number(userModel.value?.SkinHead?.split('-')[3] ?? 0) : null,
  set: (val) => {
    userModel.value.SkinHead = `${userHeadIndex.value}-${userHairIndex.value}-${userUsiIndex.value}-${val}`;
  }
});

const userTorsoIndex = computed({
  get: () => Number(userModel.value?.SkinTorso?.split('-')[0]),
  set: (val) => {
    userModel.value.SkinTorso = parseModelTorso(val, userHeadIndex.value === 20 ? 10 : userHeadIndex.value);
    if (currentChangeModel.value?.index !== val) currentChangeModel.value = null;
  }
});

const userLegsIndex = computed({
  get: () => Number(userModel.value?.SkinLegs?.split('-')[0]),
  set: (val) => {
    userModel.value.SkinLegs = parseModelLegs(val, userHeadIndex.value, userModel.value.SkinLegs);
    if (currentChangeModel.value?.index !== val) currentChangeModel.value = null;
  }
});

const userShoesIndex = computed({
  get: () => Number(userModel.value?.SkinShoes?.split('-')[0]),
  set: (val) => {
    userModel.value.SkinShoes = `${val}-0`;
    if (currentChangeModel.value?.index !== val) currentChangeModel.value = null;

  }
});


const reserveSkin = ref({ ...userModel.value });

const skinIsChanged = ref(false);

const modelType = ref('face');

const subTypes = computed(() => modelType.value === 'face' ? (userModel.value.Sex === '1' ? ['head', 'hair'] : ['head', 'hair', 'boroda', 'usi']) : modelType.value === 'body' ? ['SkinTorso', 'SkinLegs', 'SkinShoes'] : []);

const modelSubType = ref('head');

const modelValues = computed(() => {
  if (!modelType.value) return [];

  if (!subTypes.value?.length) {
    return characterModels[modelType.value];
  }

  return characterModels[modelType.value][modelSubType.value]?.filter(item => item?.sex === 2 || `${item?.sex}` === userModel.value?.Sex) ?? [];
})


function sendConstructCharacterInUnreal() {
  document.dispatchEvent(
    new CustomEvent("ConstructCharacter", {
      detail: JSON.stringify(userModel.value)
    }));
}


// function changeAvatarZoom(isInZoom = true) {
//   document.dispatchEvent(new Event(isInZoom ? 'ZoomIn' : 'ZoomOut'))
// }

// function changeAvatarRotate(event) {
//   document.dispatchEvent(new CustomEvent('RotateChar', { detail: event?.target?.value }))
// }

function resetChanges() {
  userModel.value = { ...reserveSkin.value };
  setTimeout(() => {
    skinIsChanged.value = false;
  }, 100);
}

function saveUserAvatar() {
  const userData = JSON.parse(JSON.stringify(user.value));

  if (!sideData.value) sideData.value = {};
  sideData.value["userModel"] = { ...userModel.value };

  userData.sideData = JSON.stringify(sideData.value);

  store.dispatch("saveUserData", userData)
    .then(() => {
      store.dispatch("fetchUserData").then(() => {
        document.dispatchEvent(new Event("SkinConstructionComplete"));
        emit('close');
      })
    });
}

function pickMaterialColor(bodyIndex, materialIndex, colorIndex) {
  const modelSplit = [...currentModelSplit.value];
  modelSplit[materialIndex] = colorIndex
  userModel.value[modelSubType.value] = modelSplit?.join('-');
}


watch(modelType, (value) => {
  modelSubType.value = (value === 'face' ? 'head' : 'SkinTorso');
});

watch(modelSubType, () => {
  currentChangeModel.value = null;
})

watch(() => [
  userModel.value?.Sex,
  userModel.value?.SkinHead,
  userModel.value?.SkinTorso,
  userModel.value?.SkinLegs,
  userModel.value?.SkinShoes
], (value, oldValue) => {
  if (!skinIsChanged.value) {
    skinIsChanged.value = true;

  }

  if (value[0] !== oldValue[0]) {

    userModel.value.SkinHead = value[0] === '1' ? '10-0' : '0-0-0-0';
    userTorsoIndex.value = value[0] === '1' ? 14 : 0;
    userLegsIndex.value = value[0] === '1' ? 4 : 0;
    userShoesIndex.value = 0;
  }

  sendConstructCharacterInUnreal();
}, { deep: true });

watch(currentChangeModel, (val) => {
  if (val == null) return;

  if (modelSubType.value === 'SkinTorso' && userTorsoIndex.value !== val) {
    userTorsoIndex.value = val;
  }
  if (modelSubType.value === 'SkinLegs' && userLegsIndex.value !== val) {
    userLegsIndex.value = val;
  }
  if (modelSubType.value === 'SkinShoes' && modelSubType.value !== val) {
    userShoesIndex.value = val;
  }
})
</script>
<template>
  <section class="avatar section">
    <div class="avatar-filling ">
      <div class="constructor">
        <div class="constructor-head">
          <div class="sex">
            <label class="sex-element">
              <input v-model="userModel.Sex" value="0" name="sex" :checked="userModel.Sex === '0'" type="radio"
                class="sex-element__input">
              <img src="@/assets/new-icons/man.svg" alt="" class="sex-element__icon">
              <span class="sex-element__bg"></span>
            </label>
            <label class="sex-element ">
              <input v-model="userModel.Sex" :checked="userModel.Sex === '1'" value="1" name="sex" type="radio"
                class="sex-element__input">
              <img src="@/assets/new-icons/women.svg" alt="" class="sex-element__icon">
              <span class="sex-element__bg"></span>
            </label>
          </div>
          <div class="settings">
            <label class="settings-element">
              <input type="radio" name="settings" v-model="modelType" value="face" class="settings-element__input">
              <svg class="settings-element__icon settings-element__icon--face" viewBox="0 0 31 29" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <ellipse cx="15.1041" cy="14.5" rx="12.2941" ry="14.5" fill="#003099" />
                <ellipse cx="27.3983" cy="14.8455" rx="2.81008" ry="4.4881" fill="#003099" />
                <ellipse cx="2.81008" cy="14.8455" rx="2.81008" ry="4.4881" fill="#003099" />
                <path d="M17.8262 13.6757V10.875H21.5144" stroke="#F3F4F6" stroke-width="1.2" />
                <path d="M12.2816 13.6758V10.8008H8.83153" stroke="#F3F4F6" stroke-width="1.2" />
                <path
                  d="M18.4411 18.125C18.4411 18.601 18.3457 19.0724 18.1604 19.5122C17.975 19.952 17.7034 20.3516 17.3609 20.6883C17.0184 21.0249 16.6118 21.2919 16.1643 21.4741C15.7169 21.6562 15.2373 21.75 14.7529 21.75C14.2686 21.75 13.789 21.6562 13.3415 21.4741C12.894 21.2919 12.4874 21.0249 12.145 20.6883C11.8025 20.3516 11.5308 19.952 11.3454 19.5122C11.1601 19.0724 11.0647 18.601 11.0647 18.125H12.3099C12.3099 18.4403 12.3731 18.7526 12.4958 19.0439C12.6186 19.3352 12.7986 19.5999 13.0254 19.8229C13.2523 20.0458 13.5216 20.2227 13.818 20.3434C14.1144 20.4641 14.4321 20.5262 14.7529 20.5262C15.0737 20.5262 15.3914 20.4641 15.6878 20.3434C15.9842 20.2227 16.2536 20.0458 16.4804 19.8229C16.7073 19.5999 16.8872 19.3352 17.01 19.0439C17.1328 18.7526 17.196 18.4403 17.196 18.125H18.4411Z"
                  fill="#F3F4F6" />
              </svg>
              <p class="settings-element__text">Лицо</p>
              <div class="settings-element__bg"></div>
            </label>
            <label class="settings-element">
              <input type="radio" name="settings" v-model="modelType" value="body" class="settings-element__input">
              <svg class="settings-element__icon settings-element__icon--body" viewBox="0 0 19 27" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M9.49999 6.07499C11.1864 6.07499 12.5535 4.71507 12.5535 3.03749C12.5535 1.35993 11.1864 0 9.49999 0C7.8136 0 6.44654 1.35993 6.44654 3.03749C6.44654 4.71507 7.8136 6.07499 9.49999 6.07499ZM18.9454 7.72225C19.1552 8.43802 18.7419 9.18747 18.0223 9.39605C16.4104 9.86335 14.9541 10.2152 13.5713 10.4498V18.2212V18.225V25.6499C13.5713 26.3717 13.0005 26.9659 12.2757 26.9986C11.5508 27.0313 10.9283 26.491 10.8627 25.7722L10.1841 18.3472C10.1804 18.3064 10.1785 18.2656 10.1785 18.225H8.82145C8.82145 18.2656 8.81962 18.3064 8.81588 18.3472L8.13734 25.7722C8.07165 26.491 7.44916 27.0313 6.72433 26.9986C5.99951 26.9659 5.42872 26.3717 5.42872 25.6499V10.4249C4.0495 10.1883 2.59836 9.8435 0.994619 9.40084C0.272374 9.20151 -0.150632 8.45746 0.049743 7.73906C0.250186 7.02059 0.998079 6.5998 1.72032 6.79913C4.84727 7.66225 7.19687 8.08952 9.5059 8.09978C11.8102 8.11004 14.1539 7.70524 17.2628 6.80392C17.9824 6.59528 18.7358 7.00642 18.9454 7.72225Z"
                  fill="#003099" />
              </svg>
              <p class="settings-element__text">Тело</p>
              <div class="settings-element__bg"></div>
            </label>
          </div>
        </div>
        <div class="content">

          <!-- <div class="user-wrapper">
            <div class="user-filling">
              <div class="user">
                <div class="controls">
                  <button class="controls__btn" @click="changeAvatarZoom(true)">
                    <img src="@/assets/new-icons/plus.svg" alt="" class="controls__ico">
                  </button>
                  <button class="controls__btn" @click="changeAvatarZoom(false)">
                    <img src="@/assets/new-icons/minus.svg" alt="" class="controls__ico">
                  </button>
                </div>
              </div>

            </div>
          </div> -->
          <div class="changes" v-if="modelType">
            <div class="changes-caption">
              <label class="changes-caption-element" v-for="item in subTypes" :key="item">
                <input name="changes-caption" :value="item" v-model="modelSubType" type="radio"
                  class="changes-caption-element__input">
                <svg v-if="item === 'SkinTorso'" viewBox="0 0 32 30" fill="none" xmlns="http://www.w3.org/2000/svg"
                  class="changes-caption-element__icon">
                  <path
                    d="M27.8201 3.82023C27.6501 3.6002 27.43 3.43022 27.17 3.32023L20.9601 0.800212C20.55 0.640232 20.06 0.640232 19.67 0.870222C19.2101 1.1002 18.92 1.59019 18.88 2.1002C18.68 2.37022 17.66 2.9202 16 2.9202C14.3401 2.9202 13.3201 2.37022 13.12 2.1002C13.1 1.1002 11.97 0.390242 11.06 0.790202L4.83006 3.32023C4.57005 3.43022 4.35002 3.6002 4.18004 3.82023L0.790024 8.4202C0.410024 8.94022 0.400074 9.65018 0.780074 10.1802L4.45005 15.3102C4.94004 16.0202 6.03007 16.1402 6.66001 15.5502L7.41001 27.9102C7.45005 28.7002 8.11002 29.3202 8.90006 29.3202H23.1C23.8901 29.3202 24.55 28.7002 24.5901 27.9102L25.3401 15.5502C25.97 16.1402 27.06 16.0202 27.55 15.3102L31.22 10.1802C31.6 9.65018 31.5901 8.94022 31.2101 8.4202L27.8201 3.82023Z"
                    fill="#003099" />
                </svg>
                <svg v-if="item === 'SkinShoes'" class="changes-caption-element__icon" viewBox="0 0 32 32" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M27 24.1482C27 24.12 26.9971 24.0923 26.9919 24.0659C26.9642 17.8007 23.7793 17.5025 20.9683 17.2397C19.3396 17.087 17.8014 16.9427 16.6895 15.8309C13.5303 12.6699 14.7558 5.55083 14.7684 5.47913C14.7916 5.34957 14.7505 5.21635 14.658 5.12265C14.5656 5.02894 14.4349 4.98372 14.3037 5.00531L11.2296 5.49054C11.0496 5.51905 10.9103 5.66368 10.8887 5.84539L10.7482 7.03705H5.40809C5.18286 7.03705 5.00081 7.21957 5.00081 7.44446V24.1441C5.00081 24.1457 5 24.1469 5 24.1482V26.5926C5 26.8179 5.18205 27 5.40727 27H7.4477C7.67292 27 7.85497 26.8179 7.85497 26.5926V25.7778H8.25817V26.5926C8.25817 26.8179 8.44022 27 8.66544 27H10.7018C10.927 27 11.1091 26.8179 11.1091 26.5926V25.7778H11.5163V26.5926C11.5163 26.8179 11.6984 27 11.9236 27H16.4036C16.6288 27 16.8109 26.8179 16.8109 26.5926V25.7778H17.2181V26.5926C17.2181 26.8179 17.4002 27 17.6254 27H19.6618C19.887 27 20.069 26.8179 20.069 26.5926V25.7778H20.4763V26.5926C20.4763 26.8179 20.6584 27 20.8836 27H22.92C23.1452 27 23.3272 26.8179 23.3272 26.5926V25.7778H23.7304V26.5926C23.7304 26.8179 23.9125 27 24.1377 27H26.5813C26.8061 27 26.9882 26.8183 26.9886 26.5934L26.9927 24.184C26.9939 24.1714 27 24.1608 27 24.1482ZM24.545 26.1852V25.3704C24.545 25.1451 24.3629 24.963 24.1377 24.963H22.92C22.6947 24.963 22.5127 25.1451 22.5127 25.3704V26.1852H21.2909V25.3704C21.2909 25.1451 21.1088 24.963 20.8836 24.963H19.6618C19.4366 24.963 19.2545 25.1451 19.2545 25.3704V26.1852H18.0327V25.3704C18.0327 25.1451 17.8506 24.963 17.6254 24.963H16.4036C16.1784 24.963 15.9963 25.1451 15.9963 25.3704V26.1852H12.3309V25.3704C12.3309 25.1451 12.1488 24.963 11.9236 24.963H10.7018C10.4766 24.963 10.2945 25.1451 10.2945 25.3704V26.1852H9.07272V25.3704C9.07272 25.1451 8.89067 24.963 8.66544 24.963H7.4477C7.22248 24.963 7.04043 25.1451 7.04043 25.3704V26.1852H5.81454V24.5556H26.1773L26.1749 26.1852H24.545Z"
                    fill="#003099" />
                </svg>
                <svg v-if="item === 'SkinLegs'" class="changes-caption-element__icon" viewBox="0 0 32 32" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M17.2797 1.81615H8.9733V5.54268L3.93091 29.2936L10.2345 31.1846L15.4629 12.2729L17.2797 1.81615Z"
                    fill="#003099" />
                  <path
                    d="M13.7202 1.81615H22.0266V5.54268L27.069 29.2936L20.7654 31.1846L15.537 12.2729L13.7202 1.81615Z"
                    fill="#003099" />
                  <path d="M22.0263 5.0791H8.97363V3.44752H22.0263V5.0791Z" fill="#D4D5D5" />
                  <path d="M16.3158 11.6055H14.6842L11.421 9.97389H19.5789L16.3158 11.6055Z" fill="#D4D5D5" />
                  <path
                    d="M10.6052 4.26275H12.2368V1.81538H10.6052V4.26275ZM18.7631 4.26275H20.3947V1.81538H18.7631V4.26275ZM13.8684 10.7891H15.5V5.07854H13.8684V10.7891Z"
                    fill="#D4D5D5" />
                </svg>
                <svg v-if="item === 'body'" class="changes-caption-element__icon" viewBox="0 0 32 32" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M23.0588 28H19.8235L16 20.3902L12.1765 28H8.94118C8.94118 28 9.02351 25.0732 9.52942 22.439C9.93223 20.3417 11 17.1707 11 17.1707V11.0244L8.05883 12.1951L6 8.97561L10.2938 4.70279C10.7455 4.2533 11.3637 4 12.0091 4H13.9412V5.21216C13.9412 6.32079 14.8629 7.21951 16 7.21951C17.137 7.21951 18.0588 6.32079 18.0588 5.21216V4H19.7369C20.3585 4 20.956 4.23504 21.4035 4.65568L26 8.97561L23.9412 12.1951L21 11.0244V17.1707C21 17.1707 22.0678 20.3417 22.4706 22.439C22.9765 25.0732 23.0588 28 23.0588 28Z"
                    fill="#003099" />
                  <path
                    d="M21 17.1707H15.4117M21 17.1707V11.0244L23.9412 12.1951L26 8.97561L21.4035 4.65568C20.956 4.23504 20.3585 4 19.7369 4H18.0588V5.21216C18.0588 6.32079 17.137 7.21951 16 7.21951C14.8629 7.21951 13.9412 6.32079 13.9412 5.21216V4H12.0091C11.3637 4 10.7455 4.2533 10.2938 4.70279L6 8.97561L8.05883 12.1951L11 11.0244V17.1707M21 17.1707C21 17.1707 22.0678 20.3417 22.4706 22.439C22.9765 25.0732 23.0588 28 23.0588 28H19.8235L16 20.3902L12.1765 28H8.94118C8.94118 28 9.02351 25.0732 9.52942 22.439C9.93223 20.3417 11 17.1707 11 17.1707M11 17.1707H15.7059"
                    stroke="#003099" stroke-width="1.06829" />
                </svg>
                <svg v-if="item === 'head'" class="changes-caption-element__icon" viewBox="0 0 32 32" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M3.70921 18.9556L5.46045 20.2305C5.70302 20.407 5.7285 20.7593 5.51387 20.969C5.36193 21.1174 5.32432 21.3475 5.42111 21.5365L5.76175 22.202C5.86305 22.3999 5.85684 22.6358 5.74525 22.8281C5.58211 23.1092 5.65027 23.4675 5.90525 23.6691L6.0961 23.8199C6.26581 23.9541 6.38372 24.143 6.42972 24.3544L6.59464 25.1123C6.70568 25.6226 6.9442 26.0964 7.28793 26.4895C7.87487 27.1608 8.72313 27.5459 9.61484 27.5459H10.4012C11.7716 27.5459 13.0773 28.1289 13.9921 29.1492L14.3872 29.5898C14.6175 29.8467 14.9593 29.9741 15.3016 29.9308L24.3733 28.7824C24.8829 28.7179 25.265 28.2843 25.265 27.7706C25.265 25.0436 25.782 22.3414 26.7886 19.8071L28.0437 16.647C28.4282 15.6789 28.6675 14.6593 28.7539 13.6213C28.9148 11.6866 28.54 9.74495 27.6704 8.00925L27.3894 7.4485C26.7718 6.21565 25.8963 5.12996 24.8224 4.26499C23.211 2.96706 21.2241 2.22316 19.1565 2.14365L17.7302 2.0888C16.196 2.02981 14.6602 2.15158 13.1545 2.45161L12.9455 2.49325C11.4587 2.78951 10.0815 3.48751 8.96347 4.51144C7.70175 5.66697 6.82813 7.18434 6.46229 8.85568L6.10649 10.4812C5.98537 11.0345 5.92428 11.5993 5.92428 12.1658V12.9873C5.92428 14.4034 5.40043 15.7695 4.45359 16.8226L3.58652 17.787C3.27284 18.1358 3.32992 18.6795 3.70921 18.9556Z"
                    fill="#003099" />
                </svg>
                <svg v-if="item === 'hair'" class="changes-caption-element__icon" viewBox="0 0 32 32" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M4.38824 20.8678L5.79321 25.5579C5.86698 25.8042 6.12311 25.9472 6.37145 25.8808C6.57942 25.8252 6.72407 25.6368 6.72407 25.4215V23.8973C6.72407 23.0424 6.84922 22.192 7.09555 21.3734L7.45205 20.1885L7.90861 18.7816C7.96944 18.5942 8.04503 18.4118 8.13467 18.2363C8.3985 17.7197 8.97014 17.4376 9.54066 17.5424L9.74503 17.5799C9.82877 17.5953 9.91114 17.6173 9.99134 17.6459L10.7807 17.9269C12.0745 18.3875 13.4378 18.623 14.8111 18.623H17.1272H20.7223C20.8772 18.623 21.0318 18.6105 21.1847 18.5858L22.3478 18.3973C22.9903 18.2933 23.62 18.6446 23.8689 19.2461L23.8954 19.3103C24.0328 19.6422 24.1959 19.9629 24.3834 20.2693C25.0439 21.3489 25.3933 22.5898 25.3933 23.8553V25.2158C25.3933 25.4147 25.5213 25.591 25.7104 25.6526C25.9413 25.7279 26.1909 25.6104 26.28 25.3845L27.187 23.0871C27.4929 22.3122 27.6991 21.5017 27.8006 20.6748L27.8605 20.1865C27.9529 19.434 27.9201 18.6714 27.7636 17.9296L27.7106 17.6782C27.5001 16.6805 27.0346 15.7543 26.3596 14.9901L25.8814 14.4486C25.5426 14.0649 25.7518 13.4583 26.2551 13.3651C26.3675 13.3443 26.4726 13.2945 26.5599 13.2208L27.1519 12.721C27.5046 12.4232 27.3703 11.8509 26.9218 11.7412L23.4442 10.8901L25.1844 10.4228C25.645 10.2991 25.5866 9.62786 25.1115 9.58552L22.2144 9.17718C21.9131 9.13472 21.8674 8.71833 22.1523 8.61157C22.522 8.47307 22.4227 7.92509 22.0279 7.92509H21.5116C21.2193 7.92509 20.9274 7.94818 20.6387 7.99414L17.8754 8.43405C17.6096 8.47638 17.4722 8.12766 17.6955 7.97728C18.1328 7.68278 17.9244 7 17.3971 7H17.1272H16.1489C15.9091 7 15.6697 7.01849 15.4327 7.05531L14.7282 7.16479C13.4055 7.37034 12.1148 7.74567 10.8881 8.28148C9.82924 8.74399 8.94074 9.52485 8.34609 10.5155L7.81706 11.3969C7.57822 11.7948 7.45205 12.2501 7.45205 12.7142V14.14C7.45205 14.5896 7.12269 14.9714 6.67794 15.0373C6.04281 15.1314 5.46564 15.4591 5.05936 15.9563L5.03711 15.9835C4.66539 16.4384 4.42012 16.9832 4.326 17.5631L4.21674 18.2363C4.07411 19.1152 4.13275 20.0149 4.38824 20.8678Z"
                    fill="#003099" />
                </svg>
                <svg v-if="item === 'usi'" class="changes-caption-element__icon" viewBox="0 0 32 32" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M30.519 20.1013C29.9974 20.1127 29.5214 20.3099 28.9617 19.8955C30.9797 19.1259 31.654 16.6741 30.2783 14.9407C30.2413 14.8943 30.1967 14.8849 30.1547 14.895C30.095 14.896 30.0344 14.9254 30.0064 14.9939C29.45 16.3569 28.1271 17.3835 26.6044 17.042C25.3531 16.7613 24.4165 15.8036 23.6272 14.8502C22.1855 13.1088 20.7522 11.437 18.3956 11.0386C17.2516 10.8451 16.4163 11.3985 16 12.2517C15.5837 11.3985 14.748 10.8451 13.6044 11.0386C11.2478 11.437 9.81447 13.1088 8.37284 14.8502C7.58352 15.8036 6.64687 16.7613 5.39557 17.042C3.87294 17.3835 2.54997 16.3569 1.99364 14.9939C1.96565 14.9254 1.90498 14.896 1.84531 14.895C1.80365 14.8849 1.75865 14.8943 1.72165 14.9407C0.346015 16.6741 1.02033 19.1262 3.03829 19.8955C2.4783 20.3099 2.00264 20.1124 1.48099 20.1013C1.36699 20.099 1.39099 20.3089 1.51699 20.3764C3.11262 21.2295 5.79656 21.2165 7.48352 19.8246C7.31553 20.1394 7.14786 20.4542 6.97987 20.769C6.92487 20.8723 7.01953 21.0434 7.1492 20.9899C9.21515 20.1378 11.0441 18.8709 12.5664 17.2395C12.5431 17.694 12.3607 18.0956 12.0058 18.4348C11.8828 18.5525 12.0414 18.7596 12.1874 18.6711C13.6807 17.7645 15.3323 16.7018 15.9997 15.0467C16.667 16.7015 18.3186 17.7645 19.8119 18.6711C19.9582 18.7596 20.1169 18.5525 19.9936 18.4348C19.6386 18.0956 19.4566 17.6943 19.4329 17.2395C20.9552 18.8709 22.7842 20.1374 24.8501 20.9899C24.9798 21.0434 25.0745 20.8723 25.0195 20.769C24.8515 20.4542 24.6838 20.1394 24.5158 19.8246C26.2028 21.2165 28.8867 21.2292 30.4823 20.3764C30.609 20.3089 30.633 20.099 30.519 20.1013Z"
                    fill="#003099" />
                </svg>
                <svg v-if="item === 'boroda'" class="changes-caption-element__icon" viewBox="0 0 32 32" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M26.9934 11.176C26.9863 11.3494 26.8636 11.489 26.6891 11.5228C26.5687 11.5461 26.452 11.5135 26.367 11.4402C26.3659 11.4472 26.3659 11.4541 26.3646 11.4611C26.2927 12.5445 26.0981 15.4235 23.78 18.7344C23.5965 18.9972 23.1678 18.9266 23.0993 18.6098C23.0604 18.4248 22.9743 18.2724 22.835 18.1397C22.6852 19.0637 22.3007 20.2205 21.3392 20.9535C21.0461 21.1755 20.6451 20.9038 20.7423 20.5579C20.9416 19.8388 20.8968 19.1195 20.6196 18.4853C20.5535 20.0877 20.2975 21.0943 19.3231 22.3197C19.0874 22.6169 18.6052 22.4225 18.6472 22.0521C18.6944 21.625 18.6366 21.2968 18.4703 21.0559C18.3499 21.6436 18.0078 23.0203 17.2634 24.2864C16.7039 25.2369 15.2973 25.2388 14.7366 24.2864C13.991 23.0203 13.6501 21.6436 13.5298 21.0559C13.3634 21.2968 13.3056 21.625 13.3528 22.0521C13.3924 22.4246 12.9116 22.6157 12.6768 22.3197C11.7012 21.0943 11.4464 20.0877 11.3804 18.4853C11.1032 19.1207 11.0583 19.8387 11.2577 20.5579C11.3547 20.9032 10.9535 21.1753 10.6608 20.9535C9.69935 20.2204 9.31357 19.0637 9.16494 18.1397C9.02577 18.2724 8.93962 18.4248 8.90069 18.6098C8.83219 18.927 8.40332 18.9969 8.21998 18.7344C5.9019 15.4236 5.70725 12.5446 5.63412 11.4611C5.63412 11.4541 5.63412 11.4472 5.63296 11.4402C5.54801 11.5135 5.43122 11.5461 5.31091 11.5228C5.13628 11.489 5.01365 11.3494 5.0066 11.176C4.95822 10.081 5.17646 8.97309 5.63653 7.97466C5.9661 7.25755 6.95313 7.14009 7.45088 7.76751C8.32027 8.86373 9.05761 9.4165 9.70523 9.45842C10.6426 9.51857 11.2494 8.47542 11.9419 8.02238C14.0174 6.66485 17.9653 6.65357 20.0581 8.02238C20.7636 8.48627 21.3546 9.51975 22.2948 9.45842C22.9425 9.4165 23.6798 8.86377 24.5492 7.76751C24.7827 7.47309 25.1472 7.32295 25.5224 7.3672C25.894 7.41146 26.209 7.63836 26.3635 7.9747C26.8235 8.97309 27.0418 10.0798 26.9934 11.176Z"
                    fill="#003099" />
                </svg>

                <span class="changes-caption-element__bg"></span>
              </label>
            </div>
            <div class="changes-list" v-if="modelType && (subTypes?.length ? modelSubType : true)">
              <div class="changes-element-wrapper" v-for="(item, index) in modelValues" :key="index">
                <label class="changes-element">
                  <input v-if="modelSubType === 'SkinTorso'" :value="item?.index" v-model="userTorsoIndex" type="radio"
                    name="change" class="changes-element__input">
                  <input v-else-if="modelSubType === 'SkinLegs'" :value="item?.index" v-model="userLegsIndex"
                    type="radio" name="change" class="changes-element__input">
                  <input v-else-if="modelSubType === 'SkinShoes'" :value="item?.index" v-model="userShoesIndex"
                    type="radio" name="change" class="changes-element__input">
                  <input v-else-if="modelSubType === 'head'" :value="item?.index" v-model="userHeadIndex" type="radio"
                    name="change" class="changes-element__input">
                  <input v-else-if="modelSubType === 'hair'" :value="item?.index" v-model="userHairIndex" type="radio"
                    name="change" class="changes-element__input">
                  <input v-else-if="modelSubType === 'usi'" :value="item?.index" v-model="userUsiIndex" type="radio"
                    name="change" class="changes-element__input">
                  <input v-else-if="modelSubType === 'boroda'" :value="item?.index" v-model="userBorodaIndex"
                    type="radio" name="change" class="changes-element__input">
                  <input v-else :value="item?.index" v-model="userModel[modelSubType]" type="radio" name="change"
                    :id="modelSubType" class="changes-element__input">
                  <img :src="item?.path" alt="" class="changes-element__img">
                  <img src="@/assets/new-icons/check-icon.svg" alt="" class="changes-element__icon">
                  <span class="changes-element__border"></span>
                </label>
                <div class="colors">
                  <button class="colors__btn" @click="currentChangeModel = item?.index" v-if="item?.materials"></button>
                  <div class="colors-filling " :class="{ 'colors-filling--open': currentChangeModel === item?.index }">
                    <button class="colors__close" @click="currentChangeModel = null"></button>
                    <div class="colors-element" v-for="(material, materialIndex) in item?.materials"
                      :key="materialIndex">
                      <img src="@/assets/new-icons/Mens.svg" alt="" class="colors__icon">
                      <!--                      <img src="@/assets/new-icons/shirt.svg" alt="" class="colors__icon">-->
                      <!--                      <img src="@/assets/new-icons/Tie.svg" alt="" class="colors__icon">-->
                      <div class="colors-list">
                        <button class="colors-list__icon"
                          :style="{ background: color, padding: currentModelSplit[material?.index] === `${colorIndex}` ? '7px' : '' }"
                          v-for="(color, colorIndex) in clothesColors[material.name]" :key="colorIndex"
                          @click="pickMaterialColor(item?.index, material?.index, colorIndex)">
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="changes-links" v-if="skinIsChanged">
              <button class="link" @click="saveUserAvatar">Сохранить изменения</button>
              <button class="link link--disable" @click="resetChanges">Сбросить изменения</button>
            </div>
          </div>
          <!-- <input min="-1" max="1" type="range" class="range" @input="changeAvatarRotate" step="0.1"> -->
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  margin: 0;
}

.avatar {
  position: fixed;
  width: max-content;
  right: 0;
  height: 100%;
  padding: 5rem 0;

  @media (max-width: 1000px) {
    display: none;
  }
}

.caption {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  display: inline-flex;
  position: relative;

  &__title {
    font-weight: 500;
    font-size: 3rem;
    line-height: 150%;
    color: #0a0033;
  }

  &__decoration {
    transform: rotate(-105deg);
    position: absolute;
    right: -6.3rem;
    bottom: 1rem;
    width: 5.9375rem;
  }
}

.back {
  width: 2.5rem;
  height: 2.5rem;
  background-color: #e6ddf3;
  background-image: url("@/assets/new-icons/back.svg");
  background-size: contain;
  border-radius: 100%;
}

.constructor {
  max-width: 100%;
  width: 100%;
  padding: 1rem;

  &-head {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    align-items: flex-start;
  }
}

.sex {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  border-radius: 3.75rem;
  background: #fff;
  flex-shrink: 0;
  padding: 0.375rem;

  &-element {
    width: 2.375rem;
    height: 2.375rem;
    transition: all 0.5s ease;
    display: flex;
    flex-shrink: 0;
    position: relative;
    cursor: pointer;

    &__bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      border-radius: 100%;
      transition: all 0.5s ease;
    }

    &__input {
      &:checked~.sex-element__bg {
        background: #D72D41;
        backdrop-filter: blur(85px);
        box-shadow: 0 4px 4px 0 rgba(60, 0, 229, 0.25);
      }
    }

    &__icon {
      width: 100%;
      height: 100%;
      display: flex;
      flex-shrink: 0;
      padding: .5625rem;
      position: relative;
      z-index: 1;
    }
  }
}

.settings {
  display: flex;
  align-items: flex-end;
  gap: 1rem;
  flex-wrap: wrap;

  &-element {
    display: flex;
    align-items: center;
    padding: 0.625rem;
    position: relative;
    cursor: pointer;


    &__icon {
      display: flex;
      flex-shrink: 0;
      position: relative;
      z-index: 1;
      transition: all 0.5s ease;

      &--face {
        width: 1.875rem;
      }

      &--body {
        width: 1.1875rem;
      }

      &--accessories {
        width: 2.125rem;
      }
    }

    &__bg {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
      height: 100%;
      width: 100%;
      border-radius: 3.75rem;
      background: #f3f4f6;
      transition: all 0.5s ease;
    }

    &__text {
      margin-left: 0.625rem;
      position: relative;
      z-index: 1;
      font-weight: 500;
      font-size: 1rem;
      line-height: 125%;
      color: #00288C;
      transition: all 0.5s ease;
    }

    &__number {
      position: relative;
      z-index: 1;
      width: 1.5625rem;
      height: 1.5625rem;
      border-radius: 100%;
      color: #fff;
      margin-left: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      font-size: 1rem;
      text-align: center;
      color: #f3f4f6;
      background: #003099;
      line-height: 0;
      transition: all 0.5s ease;
    }

    &__input {

      &:checked~.settings-element__bg {
        background: #003099;
      }

      &:checked~.settings-element__number {
        color: #00288C;
        background: #fff;
      }

      &:checked~.settings-element__text {
        color: #fff;
      }

      &:checked~svg path {
        fill: #fff;
        stroke: #003099;
      }

      &:checked~svg ellipse {
        fill: #fff;

      }
    }
  }
}

.content {
  display: flex;
  align-items: flex-start;
  margin-top: 1rem;
  justify-content: space-between;
  gap: 2rem;
}

.user {

  &-wrapper {
    max-width: 100%;
    width: 100%;


  }

  &-filling {
    max-width: 100%;
    width: 100%;
    position: relative;
    padding-bottom: 56.25%;
  }

  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  top: 0;
  left: 0;

  &:deep(#player) {
    max-width: 100%;
    height: 100% !important;
    width: 100% !important;
    position: relative;
    background: linear-gradient(270deg, #dfd4f0 0%, #dfd4f0 27.62%, #dfd4f0 100%);
  }

  &:deep(#videoPlayOverlay) {
    background: linear-gradient(270deg, #dfd4f0 0%, #dfd4f0 27.62%, #dfd4f0 100%);
  }

  &:deep(#overlay) {
    top: 0;
  }

  &__body {
    max-width: 100%;
    width: 100%;
  }
}

.controls {
  display: flex;
  flex-direction: column;
  position: absolute;
  margin: auto 0;
  gap: .6875rem;
  right: 1rem;
}

.changes {
  max-width: 20.125rem;
  width: 100%;
  padding: 1.25rem;
  box-shadow: 0 4px 20px 0 rgba(60, 0, 229, 0.25);
  background: #f3f4f6;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  height: 100%;

  &-caption {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0.5rem;
    justify-content: space-between;

    &-element {
      max-width: 100%;
      width: 100%;
      border-radius: 100%;
      padding: .75rem;
      cursor: pointer;
      position: relative;
      display: flex;

      &__bg {
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        transition: all 0.5s ease;
      }

      &__icon {
        max-width: 100%;
        width: 100%;
        transition: all 0.5s ease;
        position: relative;
        z-index: 2;
      }

      &__input {
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;

        &:checked~.changes-caption-element__icon path {
          fill: #ecedf1;
          stroke: #003099;
        }

        &:checked~.changes-caption-element__icon .path-blue {
          fill: #003099;
        }

        &:checked~.changes-caption-element__bg {
          background: #003099;
        }
      }
    }
  }

  &-links {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .link {
      padding: 1rem 0.5rem;
      font-size: 1rem;
      justify-content: center;

      &--disable {
        background: #ecedf1;
        color: #000;
      }
    }
  }

  &-list {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.75rem;
    max-height: 26.8rem;
    overflow: auto;
    scrollbar-color: #003099 #E8EEF6;
    scrollbar-width: thin;

    @media (max-height: 650px) {
      max-height: 49vh;
    }
  }

  &-element {
    background: #fff;
    max-width: 100%;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    padding-bottom: 100%;
    border-radius: 1rem;
    cursor: pointer;

    &-wrapper {
      position: relative;
    }

    &__img {
      position: absolute;
      bottom: 0;
      border-radius: 1rem;
      max-height: 100%;
    }

    &__icon {
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
      border-radius: 100%;
      opacity: 0;
      z-index: 1;
      width: 1.25rem;
      height: 1.25rem;
      transition: all 0.5s ease;
    }

    &__border {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 1;
      top: 0;
      left: 0;
      border-radius: 1rem;
      transition: all 0.5s ease;
    }

    &__input {
      position: absolute;
      bottom: 0;

      &:checked~.changes-element__border {
        border: 2px solid #003099;
      }

      &:checked~.changes-element__icon {
        opacity: 1;
      }
    }
  }
}

input[type=range] {
  width: 14.5rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  opacity: 1;
  padding: 0;
  background: no-repeat;
  border: none;
  margin: 0 auto;
  display: flex;
  position: absolute;
  bottom: 0;
  z-index: 2;
  height: unset;
  left: calc(50% - 7.25rem);
  bottom: 6rem;


  &::-webkit-slider-runnable-track {
    border-radius: 10px/100%;
    width: 2rem;
    height: 2rem;
    background-color: #fff;
  }

  &::-moz-range-track {
    border-radius: 10px/100%;
    height: 0.75rem;
    background-color: #fff;
  }

  &::-moz-range-thumb {
    background-color: #fff;
    background-image: url("@/assets/new-icons/range.svg");
    border: none;
    border-radius: 100%;
    cursor: pointer;
    width: 2rem;
    height: 2rem;
  }

  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    border-radius: 1rem;
    height: 0.75rem;
    background-color: #fff;
  }

  &::-webkit-slider-thumb {
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    background: url("@/assets/new-icons/range.svg");
    background-size: contain;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -0.6rem;
  }

  &::-moz-range-track {
    border-radius: 1rem;
    height: 0.75rem;
    background-color: #fff;
  }

  &::-moz-range-thumb {
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    background: url("@/assets/new-icons/range.svg");
    background-size: contain;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -0.6rem;
  }

  &::-ms-track {
    border-radius: 1rem;
    height: 0.75rem;
    background-color: #fff;
  }

  &::-ms-fill-lower {
    border-radius: 1rem;
    height: 0.75rem;
    background-color: #fff;
  }

  &::-ms-fill-upper {
    border-radius: 1rem;
    height: 0.75rem;
    background-color: #fff;
  }

  &::-ms-thumb {
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    background: url("@/assets/new-icons/range.svg");
    background-size: contain;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -0.6rem;
  }

  &:focus::-ms-fill-lower {
    background: #fff;
  }

  &:focus::-ms-fill-upper {
    background: #fff;
  }
}

.colors {

  &__close {
    margin-left: auto;
    width: 0.85rem;
    height: 0.85rem;
    background-image: url("@/assets/new-icons/colors-close.svg");
    background-size: contain;
    background-repeat: no-repeat;
  }

  &__btn {
    position: absolute;
    right: 0.5rem;
    bottom: 0.5rem;
    width: 1.25rem;
    height: 1.25rem;
    display: flex;
    background-color: #fff;
    border-radius: 100%;
    background-image: url("@/assets/new-icons/colors-btn.svg");
    background-size: contain;
    background-repeat: no-repeat;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
    z-index: 2;
  }

  &-filling {
    position: absolute;
    right: 0.5rem;
    bottom: 0.5rem;
    transform: translateY(10%);
    opacity: 0;
    padding: 0.5rem;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
    background: #fff;
    border-radius: 0.69rem;
    transition: all 0.5s ease;
    display: flex;
    flex-direction: column;
    gap: .1875rem;
    z-index: 3;
    pointer-events: none;
    max-width: calc(100% - 1rem);

    &--open {
      transform: translateY(0);
      opacity: 1;
      pointer-events: all;
    }

  }

  &-element {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &-list {
    margin-top: 0.5rem;
    display: flex;
    gap: 1px;
    flex-wrap: wrap;
    justify-content: flex-end;

    &__icon {
      display: flex;
      width: .625rem;
      height: .625rem;
      border-radius: 100%;
      background: #F8B95C;

      &--red {
        background: #E51740;
      }

      &--black {
        background: #1A2632;
      }

      &--grey {
        background: #2E4051;
      }
    }
  }

  &__icon {
    width: .6875rem;
    height: .6875rem;
    object-fit: contain;
  }
}
</style>
