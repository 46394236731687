<template>

  <div class="prevue-wrapper" :class="{'train--active':educationStep===0}">
    <!--    при обучении -->
    <div class="train "></div>
    <div class="prevue " @click="pickSlide" :style="{ cursor: slide ? 'pointer' : 'default' }">
      <img
          :src="slide ? (slide?.fileLink ? slide?.fileLink : slide?.videoLink ? require('@/assets/icons/Play.png')  : slide?.translationLink ? require('@/assets/icons/stream.svg') : require('@/assets/icons/small-prevue.svg')) : require(`@/assets/icons/small-prevue.svg`)"
          :class="{'video':slide?.videoLink}" alt=" " class="prevue__img"/>
      <!--    <img v-if="slide && slide.videoLink" src="@/assets/icons/Play.png" alt="Превью" class="prevue__img video" />-->
      <div class="filter" :class="{'active':slide?.order===currentSlide}" v-if="slide"></div>

      <button class="add-top add" v-if="route.name === 'presentationEditorPage' && !slide"
              @click="commit('addSlide')"></button>
      <!--    <div class="new-slide-info new-slide-info__in-slide" v-if="slide">-->
      <!--      <p class="new-slide-title">-->
      <!--        Новый слайд-->
      <!--      </p>-->
      <!--      <p class="new-slide-text">-->
      <!--        Без описания-->
      <!--      </p>-->
      <!--    </div>-->
      <!--    <div class="new-slide-info" v-if="route.name === 'presentationEditorPage' && !slide">-->
      <!--      <p class="new-slide-title">-->
      <!--        {{ $t("newSlide.title1") }}-->
      <!--      </p>-->
      <!--      <p class="new-slide-text">-->
      <!--        {{ $t("newSlide.info") }}-->
      <!--      </p>-->
      <!--    </div>-->


      <button @click="deleteSlide" class="delete" v-if="slide">
      </button>

      <div class="moving">
        <p class="number" v-if="slide">
          #{{ slide?.order }}
        </p>
        <button class="shift-places shift-places__top"
                @click="commit('swapSlides', { from: slide.order - 1, to: slide.order - 2 })"
                v-if="slide && slide.order !== 1"></button>
        <button class="shift-places shift-places__bottom"
                @click="commit('swapSlides', { from: slide.order - 1, to: slide.order })"
                v-if="slide && slide.order !== slidesLength"></button>
      </div>

      <button class="add-top add" v-if="route.name === 'presentationsPage'" @click="
      router.push({ name: 'presentationEditorPage', params: { id: 'new' } })
      "></button>

      <div class="new-slide-info new-slide-info__in-slide" v-if="route.name === 'presentationsPage'">
        <p class="new-slide-title">
          {{ $t("newSlide.title2") }}
        </p>
        <!--      <p class="new-slide-text">-->
        <!--        {{ $t("newSlide.info") }}-->
        <!--      </p>-->
      </div>

    </div>
  </div>
</template>

<script>
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";

export default {
  name: "NewSeparateLectionPreview",
  props: ["slide", "slidesLength", 'currentSlide', 'slideIndex','educationStep'],
  setup(props, {emit}) {
    const store = useStore();
    const deleteSlide = (event) => {
      store.commit('deleteSlide', props.slide.order);
      event.preventDefault();
      event.stopPropagation();
      return false;
    }

    return {
      route: useRoute(),
      router: useRouter(),
      pickSlide: () => {
        if (props.slide) {
          emit("pickSlide", props.slide.order);
        }
      },
      commit: (name, data) => store.commit(name, data),
      deleteSlide
    };
  },
};
</script>

<style scoped>
.prevue-wrapper {
  position: relative;
}

.prevue {
  max-width: 100%;
  width: 100%;
  position: relative;
  border-radius: 1rem;
  background: #ECF2FA;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding-bottom: 56.25%;
}

.prevue__img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 20px;
}

.video {
  background: #000;
  object-fit: contain;
  object-position: center;
}

.add {
  background: url("@/assets/icons/add.svg");
  background-size: contain;
  width: 3rem;
  height: 3rem;
  position: absolute;
  z-index: 5;
}

.add-center {
  top: -24px;
  left: calc(50% - 24px);
}

.add-top {
  left: 1rem;
  top: 1rem;
}

.prevue__text {
  margin-top: auto;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
  color: #ffffff;
  padding: 16px 12px 12px 12px;
  max-width: 100%;
  width: 100%;
  min-height: 88px;
  border-radius: 0 0px 20px 20px;
  text-align: center;
  background: rgba(0, 0, 0, 0.75);
  position: relative;
}

.add-center-text {
  padding-top: 26px;
}

.moving {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  right: 1rem;
  top: 1rem;
  z-index: 3;
}

.shift-places {
  background: url("@/assets/icons/arrow-bottom.svg");
  width: 2rem;
  height: 2rem;
  background-size: contain;
  background-position: center;
  border-radius: 100%;
}

.number {
  padding: 0.37rem 0.56rem;
  color: #9098A5;
  font-size: 0.75rem;
  border-radius: 0.25rem;
  background: #ECF2FA;
  margin-bottom: 0.12rem;
}

.shift-places__top {
  transform: rotate(180deg);
}

.new-slide-info {
  position: absolute;
  bottom: 0;
  max-width: 100%;
  width: 100%;
  border-radius: 0rem 0rem 1rem 1rem;
  background: rgba(171, 185, 203, 0.60);
  color: #000;
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  z-index: 3;
}

.new-slide-title {
  font-size: 1.125rem;
  font-weight: 700;
}

.new-slide-text {
  font-size: 0.875rem;
}

.delete {
  position: absolute;
  left: 1rem;
  top: 1rem;
  width: 3rem;
  height: 3rem;
  background: url("@/assets/icons/delete.svg");
  background-size: contain;
  background-position: center;
  z-index: 3;
}

.new-slide-info__in-slide {
  color: #FFF;
}

.filter {
  border-radius: 1rem;
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
}

.active {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%);
}


.train {
  position: absolute;
  left: -1rem;
  top: -2.5rem;
  right: -1rem;
  bottom: -1rem;
  opacity: 1;
  border-radius: 1rem;
  transition: 0.5s ease-in-out all;
}

.train--active {
  opacity: 1;
  z-index: 8;
}

</style>
