
<script setup>
import { defineEmits, shallowRef, onMounted, onBeforeUnmount } from 'vue';
const emits = defineEmits({
  showAbout: () => true,
  hide: () => true,
});

const timer = shallowRef(null);

onMounted(() => {
  window.addEventListener('keydown', keyListener);

  timer.value = setInterval(() => {
    emits('hide');
    clearInterval(timer.value);
  }, 10000);
});

onBeforeUnmount(() => {
  window.addEventListener('keydown', keyListener);

  if (timer.value) {
    clearInterval(timer.value);
  }
});

function keyListener(event) {
  if (event.keyCode === 69) {
    emits('showAbout')
  }
}

</script>


<template>
  <Transition name="fade">
    <div class="shell">
      <div class="modal">
        <p class="letter">Е</p>
        <p class="info">Нажмите <span>“E”</span>, что бы узнать больше информации об объекте</p>
      </div>
    </div>
  </Transition>
</template>

<style scoped>
.shell {
  align-items: flex-start;
  justify-content: center;
  border-radius: 2.75rem;
  width: 28rem;
  height: unset;
  padding: 0.5rem;
  backdrop-filter: blur(8px);
  background: rgba(157, 196, 248, 0.3);
  left: calc(50% - 14rem);
  top: 1rem;
}

.modal {
  background: #ecf2fa;
  border-radius: 2.75rem;
  display: flex;
  gap: 0.875rem;
  align-items: center;
  flex-direction: row;
  padding: 0.75rem;
  margin: 0;
}

.letter {
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  background: #003099;
  font-weight: 900;
  font-size: 2.5rem;
  color: #fff;
  border-radius: 100%;
  font-family: "Inter", sans-serif;
}

.info {
  font-weight: 400;
  font-size: 1.12rem;
  letter-spacing: -0.01em;
  color: #000;
}

.info span {
  font-weight: 700;
}

@media (max-width: 1000px) {
  .shell {
    display: none;
  }
}
</style>
