import {makeGetReq, makePostReq, makePutReq} from "@/store/helpers/request";
import {defaultHttpResHandler} from '@/store/helpers/defaultHttpResHandler'
import {appConfig} from "@/lib/appConfig";


export const loadFile = async (data) => await makePostReq(appConfig.domain + '/file', data);
export const loadPDF = async (data) => await makePostReq(appConfig.domain + '/pdf', data);

export const updateDedicatedTour = async (token, id, data) => await defaultHttpResHandler(async () => await makePutReq(`${appConfig.domain}/event/dedicated-tour/${id}`, data, {token}));

export const getEventDedicatedTourById = async (token, id) => await defaultHttpResHandler(async () => await makeGetReq(`${appConfig.domain}/event/dedicated-tour/${id}`, {token}));

export const getEventRecordedTourById = async (token, id) => await defaultHttpResHandler(async () => await makeGetReq(`${appConfig.domain}/event/dedicated-tour/${id}`, {token}))

export const getScoreTable = async (id) => await defaultHttpResHandler(async () => await makeGetReq(`${appConfig.domain}/score-table/${id}`));

export const saveStat = async (token, data) => await defaultHttpResHandler(async () => await makePostReq(`${appConfig.domain}/save-stat`, data, { token }));

export const saveFeedback = async (token, data) => await defaultHttpResHandler(async () => await makePostReq(`${appConfig.domain}/feedback/aveleks`, data, { token }))