import {defaultHttpResHandler} from "../helpers/defaultHttpResHandler";
import {makeDeleteReq, makeGetReq, makePostReq, makePutReq} from "@/store/helpers/request";
import {appConfig} from "@/lib/appConfig";


//TODO TEST FAST AUTHORIZATION
export const sendCode = async (code) => await defaultHttpResHandler(async () => await makePostReq(`${appConfig.domain}/client/accept?code=${code}`));


// http request for default register process
export const submitRegistration = async (data) => await defaultHttpResHandler(async () => await makePostReq(`${appConfig.domain}/client/registration`, data, {data: true}))

export const fetchInstance = async (token) => await defaultHttpResHandler(async () => await makeGetReq(appConfig.instance, {token}));


export const fetchUserLocation = async () => await defaultHttpResHandler(async () => await makeGetReq(`https://xgeo.wildx.ru/&apikey=f9304cb5fd23fe6798904303b9094f339f0e089d981cd47df54595163a935402`, {}));


// http request for login into system
export const submitAuthorization = async (data) => await defaultHttpResHandler(async () => await makePostReq(`${appConfig.domain}/client/login`, data, {data: true}));

export const authorizationThroughMos = async (code) => await defaultHttpResHandler(async () => {
    const search = new URLSearchParams({
        origin: window.location.origin,
        code
    }).toString();

    const url = new URL(`${appConfig.domain}/client/authorization?${search}`);

    return  await makeGetReq(url);
});



export const deleteInstance = async (token, unrealToken, data) => await defaultHttpResHandler(async () => await makePostReq(`${appConfig.domain}/instance`, data, {
    data: true,
    token,
    headers: {
        "X-Unreal-Token": unrealToken
    }
}));


export const fastAuthorization = async (data) => await defaultHttpResHandler(async () => await makePostReq(`${appConfig.domain}/client/fastauth`, data, {data: true}));


export const fetchUserData = async (token) => await defaultHttpResHandler(async () => await makeGetReq(`${appConfig.domain}/client`, {token}));

export const saveUserData = async (token, data) => await defaultHttpResHandler(async () => await makePutReq(`${appConfig.domain}/client`, data, {
    token,
    data: true
}));

export const writeToSupport = async (data) => await defaultHttpResHandler(async () => await makePostReq(`${appConfig.domain}/support-message`, data));

export const slotEventSubscribe = async (token, data) => await defaultHttpResHandler(async () => await makePostReq(`${appConfig.domain}/client/slots-subscribe`, data, {
    token,
    data: true,
}));

export const slotEventUnsubscribe = async (token, data) => await defaultHttpResHandler(async () => await makeDeleteReq(`${appConfig.domain}/client/slots-subscribe`, data, {
    token,
    data: true,
}));


