export const declination = (quantity, optionWord) => {
  const num = quantity % 100;
  if (num > 4 && num < 21) {
    return optionWord[0]
  }

  if (num % 10 === 1) {
    return optionWord[1]
  }

  if (num % 10 === 2 || num % 10 === 3 || num % 10 === 4) {
    return optionWord[2];
  }

  return optionWord[0];
};