<script setup>
import {computed} from 'vue';
import {useRouter} from 'vue-router';
import {useStore} from 'vuex';
import {appConfig} from '@/lib/appConfig';
import LoaderElement from '@/components/general/LoaderElement.vue';

const router = useRouter();
const {dispatch} = useStore();

const code = computed(() => router.currentRoute.value.query?.code);


if (code.value) {
  dispatch('authorizationThroughMos', code.value);
} else {
  const url = new URL('/client/oauth', appConfig.domain);

  const search = new URLSearchParams({
    origin: window.location.origin
  }).toString();

  url.search = search;

  fetch(url)
      .then((response) => response.json())
      .then((data) => window.open(data.url, '_self'));
}
</script>

<template>
  <div class="authorization-wrapper">
    <LoaderElement :page="false" />
  </div>
</template>


<style scoped>
.authorization-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #ECF2FA;
  padding: 2rem 3rem 1.5rem 3rem;
  min-height: 100vh;
  min-height: 100dvh;
}

.logo {
  cursor: pointer;
  height: 6.375rem;
}
</style>
