<template>
  <div class="help-wrapper" @click="$emit('closeShareForm')">
    <div class="share">
      <div class="share-caption">
        <button class="back" @click="$emit('closeShareForm')">
          <img src="@/assets/icons/back-icon.svg" alt="" class="back-icon" />
          <p class="back__text">Назад</p>
        </button>
        <button class="share-action" @click="$emit('closeShareForm')">
          <img
            src="@/assets/icons/game/share-action.svg"
            alt=""
            class="share-action__icon"
          />
          Поделиться
        </button>
      </div>
      <div class="share-filling">
        <h2 class="share__title">Мои контакты</h2>
        <div class="share-element">
          <p class="share-element__title">ФИО</p>
          <p class="share-element__text">Мартыненко Марианна Олеговна</p>
        </div>
        <div class="share-element">
          <p class="share-element__title">Мобильный телефон</p>
          <p class="share-element__text">+7(985)5436787</p>
        </div>
        <div class="share-element">
          <p class="share-element__title">Email</p>
          <p class="share-element__text">Мартыненко Марианна Олеговна</p>
        </div>
        <div class="share-element">
          <p class="share-element__title">ФИО</p>
          <p class="share-element__text">mariannaol@mail.ru</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShareModal",
  components: {},
};
</script>

<style scoped>
.help-wrapper {
  position: fixed;
  max-width: 100%;
  width: 100%;
  z-index: 1000;
  display: flex;
  top: 0;
  left: 0;
  height: 100vh;
  background: rgba(18, 20, 26, 0.7);
  padding: 40px 10px;
  scrollbar-width: thin;
  overflow: auto;
}
.share {
  margin: auto;
  background: rgba(255, 255, 255, 0.699999988079071);
  border-radius: 24px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(85px);
  max-width: 591px;
  width: 100%;
  padding: 20px 48px 48px 48px;
}
.share-caption {
  display: flex;
  align-items: center;
}
.back {
  display: flex;
  align-items: center;
  padding: 12px 1px;
  gap: 6px;
}
.back__text {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #003099;
  margin-left: 6px;
}
.share-action {
  margin-left: auto;
  display: flex;
  align-items: center;
  padding: 14px;
  background: #003099;
  border: 1px solid #003099;
  border-radius: 8px;
  font-size: 17px;
  line-height: 20px;
  color: #ffffff;
  gap: 8px;
}
.share-filling {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
  gap: 24px;
}
.share__title {
  font-family: "GolosUI";
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.02em;
  color: #1a1a1a;
}
.share-element__title {
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  color: #5d6470;
}
.share-element__text {
  font-size: 20px;
  line-height: 28px;
  color: #13151a;
  padding: 12px 0;
}
@media (max-width: 1800px) {
  .share {
    max-width: 491px;
    width: 100%;
    padding: 12px 20px 20px 20px;
    border-radius: 14px;
  }
  .share-caption {
    display: flex;
    align-items: center;
  }
  .back {
    padding: 0px 1px;
    gap: 2px;
  }
  .back-icon {
    width: 20px;
    height: 20px;
  }
  .back__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #003099;
    margin-left: 6px;
  }
  .share-action {
    padding: 8px;
    font-size: 15px;
    line-height: 16px;
    color: #ffffff;
    gap: 6px;
  }
  .share-action__icon {
    width: 16px;
    height: 16px;
  }
  .share-filling {
    margin-top: 10px;
    gap: 12px;
  }
  .share__title {
    font-size: 24px;
    line-height: 1;
  }
  .share-element__title {
    font-size: 15px;
    line-height: 20px;
  }
  .share-element__text {
    font-size: 15px;
    line-height: 20px;
    padding: 8px 0;
  }
}
</style>
