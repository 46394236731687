// makes post request to server
export async function makePostReq(url, data, params) {
    const requestOptions = {
        method: "POST",
        body: data
    };
    if (params) {
        requestOptions['headers'] = {};
        if (params.token) {
            requestOptions.headers['X-Auth-Token'] = params.token;
        }
        if (params.data) {
            requestOptions.headers['Content-Type'] = 'application/json;charset=UTF-8';
            requestOptions.body = JSON.stringify(data);
        }
        if (params.headers) {
            for (let key in params.headers) {
                requestOptions.headers[key] = params.headers[key];
            }
        }
    }

    const req = await fetch(url, requestOptions);
    const reqData = await req.json();
    return {
        data: reqData,
        status: req.status
    }
}

export async function makePutReq(url, data, params) {
    const requestOptions = {
        method: "PATCH",
        body: data
    };
    if (params) {
        requestOptions['headers'] = {};
        if (params.token) {
            requestOptions.headers['X-Auth-Token'] = params.token;
        }
        if (params.data) {
            requestOptions.headers['Content-Type'] = 'application/json;charset=UTF-8';
            requestOptions.body = JSON.stringify(data);
        }
    }

    const req = await fetch(url, requestOptions);
    const reqData = await req.json();
    return {
        data: reqData,
        status: req.status
    }
}

export async function makeDeleteReq(url, data, params) {
    const requestOptions = {
        method: "DELETE",
        body: data
    };
    if (params) {
        requestOptions['headers'] = {};
        if (params.token) {
            requestOptions.headers['X-Auth-Token'] = params.token;
        }
        if (params.data) {
            requestOptions.headers['Content-Type'] = 'application/json;charset=UTF-8';
            requestOptions.body = JSON.stringify(data);
        }
    }

    const req = await fetch(url, requestOptions);
    const reqData = await req.json();
    return {
        data: reqData,
        status: req.status
    }
}

export async function makeGetReq(url, params) {
    const requestOptions = {
        method: "GET"
    };
    if (params) {
        requestOptions['headers'] = {};
        if (params.token) {
            requestOptions.headers['X-Auth-Token'] = params.token;
        }
    }
    const req = await fetch(url, requestOptions);
    try {
        const reqData = params?.blob ? await req.blob() : await req.json();
        return {
            data: reqData,
            status: req.status
        }
    } catch (e) {
        console.log(e)
        return {
            data: null,
            status: req.status
        }
    }
}

export const getVideoReq = async (url, params) => {
    const requestOptions = {
        method: "GET"
    };
    if (params) {
        requestOptions['headers'] = {};
        if (params.token) {
            requestOptions.headers['X-Auth-Token'] = params.token;
        }
    }
    const req = await fetch(url, requestOptions);
    try {
        return {
            data: url,
            status: req.status
        }
    } catch (e) {
        return {
            data: url,
            status: e.response.status
        }
    }
}