<template>
  <section class="section purple-bg">
    <div class="block filling container">


      <NewCaptionComponent>
      </NewCaptionComponent>
      <p class="subtitle text text-small">
        {{ $t("lecturesList.title") }}</p>
      <div class="actions">
        <!--      <label for="search" class="search">-->
        <!--        <img src="@/assets/icons/search.svg" alt="" class="search-icon">-->
        <!--        <input name="search" placeholder="Поиск по презентациям" id="search" type="text" class="search-input">-->
        <!--      </label>-->
        <!--      <button class="start link-default" @click="startPresentation">-->
        <!--        {{ $t("lecturesList.start") }}-->
        <!--      </button>-->
      </div>
      <div class="content">
        <div class="content-description">
          <p class="content-description-title text">
            {{ $t("lecturesList.list") }}
          </p>
          <p class="content-description-text text" v-if="presentations?.length">
            {{ $t("lecturesList.text") }}</p>
        </div>
        <div class="content-list " :class="{ 'content-list__empty': !presentations?.length }">
          <NewSeparateLectionPreview :education-step="educationStep"></NewSeparateLectionPreview>

          <div class="clue" v-if="!presentations?.length">
            <img src="@/assets/icons/robot.png" alt="" class="clue-robot">
            <p class="clue-title title">
              {{ $t("lecturesList.noPresents") }}
            </p>
            <p class="clue-text text">
              {{ $t("lecturesList.firstPresent") }}
            </p>
          </div>

          <NewSeparateLectionPreviewBig @pickPresentation="pickPresentation" v-for="item in presentations"
            :key="item.id" :role="user?.role" :selected="selected === item.id" :presentation="item">
          </NewSeparateLectionPreviewBig>
        </div>
      </div>
      <new-page-training v-if="educationStep != null" :education-step="educationStep" page="presentations"
        @close="nextStep(true)" @next="nextStep(false)"></new-page-training>
    </div>
  </section>
</template>

<script>
import NewSeparateLectionPreview from "@/components/guide/NewSeparateLectionPreview.vue";
import NewSeparateLectionPreviewBig from "@/components/guide/NewSeparateLectionPreviewBig.vue";
import { useStore } from "vuex";
import { computed, onBeforeMount, ref } from "vue";
import NewCaptionComponent from '@/components/general/NewCaptionComponent.vue';
import NewPageTraining from "@/components/general/NewPageTraining.vue";
import { useRouter } from "vue-router";

export default {
  name: "NewLecturesListPage",
  components: {
    NewPageTraining,
    NewSeparateLectionPreview,
    NewSeparateLectionPreviewBig,
    NewCaptionComponent
  },
  setup() {
    const store = useStore();

    const selected = ref(null);

    const router = useRouter();

    const user = computed(() => store.getters.userData);

    const educationStep = ref(sessionStorage.getItem('education') === 'true' ? 0 : null);

    const nextStep = (skipEducation) => {

      if (skipEducation) {
        sessionStorage.removeItem('education');
        educationStep.value = null;
        return;
      }
      router.push({ name: 'presentationEditorPage', params: { id: 'new' } });
    }


    onBeforeMount(() => store.dispatch("fetchPresentations"));
    return {
      user,
      selected,
      presentations: computed(() => store.getters.presentations),
      pickPresentation: (val) => {
        selected.value = selected.value === val ? null : val;
      },
      startPresentation: () => {
        if (selected.value) sessionStorage.setItem("presentationId", selected.value);
        router.push({ name: "gamePage" });
      },
      educationStep,
      nextStep
    };
  },
};
</script>

<style scoped>
.section {
  min-height: calc(100vh - 189px);
  padding: 3rem 0;
}

.slides {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 20rem;
  width: 100%;
}

.filling {
  padding: 2.5rem 2rem 2rem 2rem;
  border-radius: 1.5rem;
  background: #FFF;
}

.subtitle {
  margin-top: 1rem;
}

.actions {
  margin-top: 1.5rem;
  display: flex;
  gap: 1rem;
}

.search {
  display: flex;
  align-items: center;
  gap: 1rem;
  border-radius: 1rem;
  background: #ECF2FA;
  padding: 1rem 1.5rem;
  max-width: 100%;
  width: 100%;
}

.search-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.search-input {
  margin-top: 0;
  border: none;
  padding: 0;
  max-width: 100%;
  width: 100%;
  background: none;
}

.start {
  margin-top: 0;
  max-width: 17.875rem;
}

.content {
  margin-top: 1.63rem;

}

.content-description {
  display: grid;
  grid-template-columns: 1fr 3fr;
}

.content-description-title {
  color: #000;
  font-weight: 600;
}


.content-list {
  margin-top: 0.75rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(4, 1fr);
}

.content-list__empty {
  grid-template-columns: 1fr 3fr;
}

.clue {
  border-radius: 1rem;
  background: #ECF2FA;
  max-width: 100%;
  width: 100%;
  padding: 1.5rem 2rem;
}

.clue-robot {
  width: 4.5rem;
  height: 4.5rem;
}

.clue-title {
  margin-top: 1.44rem;
}

.clue-text {
  margin-top: 0.5rem;
}

@media (max-width: 1300px) {
  .content-list {

    grid-template-columns: repeat(3, 1fr);
  }
}
</style>
