<template>
  <div class="help-wrapper">
    <p class="help__text">
      Для корректного отображения сайта переверните телефон
    </p>
  </div>
</template>

<script>
export default {
  name: "MobileAlert",
};
</script>

<style scoped>
.help-wrapper {
  left: 0;
  top: 0;
  position: fixed;
  z-index: 1000000;
  max-width: 100%;
  width: 100%;
  height: 100vh;
  display: none;
  justify-content: center;
  background: rgba(0, 0, 0, 0.55000011920929);
  height: 100%;
  overflow: auto;
}

@media (orientation: portrait) and (max-width: 1000px) {
  .help-wrapper {
    display: flex;
    align-items: center;
    padding: 0 10px;
  }
  .help__text {
    border-radius: 16px;
    padding: 14px 13.5px;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.4;
    text-align: center;
    color: #fff;
    background: rgba(255, 255, 255, 0.35);
    backdrop-filter: blur(22.5px);
  }
}
</style>
