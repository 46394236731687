<template>
  <div class="teleport">
    <p class="teltport-text">
      Для того чтобы взаимдоействовать с объектами, нажмите клавишу «{{
        actionButton
      }}»
    </p>
    <div class="teleport-btns">
      <button class="teleport__btn" @click="closeNotification">Понятно</button>
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted } from "vue";

export default {
  name: "ActionButtonNotification",
  props: ["actionButton"],
  setup(props, { emit }) {
    const pressButton = (event) => {
      if (event.code === "KeyE") closeNotification();
    };

    const closeNotification = () => emit("close");

    onMounted(() => document.addEventListener("keydown", pressButton));
    onUnmounted(() => document.removeEventListener("keydown", pressButton));
    return {
      closeNotification,
    };
  },
};
</script>

<style scoped>
.teleport {
  position: absolute;
  top: 48px;
  background: rgba(255, 255, 255, 0.36000001430511475);
  border-radius: 16px;
  backdrop-filter: blur(85px);
  margin: 0 auto;
  z-index: 10;
  padding: 20px;
}

.teltport-text {
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  text-align: left;
  color: #ffffff;
}

.teleport-btns {
  margin-top: 15px;
  display: flex;
  gap: 10px;
  justify-content: center;
}

.teleport__btn {
  padding: 12px 14px;
  background: #003099;
  border: 1px solid #003099;
  border-radius: 8px;
  font-size: 15px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
}

.teleport__btn-cancellation {
  background: #ddecff;
  color: #003099;
}

@media (max-width: 1800px) {
  .teleport {
    position: absolute;
    top: 20px;
    border-radius: 12px;
    padding: 15px;
  }

  .teltport-text {
    font-size: 15px;
    line-height: 1;
  }

  .teleport-btns {
    margin-top: 15px;
  }

  .teleport__btn {
    padding: 10px 12px;
    font-size: 14px;
    line-height: 1;
  }
}
</style>
