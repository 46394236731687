<template>
  <div class="notifcation">
    <p class="notifcation__text">{{
      expectedCall?.metadata?.userName ? expectedCall?.metadata?.userName : 'Пользователь'
    }} хочет связаться с вами.</p>
    <div class="notifcation-btns">
      <button class="notifcation__btn" @click="answerUser">Принять</button>
      <button class="notifcation__btn reject" @click="cancelInvite">Отклонить</button>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  name: "DialogueWindow",
  setup(props, { emit }) {
    const store = useStore();
    const expectedCall = computed(() => store.getters.expectedCall);

    return {
      answerUser: () => {
        store.commit('setChatParams', {
          receiverId: expectedCall.value?.peer,
          chatType: 'user',
          receiverName: expectedCall.value?.metadata?.userName
        })
        store.commit('answerCall');
      },
      cancelInvite: () => {
        emit('cancel');
      },

      expectedCall

    }
  }

};
</script>

<style>
.notifcation {
  position: absolute;
  margin: auto;
  z-index: 6;
  padding: 20px 30px;
  background: rgba(255, 255, 255, 0.36000001430511475);
  border-radius: 16px;
  backdrop-filter: blur(85px);
  font-size: 16px;
  line-height: 1.3;
  max-width: 373px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.notifcation__text {
  line-height: 1.2;
}

.notifcation-btns {
  display: flex;
  justify-content: space-between;
}

.notifcation__btn {
  background: #003099;

  border: 1px solid #003099;
  border-radius: 8px;
  padding: 8px 14px;
  line-height: 1;
}

.reject {
  background: none;
  color: #fff;
  border-color: #fff;
}
</style>
