<script setup>
import { defineEmits } from 'vue';

const emits = defineEmits({
  close: () => true,
});


function onClose() {
  emits('close')
}
</script>

<template>
  <div class="shell" @click.self="onClose">
    <div class="modal">
      <div class="modal-caption">
        <p class="title">Для корректной работы необходимо зайти с ПК</p>
        <button class="close" v-if="false">
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.shell {
  display: none;
}

@media screen and (max-width: 786px) {
  .shell {
    display: flex;

  }

  .modal {
    border-radius: 0.5rem;

  }

  .title {
    max-width: 100%;
    width: 100%;
    font-size: 1.25rem;
    text-align: center;
  }
}
</style>