<template>
  <div class="">
  </div>
</template>

<script setup>
import {appConfig} from "@/lib/appConfig";

const el = document.createElement('a');
el.href = `${appConfig.domain}/file/MetaMoscowInstaller.zip`;
el.download = 'Файл';
el.click()
</script>


<style scoped>

</style>