<template>
  <div class="element">
    <div class="element-info">
      <img :src="mapElement.imgUrl" alt="" class="element-demonstration">
      <div class="element-filling">
        <p class="element-subtitle">{{ mapElement.category }}</p>
        <p class="element-title">{{ locale === 'en' ? mapElement?.name_eng : mapElement?.name }}</p>
        <button class="link" @click="openObjectInfo"> Об объекте
        </button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "MapElementSmall",
  props: ['mapElement', 'index', 'locale'],
  emits: ['openObjectInfo'],
  setup(props, {emit}) {

    const openObjectInfo = () => {
      emit('openObjectInfo', props.mapElement)
    }


    return {
      openObjectInfo
    }
  }
}
</script>

<style scoped>
.element {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.element-info {
  border-radius: 0.5rem;
  padding: 0.5rem;
  width: 100%;
  background: #FFF;
  display: flex;
  gap: 0.5rem;
}

.element-demonstration {
  width: 4.5rem;
  height: 4.5rem;
  object-fit: cover;
  object-position: center;
  border-radius: 0.25rem;
  background: #D9D9D9;
}

.element-filling {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.element-subtitle {
  color: #ABB4C0;
  font-size: 0.75rem;
  letter-spacing: 0.0225rem;
}

.element-title {
  color: #000;
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.link {
  margin-top: auto;
  text-align: left;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  padding: 0.5rem 0.75rem;
  border-radius: 0.75rem;
  width: unset;
}

@media (max-width: 1000px) {

  .element-info {
    border-radius: 0.25rem;
    padding: 0.25rem;
    gap: 0.25rem;
  }

  .element-demonstration {
    width: 2.5rem;
    height: 2.5rem;
  }

  .element-subtitle {
    font-size: 0.65rem;
  }

  .element-title {
    font-size: 0.65rem;
  }

  .link {
    font-size: 0.55rem;
  }
}
</style>