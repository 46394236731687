<script>
import {computed, onMounted, ref, watch} from "vue";

export default {
  name: "NewPageTraining",
  props: ['educationStep', 'page'],
  setup(props, {emit}) {

    const windowWidth = ref(window.innerWidth);

    window.onresize = () => {
      windowWidth.value = window.innerWidth;
    }

    const trainingSteps = computed(() => {
      if (props.page === 'settings') {
        return [
          {
            title: "Добро пожаловать в Мой профиль!",
            text: "В разделе \"Мой профиль\" вы можете формировать и изменять своего персонажа, включая пол, прическу, стиль одежды и общий внешний вид. Этот раздел также предоставляет возможность актуализировать личную информацию.",
            styles: {
              transform: 'translateX(0rem) translateY(0rem)',
            },
            laptop: {
              transform: 'translateX(0) translateY(0)',
            },
            mobile: {
              transform: 'translateX(0) translateY(0)',
            }

          },

          {
            title: "Редактор аватара",
            text: "Нажмите на карандашик, чтобы выбрать нужные опции для создания или изменения вашего персонажа.",
            styles: {
              transform: 'translateX(-4rem) translateY(-4.5rem)',
            },
            mobile: {
              transform: 'translateX(0rem) translateY(0rem)',
            }
          },
          {
            title: "Личные данные",
            text: "Заполните поля вашими личными данными",
            styles: {
              transform: 'translateX(-33rem) translateY(-4rem)',
            },
            laptop: {
              transform: 'translateX(-28rem) translateY(0)',
            },
            mobile: {
              transform: 'translateX(0rem) translateY(-7rem)',
            }
          },
          {
            title: "Сохраните изменения",
            text: "Обновите данные личной информации при необходимости.",
            styles: {
              transform: 'translateX(-4.5rem) translateY(-4rem)',
            },
            laptop: {
              transform: 'translateX(-4.5rem) translateY(-4rem)',
            },
            mobile: {
              transform: 'translateX(0) translateY(0)',
            }

          },
        ]
      }
      // TODO вставляй в span "Мероприятия"
      if (props.page === 'events') {
        return [
          {
            title: "Добро пожаловать в Мероприятия!",
            text: "В разделе \"Мероприятия\" вы можете увидеть место и время проведения мероприятия, назначенного для вас администратором. В день и время проведения мероприятия у вас будет возможность принять участие \n" +
                "в соответствующем мероприятии.",
            styles: {
              transform: 'translateX(0rem) translateY(0rem)',
            },

            mobile: {
              transform: 'translateX(0rem) translateY(0rem)',
              bottom: "unset",

            }
          },
          {
            title: "Выберите мероприятие",
            text: "Перейдите в мероприятие выбрав определенную дату и время из панели уведомлений или раздела “Мероприятия”",
            styles: {
              transform: 'translateX(0rem) translateY(0rem)',
            },
            mobile: {
              transform: 'translateX(0rem) translateY(14rem)',
              bottom: "unset",

            }
          },
        ]
      }

      if (props.page === 'presentations') {
        return [
          {
            title: "Создание новой презентации",
            text: "Воспользуйтесь редактором презентаций для создания новой презентации или редактирования уже существующей.",
            styles: {
              transform: 'translateX(0rem) translateY(0rem)',
            },
            mobile: {
              transform: 'translateX(0rem) translateY(0rem)',
              bottom: "unset",

            }
          },
          {
            title: "Название презентации",
            text: "Введите название презентации.",
            styles: {
              transform: 'translateX(0rem) translateY(0rem)',
            },
            laptop: {
              transform: 'translateX(0) translateY(9rem)',
            },
            mobile: {
              transform: 'translateX(0rem) translateY(0rem)',
              bottom: "unset",

            }
          },
          {
            title: "Загрузка слайдов",
            text: "Загрузите слайды в формате видео (.mp4) или изображения.",
            styles: {
              transform: 'translateX(0rem) translateY(-17rem)',
            },
            laptop: {
              transform: 'translateX(0) translateY(-6rem)',
            },
            mobile: {
              transform: 'translateX(0rem) translateY(0rem)',
              bottom: "unset",

            }
          },
          // {
          //   title: "Подсказка!",
          //   text: "Загружайте PDF файл, наша система автоматически разложит презентацию на отдельные слайды",
          //   styles: {
          //     transform: 'translateX(0rem) translateY(0rem)',
          //     
          //     
          //   },
          //   mobile: {
          //     transform: 'translateX(0rem) translateY(0rem)',
          //     bottom: "unset",
          //     
          //   }
          // },
        ]
      }

      return []
    });

    const scrollIntoView = (id) => {
      if (!id) {
        scroll(0, 0);
        return
      }

      document.getElementById(id)?.scrollIntoView({
        behavior: 'smooth',
        inline: 'start'
      });
    }


    watch(() => props.educationStep, (val) => {

      let id = '';

      if (props.page === 'settings') {
        if (val === 1) {
          id = 'changeAvatar'
        }
        if (val === 2) {
          id = 'aboutMe'
        }
        if (val === 3) {
          id = 'saveButton'
        }
      }

      setTimeout(() => scrollIntoView(id), 100)

    });

    onMounted(() => {
      document.body.setAttribute('style', 'overflow:hidden')
    })


    return {
      emit,
      trainingSteps,
      windowWidth,
      nextStep: () => emit('nextStep'),
      closeTraining: () => emit('closeTraining'),

    }
  }
};
</script>

<template>
  <div class="training">
    <div class="modal"
         :style="windowWidth >=1000 ?( windowWidth >=1500 ? trainingSteps[educationStep]?.styles : trainingSteps[educationStep]?.laptop  ):trainingSteps[educationStep]?.mobile  ">
      <div class="caption">
        <img src="@/assets/icons/robot-face-small.png" alt="" class="caption__img">
        <p class="title">{{ trainingSteps[educationStep]?.title }}</p>
      </div>
      <p class="text">{{ trainingSteps[educationStep]?.text }}</p>
      <div class="buttons ">
        <button class=" link-default" @click="emit('next')">
          {{ educationStep !== trainingSteps.length - 1 ? 'Далее' : 'Завершить обучение' }}
        </button>
        <button class="link-default" @click="emit('close')"
                v-if="educationStep ===0">
          Пропустить обучение
        </button>
      </div>
      <div class="steps">
        <div class="steps-filling ">
          <div class="step " :class="{'step__active': item<=educationStep + 1}"
               v-for="item in trainingSteps.length" :key="item"></div>
        </div>
        <div class="steps-texts">
          <p class="steps-text">Шаг {{ educationStep + 1 }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="bg">
  </div>

</template>

<style scoped>
.training {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 9;
  display: flex;
  padding: 1rem;
  left: 0;
  top: 0;
  justify-content: center;
  font-family: "GolosText", sans-serif;
  transition: all 1s ease;
  overflow: auto;
}

.bg {
  position: fixed;
  fill: rgba(157, 196, 248, 0.30);
  backdrop-filter: blur(4px);
  background: rgba(157, 196, 248, 0.3);
  z-index: 3;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;

}

.modal {
  max-width: 24rem;
  width: 100%;
  padding: 1.5rem;
  margin: auto;
  position: relative;
  z-index: 9;
  border-radius: 1.5rem;
  background: #FFF;
  transition: all 0.5s ease;
}

.caption {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.caption__img {
  width: 3.625rem;
}

.title {
  color: #000;

  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.015rem;
}

.text {
  margin-top: 0.5rem;
  color: rgba(0, 0, 0, 0.60);
  font-size: 1rem;
  line-height: 1.5rem;
}
.text span{
  font-weight: 600;
}
.buttons {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.steps {
  margin-top: 1rem;
}

.steps-filling {
  display: flex;
  gap: 0.25rem;
  padding: 0.25rem;
  border-radius: 0.5rem;
  background: #CAD4E1;
}

.step {
  max-width: 100%;
  width: 100%;
  height: 0.5rem;
  border-radius: 0.5rem;
  background: #8B98AA;
}

.step__active {
  background: #32A071;
}


.steps-texts {
  margin-top: 0.25rem;
  display: flex;
  gap: 0.25rem;
  padding: 0.25rem;
}

.steps-text {
  max-width: 100%;
  width: 100%;
  text-align: center;
  color: #ABB4C0;
  font-size: 0.75rem;
  line-height: 1.5rem;
}

@media (max-width: 1000px) {


  .steps {
    margin-top: 0.5rem;
    margin-left: auto;
    color: #ABB4C0;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: normal;
  }

  .steps span {
    color: #003099;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: normal;
  }

  .link-default {
    padding: 0.63rem;
    font-size: 0.75rem;
    line-height: normal;
    border-radius: 0.5rem;
  }


  .management .block-img {
    max-width: 12.0625rem;
  }

  .interaction .block-img {
    max-width: 7.4375rem;
  }


}
</style>