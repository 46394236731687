import {
    bookedEvent,
    bookingEventPlace,
    fetchEventPlacements,
    isVideoRecordingAvailable,
    fetchCalendarEvents,
    fetchCurrentEvent,
    saveEvent, fetchCurrentEventByCode, fetchEventsForLanding
} from "@/store/calendar/serverInterationMethods";
import {errorHandler} from "@/lib/errorHandler";
import router from "@/router";

export const calendar = {
    actions: {
        async fetchEventPlacements({getters, commit}, eventId) {
            const {data} = await fetchEventPlacements(getters.userToken, eventId);
            commit('setEventPlacements', data);
        },

        async bookingEventPlace({getters}, {row, column, eventId}) {
            const {status} = await bookingEventPlace(getters.userToken, {row, column, eventId});
            if (status === 200) {
                return router.push({name: 'calendarPage'}).then(() => {
                    errorHandler({name: 'eventBooked', status})
                });
            } else {
                errorHandler({name: 'eventBooked', status})
            }
        },

        async fetchEventsForNotifications({getters},) {
            const {data} = await fetchCalendarEvents(getters.userToken, 1, 10, null, null, (getters.userData?.role === 'guide' || getters.userData?.role === 'lecture') ? true : null, true);
            return data;
        },
        async fetchEventsForLanding(_, {type, page, limit}) {
            const {data} = await fetchEventsForLanding(page, limit, type);
            return data;
        },

        async fetchCalendarEvents({getters, commit}, {type, page, limit, id}) {
            const {data} = await fetchCalendarEvents(getters.userToken, page, limit, type, id, (getters.userData?.role === 'guide' || getters.userData?.role === 'lecture') ? true : null);
            commit('setCalendarEvents', {data, page});
            return !(data && data.length < limit);
        },
        async bookedEvent({getters, commit}, data) {
            const {status} = await bookedEvent(getters.userToken, data);
            errorHandler({name: 'eventSubscribe', status});
            if (status === 200) {
                commit('signUpEvent', data.eventId);
            }
            return status;
        },
        async checkVideoRecordingAvailable({getters}, url) {
            const {status} = await isVideoRecordingAvailable(getters.userToken, url);
            return {state: status === 200};
        },
        async fetchCurrentEvent({getters, commit}, id) {
            let data;
            if (id === 'new') {
                data = {
                    id: null,
                    title: null,
                    eventDate: null,
                    eventDateStart: new Date(),
                    eventDateEnd: new Date(),
                    description: null,
                    previewUrl: null,
                    eventPlace: null,
                    eventSlots: null,
                    participantsLimit: null,
                    type: 'excursion',
                    lectorId: getters?.userData?.id
                }
            } else {
                const response = await fetchCurrentEvent(getters.userToken, id);
                data = response.data[0] ?? null;
            }

            commit('setCurrentEvents', data);

            return data;
        },
        async fetchCurrentEventByCode({getters}, code) {
            return await fetchCurrentEventByCode(getters.userToken, code);
        },

        async saveEvent({getters}, data) {
            return await saveEvent(getters.userToken, data);
        },

    },
    mutations: {
        setEventPlacements(state, data) {
            state.eventPlacements = data;
        },


        setCalendarEvents(state, {data, page}) {
            if (page > 1) {
                try {
                    state.calendarEvents = [...state.calendarEvents, ...data];
                } catch (e) {
                    console.log(e);
                }
            } else {
                state.calendarEvents = data;
            }
        },
        signUpEvent(state, id) {
            const eventIndex = state.calendarEvents.map(item => item.id).indexOf(id);
            if (eventIndex !== -1) {
                state.calendarEvents[eventIndex].isSignedUp = true;
            }
        },
        setCurrentEvents(state, data) {
            state.currentEvent = data;
        }
    },
    state: {
        calendarEvents: [],

        currentEvent: null,
        eventPlacements: []
    },
    getters: {
        calendarEvents: state => state.calendarEvents,
        currentEvent: state => state.currentEvent,
        eventPlacements: state => state.eventPlacements,
    }
}