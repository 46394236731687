import {
    deletedSlides,
    deletePresentation,
    fetchPresentations,
    savePresentations,
    updatePresentation
} from "@/store/presentations/serverInterationMethods";
import {errorHandler} from "@/lib/errorHandler";
import {loadPDF} from "@/store/application/serverInterationMethods";

export const presentations = {
    actions: {
        async uploadSlide({getters}, {eventId, slideUrl, slideType}) {
            getters.webSocket?.send(JSON.stringify({
                action: 'EXCURSION_SHOW',
                eventId, slideUrl, slideType, token: getters.userToken
            }))
        },

        async deletePresentation({getters, commit}, id) {
            const {status} = await deletePresentation(getters.userToken, id);
            errorHandler({name: 'delete', status})
            if (status === 200) {
                commit('deletePresentation', id);
            }
        },
        async fetchPresentations({getters, commit}) {
            const {data} = await fetchPresentations(getters.userToken);
            commit('setPresentations', data);
        },


        async deletedSlides({getters}) {
            let {status} = await deletedSlides(getters.userToken, getters.deletedSlides);
            return status;
        },
        async savePresentations({getters, commit}) {
            if (getters.presentationFilesQueue.length) {
                await commit('setLoader', true);
                for (let i = 0; i < getters.presentationFilesQueue.length; i++) {
                    await this.dispatch('loadFile', {
                        file: getters.presentationFilesQueue[i].file, callback: async (url) => {
                            await commit('setFileUrl', {
                                url,
                                index: getters.presentationFilesQueue[i].index - 1,
                                type: getters.presentationFilesQueue[i].type
                            });
                        }
                    })
                }
                await commit('setLoader', false);
                await commit('clearPresentationsFiles');
            }
            if (getters.presentation.id) {
                if (getters.deletedSlides.ids.length) {
                    await this.dispatch('deletedSlides').then(status => {
                        if (status !== 200) {
                            return false;
                        }
                    })
                }
                this.commit('parsePresentation')
                const {status} = await updatePresentation(getters.userToken, getters.presentation);
                errorHandler({status, name: 'update', meta: {routeName: 'presentationsPage'}});

            } else {
                const {status} = await savePresentations(getters.userToken, getters.presentation);
                errorHandler({status, name: 'save', meta: {routeName: 'presentationsPage'}});
            }

        },
        async loadPDF({commit}, file) {
            await commit('setLoader', true);
            const formData = await new FormData();
            await formData.append('file', file);
            let {data} = await loadPDF(formData);
            commit('parsePDf', data);
            await commit('setLoader', false);

        }
    },
    mutations: {
        parsePresentation(state) {
            delete state.presentation.createDate;
            delete state.presentation.creatorId;
            for (let i = 0; i < state.presentation.slides.length; i++) {
                delete state.presentation.slides[i].presentationId;
            }


        },
        parsePDf(state, data) {
            if (state.presentation && state.presentation.slides.length && (state.presentation.slides[0].id || state.presentation.slides[0].fileLink)) {
                for (let i = 0; i < data.length; i++) {
                    state.presentation.slides.push({
                        order: state.presentation.slides.length + 1,
                        fileLink: data[i].url,
                        videoLink: null
                    })
                }
            } else {
                for (let i = 0; i < data.length; i++) {
                    data[i]['fileLink'] = data[i].url;
                    data[i]['videoLink'] = null;
                    delete data[i].url;
                }
                state.presentation.slides = data;
            }
        },
        setPresentations(state, data) {
            state.presentations = data;
            for (let i = 0; i < state.presentations.length; i++) {
                state.presentations[i].slides = state.presentations[i].slides.sort((a, b) => a.order - b.order);
            }
        },
        setPresentationName(state, data) {
            state.presentation.name = data;
        },
        setCurrentPresentation(state, id) {
            if (!id) {
                state.presentation = null;
                return false;
            }
            let index = state.presentations.length ? state.presentations.map(item => item.id).indexOf(id) : -1;
            if (index !== -1) {
                state.presentation = state.presentations[index];
                state.deletedSlides.presentationId = state.presentation.id;
            } else {
                state.presentation = {
                    name: `Презентация ${state.presentations.length + 1}`,
                    slides: [
                        {
                            fileLink: null,
                            videoLink: null,
                            order: 1
                        }
                    ]
                }
            }
        },
        addSlide(state) {
            state.presentation.slides.push({
                fileLink: null,
                videoLink: null,
                order: state.presentation.slides.length + 1
            });

        },
        setVideoUrl(state, {index, url}) {
            if (state.presentation.slides[index].fileLink) {
                state.presentation.slides[index].fileLink = null;
            }
            state.presentation.slides[index].videoLink = url;
        },
        addPresentationFilesQueue(state, {index, file, type}) {
            let add = true;
            for (let i = 0; i < state.presentationFilesQueue.length; i++) {
                if (state.presentationFilesQueue[i].index === index) {
                    state.presentationFilesQueue[i].file = file;
                    state.presentationFilesQueue[i].type = type;

                    add = false
                }
            }
            if (add) {
                state.presentationFilesQueue.push({file, index, type});
            }
            this.commit('setFileUrl', {index: index - 1, url: URL.createObjectURL(file), type});
        },

        setFileUrl(state, {index, url, type}) {
            if (type === 'file') {
                state.presentation.slides[index].fileLink = url;
                state.presentation.slides[index].videoLink = null;
                state.presentation.slides[index].transaltionLink = null;
            }
            if (type === 'video') {
                state.presentation.slides[index].videoLink = url;
                state.presentation.slides[index].fileLink = null;
                state.presentation.slides[index].transaltionLink = null;
            }
            if (type === 'translation') {
                state.presentation.slides[index].translationLink = url;
                state.presentation.slides[index].fileLink = null;
                state.presentation.slides[index].videoLink = null;
            }
        },


        setLoader(state, data) {
            state.loader = data;
        },

        clearPresentationsFiles(state) {
            state.presentationFilesQueue = [];
        },

        clearPresentation(state) {
            state.presentation = null;
            state.presentationFilesQueue = [];
        },
        swapSlides(state, {from, to}) {
            let fromOrder = state.presentation.slides[from].order;
            state.presentation.slides[from].order = state.presentation.slides[to].order;
            state.presentation.slides[to].order = fromOrder;
            [state.presentation.slides[from], state.presentation.slides[to]] = [state.presentation.slides[to], state.presentation.slides[from]]

            for (let i = 0; i < state.presentationFilesQueue?.length; i++) {
                if (state.presentationFilesQueue[i].index === state.presentation.slides[from].order) {
                    state.presentationFilesQueue[i].index = state.presentation.slides[to].order;
                }
                if (state.presentationFilesQueue[i].index === state.presentation.slides[to].order) {
                    state.presentationFilesQueue[i].index = state.presentation.slides[from].order;
                }
            }
        },
        deletePresentation(state, data) {
            let index = state.presentations.map(item => item.id).indexOf(data);
            if (index !== -1) {
                state.presentations.splice(index, 1);
            }
        },
        deleteSlide(state, slideOrder) {

            const index = state.presentation.slides?.map(item => item.order).indexOf(slideOrder);


            if (index <= -1) {
                return;
            }

            if (state.presentation.slides[index].id) {
                state.deletedSlides.ids.push(state.presentation.slides[index].id);
            }
            state.presentation.slides.splice(index, 1);
            for (let i = 0; i < state.presentation.slides.length; i++) {
                if (state.presentation.slides[i].order > slideOrder) {
                    state.presentation.slides[i].order--;
                }
            }
            for (let i = 0; i < state.presentationFilesQueue.length; i++) {

                if (state.presentationFilesQueue[i].index === slideOrder) {
                    state.presentationFilesQueue.splice(i, 1);
                    continue;
                }

                if (state.presentationFilesQueue[i].index > slideOrder) {
                    state.presentationFilesQueue[i].index--;
                }
            }
        },
        setCurrentViewSlide(state, data) {
            if (!data.slideUrl || !data.slideType) {
                state.currentViewSlide = null;
                return;
            }
            state.currentViewSlide = data;
        }
    },
    state: {
        presentations: null,
        presentation: null,
        presentationFilesQueue: [],
        loader: false,


        deletedSlides: {
            ids: [],
            presentationId: null
        },


        currentViewSlide: null,
    },
    getters: {
        presentations: state => state.presentations,
        presentation: state => state.presentation,
        presentationFilesQueue: state => state.presentationFilesQueue,
        loader: state => state.loader,
        deletedSlides: state => state.deletedSlides,
        currentViewSlide: state => state.currentViewSlide,
    }
}
