<script setup>
import {defineEmits, ref} from "vue"
import {useStore} from "vuex";
import {onClickOutside} from "@vueuse/core";

const {commit} = useStore();

const emit = defineEmits(['close']);
const availablePlacesEl = ref(null);

const availablePlaces = [
  {
    title: '№1 "Центральный"',
    description: `Павильон №1 "Центральный" — одно из самых главных и наиболее монументальных сооружений Выставки. Архитектура павильона отсылает к лучшим примерам русской классической архитектуры. Его уступчатая высотная композиция завершена шпилем со звездой, а трехъярусная композиция, торжественность и стиль навевают образы памятников русского ампира, более всего перекликаясь с силуэтом башни петербургского Адмиралтейства. Особое место в оформлении высотной композиции играют скульптуры, расположенные \\по четырем сторонам света. Экспозиция павильона отображала основные вехи в истории советского государства и успехи в индустриализации, сельском хозяйстве, науке и культуре.`,
    previewUrl: require('@/assets/new-icons/test-data/test-event.png'),
    type: 'Павильон',
    teleportId: 10
  },
  {
    title: '№34. Центр "Космонавтика и авиация"',
    description: `В центре "Космонавтика и авиация" можно увидеть более 120 уникальных образцов летательной и космической техники, экспонаты оборонно-промышленных предприятий, свыше 2 тысяч редких архивных документов, фотографий и видеоматериалов об истории космических достижений. Также в павильоне работает сувенирный магазин ВДНХ. В отреставрированном павильоне "Космос" работает музейный комплекс мирового уровня — центр "Космонавтика и авиация". Огромное выставочное пространство, посвященное отечественной истории освоения космоса, стало важнейшим этапом возрождения Выставки. Павильон построен в 1939 году.`,
    previewUrl: require('@/assets/new-icons/test-data/test-event-2.png'),
    type: 'Павильон',
    teleportId: 29
  },
];

function teleportToLocation(id) {
  if (!id) return;

  // console.log(`TELEPORT RedesignAvailablePlaces.vue: ${id}`)

  commit('stIsVdnh', id);
  document.dispatchEvent(new CustomEvent('Teleport', {
    detail: id
  }));
  emit('close');
}


onClickOutside(availablePlacesEl, () => {
  emit('close');
})
</script>

<template>
  <div class="shell">
    <div class="modal" ref="availablePlacesEl">
      <div class="modal-content">
        <div class="modal-caption">
          <p class="title">Доступные места для посещения</p>
          <button class="close" @click="emit('close')">
            <img src="" alt="" class="close__icon">
          </button>
        </div>
        <div class="events">
          <div class="event" v-for="(item,index) in availablePlaces" :key="index">
            <div class="event__img">
              <img :src="item?.previewUrl" alt="" class="event__img-content">
            </div>
            <p class="event__status">{{ item?.type }}</p>
            <p class="event__title">{{ item?.title }}</p>
            <p class="event__text">{{ item?.description }}</p>
            <button class="event__link link-default" @click="teleportToLocation(item?.teleportId)">Перейти к локации</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.shell {
  pointer-events: none;
  background: none;
}

.modal {
  padding: 0;
  max-width: 39.19rem;
  border-radius: 1.25rem;
  pointer-events: all;
  backdrop-filter: blur(8px);
  background: rgba(157, 196, 248, 0.3);
  border-radius: 0.5rem;
  padding: .625rem;
  max-width: 84rem;
  width: 100%;


  &-content {
    background: #fff;
    border-radius: 0.5rem;
    padding: 1.625rem 2.5rem;
    max-height: 80vh;
    overflow: auto;
  }

  .title {
    font-weight: 700;
    font-size: 1.5rem;
    letter-spacing: -0.01em;
    color: #000;
  }

  &-caption {
    position: relative;
    max-width: 100%;
    width: 100%;
    display: flex;
    align-items: center;
  }


  .close {
    background: center / contain no-repeat url("@/assets/new-icons/close-round.svg");
    backdrop-filter: blur(20px);
    width: 2rem;
    height: 2rem;
    border-radius: 0.5rem;


  }

  @media (max-width: 1100px) {
    &-content {
      padding: 1rem;
    }

    .title {
      font-size: 1.25rem;
    }
  }

}

.events {
  margin-top: 1.5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;

  .event {
    padding: 1rem;
    background: #ecf2fa;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;

    &__img {
      border-radius: 0.25rem;
      max-width: 100%;
      width: 100%;
      position: relative;
      padding-bottom: 56.25%;

      &-content {
        border-radius: 0.25rem;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    &__status {
      margin-top: 0.75rem;
      font-weight: 400;
      font-size: 0.75rem;
      letter-spacing: 0.03em;
      color: #abb4c0;
    }

    &__title {
      margin-top: 0.5rem;
      font-weight: 700;
      font-size: 1.5rem;
      letter-spacing: -0.01em;
      color: #000;
    }

    &__text {
      margin-top: 1rem;
      margin-bottom: 1rem;
      font-weight: 400;
      font-size: 0.88rem;
      line-height: 114%;
      color: rgba(0, 0, 0, 0.5);
    }

    &__link {
      margin-top: auto;
    }
  }

  @media (max-width: 1100px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;

    .event {
      padding: 0.75rem;

      &__title {
        font-size: 1.25rem;
      }

    }
  }
}
</style>