<template>
  <header class="header">
    <div class="header-left">

      <div class="menu__element-wrapper ">
        <div class="menu__element wifi">
          <div id="connection" class="tooltip">
            <svg version="1.1" id="connectionStrength" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 494.45 494.45"
              style="enable-background: new 0 0 494.45 494.45" xml:space="preserve">
              <circle id="dot" cx="247.125" cy="398.925" r="35.3" />

              <path id="middle"
                d="M395.225,277.325c-6.8,0-13.5-2.6-18.7-7.8c-71.4-71.3-187.4-71.3-258.8,0c-10.3,10.3-27.1,10.3-37.4,0
                            s-10.3-27.1,0-37.4c92-92,241.6-92,333.6,0c10.3,10.3,10.3,27.1,0,37.4C408.725,274.725,401.925,277.325,395.225,277.325z" />

              <path id="outer" d="M467.925,204.625c-6.8,0-13.5-2.6-18.7-7.8c-111.5-111.4-292.7-111.4-404.1,0c-10.3,10.3-27.1,10.3-37.4,0
                            s-10.3-27.1,0-37.4c64-64,149-99.2,239.5-99.2s175.5,35.2,239.5,99.2c10.3,10.3,10.3,27.1,0,37.4
                            C481.425,202.025,474.625,204.625,467.925,204.625z" />

              <path id="inner" d="M323.625,348.825c-6.8,0-13.5-2.6-18.7-7.8c-15.4-15.4-36-23.9-57.8-23.9s-42.4,8.5-57.8,23.9
                            c-10.3,10.3-27.1,10.3-37.4,0c-10.3-10.3-10.3-27.1,0-37.4c25.4-25.4,59.2-39.4,95.2-39.4s69.8,14,95.2,39.5
                            c10.3,10.3,10.3,27.1,0,37.4C337.225,346.225,330.425,348.825,323.625,348.825z" />
            </svg>
            <span class="tooltiptext" id="qualityText">Not connected</span>
          </div>
        </div>
      </div>
      <!-- TODO Счёт -->
      <div v-if="false" class="score">
        <img src="@/assets/new-icons/score.svg" alt="" class="score__img">
        <p class="score__text">120</p>
      </div>
      <button class="link-default" v-if="isRobotGuide" @click="disableRobotGuide">Отключить гид</button>

    </div>
    <div class="header-filling header-right">
      <div class="menu__element-wrapper">
        <button v-if="gameLoaded" @click="changeAvatarConstructState"
          :class="{ 'menu-element__active': viewAvatarConstruct }" class="menu__element avatar">
          <svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="32" cy="32" r="32" fill="none" />
            <path
              d="M31.9987 18.6667C32.7059 18.6667 33.3842 18.9476 33.8843 19.4477C34.3844 19.9478 34.6654 20.6261 34.6654 21.3334C34.6654 22.0406 34.3844 22.7189 33.8843 23.219C33.3842 23.7191 32.7059 24 31.9987 24C31.2915 24 30.6132 23.7191 30.1131 23.219C29.613 22.7189 29.332 22.0406 29.332 21.3334C29.332 20.6261 29.613 19.9478 30.1131 19.4477C30.6132 18.9476 31.2915 18.6667 31.9987 18.6667ZM29.9987 25.3334H33.9987C34.7059 25.3334 35.3842 25.6143 35.8843 26.1144C36.3844 26.6145 36.6654 27.2928 36.6654 28V35.3334H34.6654V45.3334H29.332V35.3334H27.332V28C27.332 27.2928 27.613 26.6145 28.1131 26.1144C28.6132 25.6143 29.2915 25.3334 29.9987 25.3334Z"
              fill="#ffffff" />
          </svg>
        </button>
      </div>
      <div class="menu__element-wrapper">

        <!-- TODO проверить включение и выключение звука -->
        <button class="menu__element sound" @click="turnOffSound = !turnOffSound">
          <svg v-if="!turnOffSound" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M19.4279 19.124C19.7593 19.3425 20.2038 19.2446 20.4449 18.883C21.7708 16.8941 22.5693 14.4231 22.5693 11.7111C22.5693 8.9915 21.7557 6.52805 20.4449 4.53168C20.2038 4.16254 19.7593 4.07213 19.4279 4.2906C19.0663 4.52414 19.0135 4.96862 19.2621 5.36789C20.4148 7.12319 21.1304 9.30037 21.1304 11.7111C21.1304 14.1067 20.4148 16.299 19.2621 18.0467C19.0135 18.446 19.0663 18.8905 19.4279 19.124ZM9.67955 18.4611C10.2446 18.4611 10.6589 18.0392 10.6589 17.4817V5.9781C10.6589 5.41309 10.2446 4.96108 9.66448 4.96108C9.28027 4.96108 9.00153 5.11929 8.59473 5.51856L5.44573 8.46415C5.40053 8.50935 5.34026 8.53196 5.27246 8.53196H3.14049C2.02553 8.53196 1.42285 9.1497 1.42285 10.3174V13.1198C1.42285 14.2875 2.02553 14.9053 3.14049 14.9053H5.27246C5.34026 14.9053 5.393 14.9279 5.44573 14.9731L8.59473 17.9413C8.9714 18.3029 9.28781 18.4611 9.67955 18.4611ZM16.3768 17.0825C16.7234 17.2934 17.1452 17.2181 17.3863 16.864C18.3657 15.4778 18.9382 13.6171 18.9382 11.7036C18.9382 9.79005 18.3732 7.92174 17.3863 6.54312C17.1452 6.18904 16.7234 6.10617 16.3768 6.32465C16.0152 6.55065 15.9625 7.00266 16.2337 7.40193C17.0322 8.57716 17.4918 10.1065 17.4918 11.7036C17.4918 13.2931 17.0247 14.8224 16.2337 16.0052C15.97 16.4044 16.0152 16.8489 16.3768 17.0825ZM13.3408 15.0635C13.6648 15.2744 14.0866 15.1991 14.3202 14.8676C14.9304 14.054 15.292 12.9014 15.292 11.7036C15.292 10.5057 14.9304 9.36064 14.3202 8.53949C14.0866 8.20802 13.6648 8.13268 13.3408 8.35115C12.9717 8.59222 12.9039 9.0367 13.1977 9.47364C13.612 10.0688 13.8456 10.8673 13.8456 11.7036C13.8456 12.5398 13.612 13.3308 13.1977 13.9335C12.9039 14.3704 12.9717 14.8149 13.3408 15.0635Z"
              fill="white" />
          </svg>
          <svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-else>
            <path
              d="M18.3599 15.8076V6.97461C18.3599 6.31543 17.8765 5.78809 17.1997 5.78809C16.7515 5.78809 16.4351 5.98145 15.9517 6.43848L12.5415 9.59375C12.5063 9.62891 12.4624 9.65527 12.4185 9.65527H12.1987L18.3599 15.8076ZM21.436 22.3818C21.7085 22.6455 22.1567 22.6543 22.4204 22.3818C22.6929 22.1006 22.6929 21.6611 22.4204 21.3887L7.02197 5.99902C6.74951 5.72656 6.30127 5.71777 6.02002 5.99902C5.75635 6.2627 5.75635 6.71973 6.02002 6.9834L21.436 22.3818ZM9.54443 17.3896H12.0229C12.1021 17.3896 12.1724 17.416 12.2251 17.4688L15.9517 20.9316C16.3911 21.3535 16.7603 21.5381 17.2085 21.5381C17.7183 21.5381 18.105 21.2744 18.2808 20.7559L8.0415 10.5254C7.74268 10.8594 7.58447 11.3516 7.58447 12.002V15.3418C7.58447 16.7129 8.25244 17.3896 9.54443 17.3896Z"
              fill="white" />
          </svg>
        </button>
      </div>
      <div class="menu__element-wrapper">
        <router-link :to="{ name: 'mainPage' }" class="menu__element settings">
          <img src="@/assets/icons/exit.svg" alt="burger" class="menu__icon desktop" />
          <img src="@/assets/icons/exit.svg" alt="burger" class="menu__icon mobile" />
        </router-link>
      </div>
      <div class="menu__element-wrapper ">
        <button class="menu__element full" @click="fullScreen">
          <img src="@/assets/icons/game/full-screen.svg" alt="burger" class="menu__icon" />
        </button>
      </div>
      <div class="menu__element-wrapper mobile" @click="openChat">
        <button class="menu__element chat ">
          <img src="@/assets/icons/open-chat.svg" alt="burger" class="menu__icon" />
        </button>
      </div>
    </div>

  </header>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { appConfig } from "@/lib/appConfig";

export default {
  name: "GameVanyaHeader",
  props: ["viewChat", "trainingStep", "turnOffMicrophone", "myMediaStream", "userCall", "role", 'canUseButtons', 'gameLoaded', 'isRobotGuide', "viewAvatarConstruct"],
  setup(props, { emit }) {


    const turnOffSound = ref(false);
    const turnOffCamera = ref(0);
    watch(
      () => turnOffSound.value,
      (value) => {
        document.dispatchEvent(
            new CustomEvent("SetAudioVolume", {
              detail: Number(value),
            })
          );
      }
    );
    const tempAccess = computed(() => {
      const store = useStore();
      const user = store?.getters?.userData;

      return appConfig.appState.mode === 'prod' ? (user?.email === 'svetlana.lobanova@mail.ru' || user?.email === 'amirov.and@ya.ru') : true
    })

    const openWeather = ref(false);


    const sendWeather = (weather) => {
      document.dispatchEvent(new CustomEvent(weather.type, { detail: weather.value }))
    }


    const changeMicrophone = () => {
      emit('changeTurnOffMicrophone');
      props.myMediaStream?.dispatchEvent(new CustomEvent('changeMicroStatus', { detail: props.turnOffMicrophone }))
    }
    const fullScreen = () => {
      document.fullscreenElement ? document.exitFullscreen() : document.getElementById('app').requestFullscreen();
    }


    return {
      openChat: () => emit("openChat"),
      // openMap: () => emit("openMap"),

      turnOffCamera,
      turnOffSound,

      nextStep: () => emit("nextStep"),
      prevStep: () => emit("prevStep"),
      closeTraining: () => emit("closeTraining"),
      disableRobotGuide: () => {
        document.dispatchEvent(new Event('DestroyRoboGuide'))
        emit("robotGuideDisable")
      },

      changeViewPort: () =>
        document.dispatchEvent(
          new CustomEvent("ChangeViewportOutput", { detail: turnOffCamera.value ? 0 : 1 })
        ),
      changeMicrophone,
      fullScreen,
      openWeather,
      sendWeather,
      tempAccess,
      changeAvatarConstructState() {
        emit("changeAvatarConstructState");
      },
    };
  },
};
</script>

<style scoped>
.header {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;
  padding: 1rem;
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
}

.header-filling {
  position: relative;
  z-index: 2;
  display: flex;
  gap: 1rem;
  transition: all 1s ease-in-out;
  align-items: center;
  pointer-events: all;
}

.header-left {
  pointer-events: all;
  display: flex;
  gap: 1rem;
  align-items: center;
}


.menu__element {
  width: 100%;
  height: 100%;
  position: relative;
}

.wifi {
  background: #ffffff4d;
  border-radius: 100%;
  padding: 0.6rem;
}

.sound {
  background-color: #ffffff4d;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar {
  background-color: #ffffff4d;
  border-radius: 100%;
  display: flex;
  justify-content: center;
}

.sound svg {
  width: 1.34375rem;
}

.header-menu-open {
  border-radius: 80px 80px 0 80px;
}

.mobile {
  display: none;
}

.menu__element-wrapper {
  position: relative;
  height: 4rem;
  width: 4rem;
  background: rgba(157, 196, 248, 0.30);
  backdrop-filter: blur(4px);
  padding: 0.5rem;
  border-radius: 100%;
  display: flex;
  flex-shrink: 0;
}

.modal {
  position: absolute;
  transform: translateY(10%);
  opacity: 0;
  transition: all 0.5s ease-in-out;
  z-index: 3;
  pointer-events: none;
}

.modal__open {
  transform: translateY(0%);
  opacity: 1;
  pointer-events: all;
}

.modal__weather {
  top: 5rem;
  left: 0;
  width: min-content;
  border-radius: 15px;
}

.weather-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.75rem;
}

.weather-element {
  width: 3rem;
  height: 3rem;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  background: #ffffff4d;
  border-radius: 100%;
}

.weather-icon {
  width: 1.75rem;
  height: 1.75rem;
}

.score {
  display: flex;
  align-items: center;
  gap: .375rem;
  padding: 1rem 1rem;
  border-radius: 1.5rem;
  backdrop-filter: blur(40px);
  background: rgba(236, 242, 250, 0.3);
}

.link-default {
  width: unset;
  line-height: 1;
}

.score__text {
  font-weight: 500;
  font-size: 1rem;
  line-height: 125%;
  color: #fff;
}

.link__img {
  width: 1.5rem;
  height: 1.5rem;
}

.menu-element__active {
  background: #003099;
}

.menu-element__active path {
  fill: #fff;
  stroke: #fff;
}

.menu-element__active circle {
  fill: #003099;
}

@media (max-width: 1000px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: flex;
  }

  .menu__element-wrapper {
    padding: 0.25rem;
    width: 2.5rem;
    height: 2.5rem;
  }

  .header-filling {
    gap: 0.5rem;
    padding: 0;
  }

  .menu__element {
    width: 100%;
    height: 100%;
  }

  .wifi {
    width: 100%;
    height: 100%;
    padding: 0.5rem;
  }

  .header {
    transition: all 1s ease-in-out;
  }

  .header-with-open-chat {
    right: 420px;
    transition: all 1s ease-in-out;
  }

  .header-left {
    gap: 0.5rem;
  }

  .modal__weather {
    top: 3rem;

  }

  .modal__weather {
    gap: 0.5rem;
  }

  .weather-element {
    width: 2rem;
    height: 2rem;
  }

  .weather-element img {
    width: 0.875rem;
  }

  .link-default {
    padding: 0.5rem;
    font-size: 0.75rem;
  }

  .sound svg {
    width: 1rem;
  }
}
</style>
