<template>
  <div class="teleport">
    <p class="teleport-text">
      Желаете перейти на локацию {{ getLocationName }}?
    </p>
    <div class="teleport-btns">
      <button class="teleport__btn" @click="teleport">Перейти</button>
      <button class="teleport__btn teleport__btn-cancellation" @click="closeTeleport">
        Отмена
      </button>
    </div>
  </div>
</template>

<script>
import {computed} from "vue";
import {useStore} from "vuex";

export default {
  name: "TeleportAcceptNotification",
  props: ["teleportId"],
  emits: [
    'setRoomLocation',
    'closeTeleport'
  ],
  setup(props, {emit}) {
    const {dispatch, commit} = useStore();

    const getLocationName = computed(() => {
      switch (props.teleportId) {
        case 0:
          return "Космос";
        case 1:
          return "Вднх";
        case 2:
          return "Центральный павильон";
        case 3:
          return "Вднх";
        case 4:
          return "Сибур";
        case 5:
          return "Вднх";
        case 6:
          return "Умный город";
        case 7:
          return "Вднх";
        default:
          return "Центральный павильон";
      }
    });

    const getLocationTeleportId = computed(() => 100 + props.teleportId);

    return {
      closeTeleport: () => emit("closeTeleport"),
      getLocationName,
      teleport: async () => {
        commit("stIsVdnh", props.teleportId);
        await dispatch("teleport", getLocationTeleportId.value);
        emit("closeTeleport");
      },
    };
  },
};
</script>

<style scoped>
.teleport {
  position: absolute;
  background: rgba(255, 255, 255, 0.36000001430511475);
  border-radius: 16px;
  backdrop-filter: blur(85px);
  margin: auto;
  z-index: 1000000;
  padding: 20px;
}

.teleport-text {
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  text-align: left;
  color: #ffffff;
}

.teleport-btns {
  margin-top: 15px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.teleport__btn {
  padding: 12px 14px;
  background: #003099;
  border: 1px solid #003099;
  border-radius: 8px;
  font-size: 15px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
}

.teleport__btn-cancellation {
  background: #ddecff;
  color: #003099;
}

@media (max-width: 1800px) {
  .teleport {
    position: absolute;
    border-radius: 12px;
    padding: 15px;
  }

  .teleport-text {
    font-size: 15px;
    line-height: 1;
  }

  .teleport-btns {
    margin-top: 15px;
  }

  .teleport__btn {
    padding: 10px 12px;
    font-size: 14px;
    line-height: 1;
  }
}
</style>
