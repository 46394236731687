<template>
  <div class="scene-wrapper">
    <!-- <div class="scene-big" v-if="viewBigScene"> -->
    <div class="scene-big" v-if="viewBigScene">
      <p class="scene-big__text" v-if="false"></p>
      <img
        v-if="slide.slideType === 'image'"
        :src="slide.slideUrl"
        alt=""
        class="scene-big__img"
      />
      <video
        v-if="slide.slideType === 'video'"
        controls
        :src="slide.slideUrl"
        alt=""
        class="scene-big__img"
      />

      <button class="close" @click="viewBigScene = false"></button>
    </div>
    <div class="scene-small" v-if="!viewBigScene">
      <img
        v-if="slide.slideType === 'image'"
        :src="slide.slideUrl"
        alt=""
        class="scene-small__img"
      />
      <video
        v-if="slide.slideType === 'video'"
        controls
        :src="slide.slideUrl"
        alt=""
        class="scene-small__img"
      />

      <button
        @click="viewBigScene = true"
        class="scene-small__change-size"
      ></button>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "PresentationWindow",
  props: ["slide"],
  setup() {
    return {
      viewBigScene: ref(false),
    };
  },
};
</script>

<style scoped>
.scene-wrapper {
  position: fixed;
  left: 20px;
  bottom: 20px;
  z-index: 6;
}

.scene-small {
  position: absolute;
  top: -265px;
  width: 270px;
  height: 170px;
  background-color: rgba(162, 169, 176, 0.1599999964237213);
  background-image: url("@/assets/icons/scene-small-bg.png");
  background-position: center;
  background-size: cover;
  border-radius: 14px;
  left: 10px;
}

.scene-small__img {
  width: 100%;
  height: 100%;
  border-radius: 14px;
  object-fit: cover;
  object-position: center;
}

.scene-small__change-size {
  position: absolute;
  right: 7px;
  top: 7px;
  width: 36px;
  height: 36px;
  background: url("@/assets/icons/change-size.svg");
}

.scene-big {
  position: fixed;
  top: 30px;
  left: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(162, 169, 176, 0.1599999964237213);
  background-image: url("@/assets/icons/scene-small-bg.png");
  background-position: center;
  background-size: cover;
  border-radius: 24px;
  backdrop-filter: blur(50px);
  width: calc(100vw - 1000px);
  height: calc(100vh - 448px);
}

.scene-big__text {
  font-weight: 500;
  font-size: 80px;
  line-height: 1.2;
  color: #ffffff;
}

.scene-big__img {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5;
  border-radius: 24px;
  object-fit: cover;
  object-position: center;
}

.close {
  position: absolute;
  top: 15px;
  right: 15px;
  background: url("@/assets/icons/close-small.svg");
  width: 20px;
  height: 20px;
  background-size: cover;
  z-index: 6;
}

@media (max-width: 1800px) {
  .scene-small {
    top: -130px;
    width: 220px;
    height: 120px;
  }

  .scene-small__change-size {
    right: 5px;
    top: 5px;
    width: 30px;
    height: 30px;
    background-size: cover;
  }

  .scene-big {
    top: 20px;
    left: 20px;
    width: calc(100vw - 581px);
    height: calc(100vh - 274px);
  }

  .scene-big__text {
    font-size: 100px;
    line-height: 1.2;
  }

  .close {
    top: 12px;
    right: 12px;
    background-size: cover;
    width: 16px;
    height: 16px;
  }

  .scene-big__text {
    font-size: 48px;
    line-height: 1.4;
  }
}

@media (max-width: 1000px) {
  .scene-big {
    top: 20px;
    left: 20px;
    width: calc(100vw - 360px);
    height: calc(100vh - 285px);
  }

  .scene-small {
    left: 0;
  }
}
</style>
