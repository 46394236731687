<template>
  <div class="invitation">
    <div class="invitation-caption">
      <div class="invitation-icon-wrapper">
        <img v-if="reason==='callUp'" src="@/assets/icons/game/micro-icon.svg" alt="" class="invitation-icon">
        <img v-if="reason==='callError'|| reason==='dropCall'" src="@/assets/icons/game/drop-call.svg" alt=""
             class="invitation-icon">
      </div>
      <button class="close" @click="cancelCall">Закрыть
        <img src="@/assets/icons/game/close-small.svg" alt="" class="close-icon">
      </button>
    </div>
    <p class="invitation-text" v-if="reason==='callUp'">
      {{
        callCounter ? `Ожидаем ответа: ${callCounter}` : `Пригласить ${receiverName ? receiverName : 'пользователя'} в аудиочат ?`
      }}</p>

    <p class="invitation-text" v-if="reason==='callError'">Не удалось связаться с пользователем</p>

    <p class="invitation-text" v-if="reason==='dropCall'">Пользователь завершил звонок</p>
    <div class="invitation-activity">
      <button class="activity-close activity-btn" @click="cancelCall" v-if="reason==='dropCall'||reason==='callError'">
        Ок
      </button>

      <button class="activity-close activity-btn" @click="cancelCall" v-if="reason==='callUp'">Отмена</button>
      <button class="activity-invite activity-btn" @click="callUp" v-if="reason==='callUp'&&!callCounter">Пригласить
      </button>
    </div>
  </div>
</template>

<script>


import {useStore} from "vuex";
import {ref, watch} from "vue";

export default {
  name: "ChatInvitation",
  props: ['reason', 'receiverId', 'receiverName'],
  setup(props, {emit}) {
    const store = useStore();

    const cancelCall = () => emit('closeInviteCall');
    const callTimerId = ref(null);
    const callCounter = ref(0);


    watch(() => props.reason, (val) => {
      if (val === 'callSuccess') {
        clearInterval(callTimerId.value);
        cancelCall();
      }
      if (val === 'dropCall' || val === 'callError') {
        clearInterval(callTimerId.value);
      }
    })
    const callUp = () => {
      store.commit('callUp', {
        userId: props.receiverId,
        userName: store.getters.userData?.name ? store.getters.userData.name : 'пользователь'
      });
      callTimerId.value = setInterval(() => {
        callCounter.value++;
        if (callCounter.value >= 30) {
          clearInterval(callTimerId.value);
          store.commit('dropCall');
          emit('closeInviteCall')
        }
      }, 1000)
    }
    return {
      callUp,
      cancelCall,
      callCounter,
    }
  }

};
</script>

<style scoped>
.invitation {
  position: absolute;
  padding: 20px 30px;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.16);
  backdrop-filter: blur(42.5px);
  z-index: 100000;
  max-width: 577px;
  width: 100%;
}

.invitation-caption {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.invitation-icon-wrapper {
  width: 68px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 100%;
}


.close {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #FFF;
  font-size: 17px;
  font-weight: 400;
  line-height: 20px;
}

.close-icon {
  width: 20px;
  height: 20px;
}

.invitation-text {
  margin-top: 31px;
  text-align: center;
  color: #FFF;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}

.invitation-activity {
  margin-top: 52px;
  display: flex;
  justify-content: center;
  gap: 16px;
}

.activity-close {
  border: 1px solid #DEEDFF;
  background: #DEEDFF;
  color: #003099;
}

.activity-btn {
  width: 128px;
  height: 48px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 17px;
  line-height: 17px;
  border-radius: 8px;
}

.activity-invite {
  background: #003099;
  color: #FFFFFF;
}

@media (max-width: 1800px) {
  .invitation {
    padding: 20px;
    max-width: 450px;
  }

  .invitation-icon-wrapper {
    height: 50px;
    width: 50px;
  }

  .close {
    gap: 6px;
    font-size: 15px;
    line-height: 15px;
  }

  .close-icon {
    width: 15px;
    height: 15px;
  }

  .invitation-text {
    margin-top: 20px;
    font-size: 17px;
    font-size: 17px;
  }

  .invitation-activity {
    margin-top: 20px;
    gap: 10px;
  }

  .activity-btn {
    width: 110px;
    height: 36px;
    font-size: 15px;
    line-height: 15px;
  }

  .activity-invite {
    background: #003099;
    color: #FFFFFF;
  }
}
</style>
