<template>
  <div class="about-wrapper">
    <div class="about-content">
      <div class="about">
        <div class="about-info">
          <div class="logo">
            <img src="@/assets/icons/logo-big.svg" alt="" class="logo__img">
          </div>
          <div class="info__caption">
            <p class="text">Компания:</p>
            <p class="title">Название компании</p>
          </div>
          <div class="description">
            <div class="description__element">
              <p class="text">Телефон:</p>
              <p class="text text--dark">+7 (999) - 999 - 99 - 99</p>
            </div>
            <div class="description__element">
              <p class="text">E-mail:</p>
              <p class="text text--dark">info@ivanova.ru </p>
            </div>
            <div class="description__element">
              <p class="text">Адрес:</p>
              <p class="text text--dark">пр-т Мира, 119, Москва</p>
            </div>
            <div class="description__element">
              <p class="text">Соц. сети</p>
              <div class="social">
                <a href="" class="social__link vk"></a>
                <a href="" class="social__link fb"></a>
                <a href="" class="social__link youtube"></a>
              </div>
            </div>
          </div>
          <a href="" class="link-default">Сайт компании</a>
        </div>
        <div class="about-container">
          <p class="title-small">Описание компании:</p>
          <p class="main-text">А также ключевые особенности структуры проекта, вне зависимости
            от их уровня, должны быть ассоциативно распределены по отраслям. Таким образом, сложившаяся структура
            организации создаёт необходимость включения в производственный план целого ряда внеочередных мероприятий с
            учётом комплекса поэтапного и последовательного развития общества. Картельные сговоры не допускают ситуации,
            при которой сторонники тоталитаризма в науке набирают популярность среди определенных слоев населения, а
            значит, должны быть рассмотрены исключительно в разрезе маркетинговых и финансовых предпосылок. Но активно
            развивающиеся страны третьего мира набирают популярность среди определенных слоев населения, а значит,
            должны быть ассоциативно </p>
          <div class="media">
            <p class="title-small">Медиагалерея</p>
            <carousel :settings="settings" :breakpoints="breakpoints" class="slider" @slide-end="slideEnd"
                      :wrapAround="true">
              <slide @click="pickSlide(index)" class="slider-element" v-for="(slide, index) in slides" :key="index">
                <img loading="lazy" :src="slide.previewUrl" alt="" class="carousel__item"/>
              </slide>
              <template #addons>
                <Pagination class="pagination"/>
                <Navigation/>
              </template>
            </carousel>
          </div>
        </div>
        <button class="about__close" @click="closeAbout">
          <img src="@/assets/icons/close-main.svg" alt="" class="close-img"/>
        </button>
      </div>
    </div>
  </div>
  <div @click="currentBigPicture = null" class="big-picture-wrapper desktop" v-if="currentBigPicture">
    <div class="big-picture">
      <button class="big-picture__close" @click="currentBigPicture = null">
        <img src="@/assets/icons/close-white.svg" alt="Закрыть" class="close"/>
      </button>
      <img @click="$event.stopPropagation()" :src="currentBigPicture" alt="" class="big-picture__img"/>
    </div>
  </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import {Carousel, Slide, Navigation, Pagination} from "vue3-carousel";
import {ref} from "vue";

export default {
  name: "NewAboutCompany",
  components: {
    Navigation,
    Carousel,
    Slide,
    Pagination
  },
  setup(props, {emit}) {
    const closeAbout = () => emit("closeAbout");

    const currentSlideIndex = ref(0);
    const currentBigPicture = ref(null);
    const slides = ref([
      {
        previewUrl: require("@/assets/icons/test-data/169.png"),
      },
      {
        previewUrl: require("@/assets/icons/test-data/object.png"),
      },
      {
        previewUrl: require("@/assets/icons/test-data/11.png"),
      },
      {
        previewUrl: require("@/assets/icons/test-data/21.png"),
      },
      {
        previewUrl: require("@/assets/icons/test-data/test-face.png"),
      },
    ])
    const pickSlide = (index) => {
      if (currentSlideIndex.value === index) {
        currentBigPicture.value = slides.value[index].previewUrl;
      }
    };
    const slideEnd = (data) => {
      currentSlideIndex.value = data.currentSlideIndex;
    };

    return {
      closeAbout,
      currentBigVideo: false,
      previewHeight: 0,
      sectionRanges: [],
      sectionValue: 0,
      sectionsSize: 10,
      pickSlide,
      slideEnd,

      settings: {
        itemsToShow: 1,
        pauseAutoplayOnHover: false,
        transition: 700,
      },
      breakpoints: {
        400: {
          itemsToShow: 1,
        },
        700: {
          itemsToShow: 2.7,
        },
        1000: {
          itemsToShow: 2.7,
        },
        1300: {
          itemsToShow: 2.7,
        },
        1800: {
          itemsToShow: 3.5,
        },
        2200: {
          itemsToShow: 4,
        },
      },

      currentSlideIndex,
      currentBigPicture,
      slides,

      name: "",
      lockedAccess: false
    };

  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
  font-family: "GolosText", sans-serif;
}

.about-wrapper {
  padding: 0 1rem;
  height: 60vh;
  position: absolute;
  z-index: 3;
  max-width: 60vw;
  width: 100%;
  display: flex;

}

.about-content {
  padding: 0.63rem;
  max-width: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 0.5rem;
  background: rgba(157, 196, 248, 0.30);
  backdrop-filter: blur(4px);
  pointer-events: all;
}

.about {
  height: 100%;
  max-width: 100%;
  width: 100%;
  padding: 1rem;
  border-radius: 0.5rem;
  background: #FFF;
  display: flex;
  gap: 1rem;
}

.about-info {
  max-width: 19rem;
  width: 100%;
  padding: 1rem;
  border-radius: 1rem;
  background: #ECF2FA;
  display: flex;
  flex-direction: column;
}


.about-container {
  display: flex;
  flex-direction: column;
  text-align: left;
  max-width: 100%;
  width: 100%;
  height: 100%;
}

.about__close {
  display: flex;
  flex-shrink: 0;
  width: 2rem;
  height: 2rem;
}

.close-img {
  width: 100%;
  height: 100%;
}

li {
  padding-left: 0;
}

.pagination {
  margin: 0.5rem 0 0 0;
}

.pagination :deep(.carousel__pagination-button::after) {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 100%;
  background: rgba(0, 48, 153, 0.30);
}

.pagination :deep(.carousel__pagination-button--active::after) {
  background: #003099;
}

.pagination :deep(.carousel__pagination-item) {
  padding-left: 0.2rem !important;
  margin-left: 0;
}

.slider :deep(.carousel__icon) {
  fill: #8B98AA;
}

.slider :deep(.carousel__prev) {
  background: #ECF2FA;
  border-radius: 100%;
}

.slider :deep(.carousel__next) {
  background: #ECF2FA;
  border-radius: 100%;
}

.carousel__slide {
  padding: 5px;
  height: 9rem;
}

.carousel__slide img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 0.5rem;
}

.carousel__viewport {
  perspective: 2000px;
}

.carousel__track {
  transform-style: preserve-3d;
}

.carousel__slide--sliding {
  transition: 0.5s;
}

.carousel__slide--active {
  cursor: pointer;
}

.carousel__slide {
  opacity: 0.9;
}


.logo {
  border-radius: 0.5rem;
  background: #FFF;
  max-width: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.logo__img {
  width: 8.938rem;
  height: 8.938rem;
  object-fit: cover;
  object-position: center;
}

.about-info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow: auto;
}

.link-default {
  margin-top: auto;
}

.text {
  color: #ABB4C0;
  font-size: 1rem;
}

.title {
  color: #000;
  font-size: 1.5rem;
  font-weight: 700;
}

.text--dark {
  margin-top: 0.25rem;
  color: rgba(0, 0, 0, 0.60);
}

.description {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.social {
  margin-top: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.social__link {
  display: flex;
  flex-shrink: 0;
  width: 3rem;
  height: 3rem;
}

.vk {
  background: center/contain no-repeat url("@/assets/icons/vk.svg");
}

.fb {
  background: center/contain no-repeat url("@/assets/icons/fb.svg");
}

.youtube {
  background: center/contain no-repeat url("@/assets/icons/youtube.svg");
}

.link-default {
  padding: 0.62rem;
  border-radius: 0.5rem;
}

.title-small {
  font-size: 1.25rem;
  color: #000;
  font-weight: 700;
}

.main-text {
  margin-top: 1rem;
  height: 100%;
  overflow: auto;

}

.media {
  max-width: 100%;
  width: 100%;
  margin-top: 1rem;
}

.carousel {
  margin-top: 1rem;
  margin-bottom: 0.69rem;
  cursor: grab;
}

.big-picture-wrapper {
  position: fixed;
  inset: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background: rgba(70, 70, 70, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  overflow: auto;
  scrollbar-width: thin;
}

.big-picture {
  position: relative;
  max-width: 50vw;
  max-height: 70vh;
}

.big-picture__close {
  position: absolute;
  width: 1.39vw;
  height: 1.39vw;
  top: 1.39vw;
  right: 1.39vw;
  z-index: 10;
}

video {
  height: auto;
  position: relative;
}

.big-picture__img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media (max-width: 1400px) {
.about-wrapper{
  height: 70vh;
  max-width: 80vw;
}
}
@media (max-width: 1000px) {
  .about-wrapper {
    padding: 0 3rem;
    height: calc(100% - 8rem);

  }

  .about-content {
    padding: 0.37rem;
    border-radius: 0.5rem;
  }

  .about {
    padding: 0.5rem;
    border-radius: 0.5rem;
    gap: 0.5rem;
  }

  .about-info {
    padding: 0.5rem;
    border-radius: 0.5rem;
  }

  .about-info-title {
    font-size: 0.75rem;
  }

  .about__close {
    width: 1.5rem;
    height: 1.5rem;
  }

  .about-info {
    max-width: 13rem;
    gap: 0.25rem;
  }
  .logo{
    padding: 0.5rem;
  }
  .logo__img{
    width: 3.938rem;
    height:3.938rem;
  }
  .text{
    margin-top: 0;
    font-size: 0.65rem;
  }
  .title{
    font-size: 1rem;
  }
  .description{
    gap: 0.25rem;
  }
  .social{
    gap: 0.25rem;
  }
  .social__link{
    width: 1.5rem;
    height: 1.5rem;
  }
  .link-default{
    padding: 0.5rem;
    font-size: 0.75rem;
    border-right: 0.25rem !important;
  }
  .title-small{
    font-size: 0.85rem;
  }
  .main-text{
    margin-top: 0.5rem;
    font-size: 0.65rem;
  }
  .media{
    margin-top: 0.5rem;
  }
  .slider{
    margin: 0.25rem 0 0 0 ;
  }
  .pagination{
    display: none;
  }
  .slider :deep(.carousel__prev) {
    display: none;
  }

  .slider :deep(.carousel__next) {
    display: none;
  }
  .carousel__slide{
  height: 6rem;
  }

}
</style>
