export const config = {
  displayMedia: {
    video: {
      width: { ideal: 1280 },
      height: { ideal: 720 },
      cursor: 'always',
      displaySurface: 'browser',
      logicalSurface: true,
      videoLayer: true,
      mediaSource: 'browser'
    },
    selfBrowserSurface: 'include',
    preferCurrentTab: true,
    systemAudio: 'include',
    surfaceSwitching: 'include',
    monitorTypeSurfaces: 'include',
    audio: true,
  },
  userMedia: {
    audio: true,
  }
};