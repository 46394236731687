<template>
  <div class="modal modal__open">
    <div class="modal-content">
      <div class="modal-filling">
        <div class="map-info">

          <p class="map-info-title">Локации ВДНХ</p>
          <label for="" class="search">
            <img src="@/assets/icons/search.svg" alt="" class="search-icon">
            <input v-model="searchObjName" type="text" class="search-input" placeholder="Поиск по локациям">
          </label>
          <div class="map-list">
            <map-element-small v-for="(item, index) in objectsList" :map-element="item" :key="index" :index="index"
                               @openObjectInfo="openObjectInfo">
            </map-element-small>
          </div>
        </div>
        <div class="description" id="objectInfo" v-if="currentObject">
          <div class="description-caption">
            <div class="description-block">
              <p class="subtitle">{{ currentObject.category }}</p>
              <p class="title">{{ locale === 'en' ? currentObject?.name_eng : currentObject?.name }}</p>
            </div>
            <button class="close " @click="closeWindow">
              <img src="@/assets/icons/close-main.svg" alt="" class="close-white"/>
            </button>
          </div>
          <p class="about">О месте</p>
          <pre class="text">
            {{
              (locale === 'en' ? currentObject?.description_eng : currentObject?.description)
            }}
          </pre>
          <img :src="currentObject.imgUrl" alt="" class="location-img">
          <div class="btns">
            <button @click="teleportToLocation()" class="link link--small "
                    v-if="!currentObject?.cantTeleportation">{{ $t("game.controls.teleportToLocation") }}
            </button>
            <button @click="teleportToLocation(true)" class="link link--small "
                    v-if="currentObject?.entranceTeleportId!=null">{{ $t("game.controls.teleportToEntrance") }}
            </button>
            <button @click="openGuide" class="link link--small ">{{ $t("game.controls.openGuide") }}</button>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import MapElementSmall from "@/components/map/MapElementSmall.vue";
import {computed, ref, watch} from "vue";
import {appConfig} from "@/lib/appConfig";
import {useStore} from "vuex";

export default {
  name: "NewAboutObject",
  emits: [
    'teleport',
    'closeWindow',
    'openMap',
    'openObjectInfo',
    'robotGuideEnable',
  ],
  props: ['currentObject', 'vdnxObjects', 'locale'],
  components: {MapElementSmall},
  setup(props, {emit}) {

    const {commit} = useStore();

    const hiddenObjectsName = ["Фонтан «Золотой колос»", "Северный вход", "Южный вход", "Останкинские ворота", "Павильон №16", "Павильон №28. Эколого-просветительский центр «Пчеловодство»", "Павильон №35. «Главтабак»", "Павильон №37. «Птицеводство»", "Павильон №40. «Школа пекарей»", "Павильон №47. Дом ремесел", "Павильон №48. «Главпиво»", "Павильон №50. «Молочная промышленность»", "Дом культуры", "Аттракцион «Круговая кинопанорама»", "Павильон «Книги» РОСИЗО", "Шахматный клуб", "Павильон «Рабочий и колхозница»", "Центр креативного арт-дизайна La Atmosfera", "«Техноград». Инновационно-образовательный комплекс", "Строение 457", "Павильон №44", "Павильон № 42. Центр национальных конных традиций", "Павильон №43. Демонстрационная конюшня Центра национальных конных традиций", "Павильон №49. Резиденция «Каскад + digital» при поддержке Музея Москвы", "Спортивный магазин Fitness Place", "Павильон №33", "Строение 313", "Строение 84а", "Павильон №46", "Музей ВДНХ", "Павильон №36. Музей кино", "Городская ферма", "Макет Москвы", "Sky Town. Высотный веревочный парк", "Арт-резиденция музея «Гараж»", "Павильон №39. Кафе «Фонтан VIEW»", "Зеленый лабиринт", "Restomarket"];


    const searchObjName = ref(null);


    const objectsList = computed(() =>
        props.vdnxObjects?.filter(item => !hiddenObjectsName.includes(item.name))?.map((obj) => obj).filter((obj) =>
            searchObjName.value
                ? obj.name
                .toLowerCase()
                .indexOf(searchObjName.value.toLowerCase()) !== -1
                : true
        )
    );


    const teleportToLocation = (isEntrance = false) => {
      if (!props.currentObject) return;

      const teleportId = isEntrance ?  props.currentObject?.entranceTeleportId : props.currentObject?.id;

      // console.log(`TELEPORT NewAboutObject.vue:
      //   isEntrance: ${isEntrance}
      // `, props)

      commit('stIsVdnh', teleportId);
      emit('teleport', teleportId);
    }


    const closeWindow = () => {
      emit('closeWindow');
    }
    const openMap = () => {
      emit('openMap');
      emit('closeWindow');
    }

    const openObjectInfo = (val) => {
      emit('openObjectInfo', val)
    }

    watch(() => props.currentObject, () => {
      document.getElementById('objectInfo').scrollTo({top: 0});
    }, {deep: true});

    return {
      closeWindow,
      openMap,
      objectsList,
      searchObjName,
      openObjectInfo,
      teleportToLocation,
      appConfig,
      openGuide() {
        document.dispatchEvent(new CustomEvent("CreateRoboGuide", {detail: props.currentObject?.id}));
        emit('robotGuideEnable');
      }
    }
  }
}
</script>

<style scoped>
.modal {
  padding: 0 1rem;
  height: calc(100% - 12rem);
  position: absolute;
  z-index: 3;
  max-width: 100%;
  width: 100%;
  display: flex;

}

.modal-content {
  padding: 0.63rem;
  max-width: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 0.5rem;
  background: rgba(157, 196, 248, 0.30);
  backdrop-filter: blur(4px);
  pointer-events: all;
}

.modal-filling {
  position: relative;
  display: flex;
  gap: 2.5rem;
  height: 100%;
  max-width: 100%;
  width: 100%;
  border-radius: 0.5rem;
  background: #FFF;
  padding: 1.5rem;

}

.map-info {
  max-width: 23.875rem;
  width: 100%;
  padding: 1.5rem 1rem;
  border-radius: 1rem;
  background: #ECF2FA;
  display: flex;
  flex-direction: column;
}

.map-info-title {
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.00875rem;
}

.search {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.69rem 1rem;
  border-radius: 0.5rem;
  background: #FFF;
}

.search-icon {
  display: flex;
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
}

.location-img {
  max-width: 35rem;
  width: 100%;
  border-radius: 0.5rem;
  margin-bottom: 1rem;

}

.search-input {
  margin-top: 0;
  padding: 0;
  background: none;
  border-radius: 0;
  border: none;
  color: #ABB4C0;
  font-size: 1rem;
}


.map-list {
  margin-top: 1.5rem;
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 100%;
  overflow: auto;
}


.description {
  max-width: 100%;
  width: 100%;
  display: flex;
  overflow: auto;
  flex-direction: column;
}

.description-caption {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
}


.subtitle {
  color: #ABB4C0;

  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.02625rem;
}

.title {
  color: #000;
  font-size: 1.5rem;
  font-weight: 700;
}

.about {
  margin-top: 0.5rem;
  color: #ABB4C0;
  font-size: 0.875rem;
}

.text {
  margin-top: 0.5rem;
  color: rgba(0, 0, 0, 0.60);
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: -0.01rem;
}

.link-teleport {
  max-width: 20rem;
  margin-top: 1rem;
  width: max-content;
}

.close {
  margin-left: auto;
  width: 2rem;
  height: 2rem;
}

.close-white {
  width: 100%;
  height: 100%;
}

.btns {
  display: flex;
  gap: 1rem;
}

@media (max-width: 1400px) {
  .link-teleport {
    max-width: 16rem;
    margin-top: 0.5rem;
    font-size: 1rem;
    padding: 0.7rem 0;
  }

}

@media (max-width: 1000px) {
  .desktop {
    display: none;
  }

  .modal {
    padding: 0 3rem;
    height: calc(100% - 8rem);
  }

  .modal-content {
    padding: 0.37rem;
    border-radius: 0.5rem;
  }

  .modal-filling {
    padding: 0.5rem;
    border-radius: 0.5rem;
    gap: 0.81rem;
  }

  .map-info {
    padding: 0.5rem;
    border-radius: 0.5rem;
  }

  .map-info-title {
    font-size: 0.75rem;
  }

  .map-info {
    max-width: 14rem;
  }


  .subtitle {
    font-size: 0.625rem;
  }

  .title {
    margin-top: 0.25rem;
    font-size: 1rem;
  }

  .close {
    width: 1.5rem;
    height: 1.5rem;
  }

  .about {
    margin-top: 0.5rem;
    font-size: 0.625rem;
  }

  .text {
    margin-top: 0.5rem;
    font-size: 0.75rem;
    line-height: 1;
  }

  .link-teleport {
    max-width: 13rem;
    margin-top: 0.5rem;
    font-size: 0.75rem;
    padding: 0.5rem 0;
  }

}
</style>