// Vuex and Router imports
import router from './router'
import store from './store'

// Toast lib imports
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";


// Vue core objects imports
import {createApp} from 'vue';
import App from './App.vue';


import {createI18n} from "vue-i18n";
import engLocale from '@/lib/locales/engLocale.json';
import ruLocale from '@/lib/locales/ruLocale.json';


// creating app instance
const app = createApp(App);


export const i18n = createI18n({

    locale: 'ru', // set locale
    defaultLang: 'en', // set fallback locale
    messages: {
        en: engLocale,
        ru: ruLocale
    }
});


// connecting Vuex storage and Vue-Router
app.use(store);
app.use(router);
app.use(i18n);


// import VueYandexMetrika from 'vue-v3-yandex-metrika'
// app.use(VueYandexMetrika,{
//     id: '94224372',
//     router,
//     env: process.env.NODE_ENV
// });


// connecting Toast lib
app.use(Toast, {
    transition: "Vue-Toastification__bounce",
    maxToasts: 20,
    timeout: 5000,
    newestOnTop: true
});

// build component and mount
app.component("app", App);
app.mount('#app');