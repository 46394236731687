<template>
  <div id="app" class="wrapper">
    <router-view/>
  </div>
</template>
<script>

import dayjs from "dayjs";
import locale from "dayjs/locale/ru";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";


export default {
  name: 'App',
  setup() {
    dayjs.extend(utc);
    dayjs.extend(timezone)
    dayjs.locale(locale);
  }

}
</script>

<style></style>