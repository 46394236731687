

export const vote = {
  state: {
    vote: null,
    openVoteViewing: false,
  },
  getters: {
    ws: (state) => state.ws,
    vote: (state) => state.vote,
    openVoteViewing: (state) => state.openVoteViewing,
    isClientVoted: (state, getters) => state.vote?.votePassings?.find(({ clientId }) => clientId === getters.userData.id)
  },
  mutations: {
    setVote(state, value) {
      if (value?.eventId === sessionStorage.getItem('eventId') && value?.isActive) {
        state.vote = {
          ...value,
          answers: value.answers?.sort((a, b) => a.value.localeCompare(b.value)),
        }
      } else {
        state.vote = null;
      }
      state.openVoteViewing = value?.isActive ?? false;
    },
    toggleVoteViewing(state, value) {
      state.openVoteViewing = value;
    }
  },
  actions: {
    createVote({ getters }, { state, eventId }) {
      getters.webSocket?.send(JSON.stringify({
        action: 'CREATE_VOTE',
        data: state,
        eventId,
      }));
    },
    endVote({ getters }, {eventId, voteId }) {
      getters.webSocket?.send(JSON.stringify({
        action: 'END_VOTE',
        eventId,
        voteId,
      }));
    },
    voted({ getters }, { answerId, eventId, voteId }) {
      getters.webSocket?.send(JSON.stringify({
        answerId,
        action: 'VOTED',
        clientId: getters.userData.id,
        voteId,
        eventId,
      }));
    }
  },
};