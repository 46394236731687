<template>
  <div class="message " :class="{ 'message-my': message?.self }">
    <div class="message-filling">
      <div class="message-caption">
        <p class="message__name">{{ message.name }}</p>
        <pre class="message__text">
        {{ message?.body }}
        </pre>
      </div>
      <p class="message__time">
        <time>{{ messageTime }}</time>
      </p>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { computed } from "vue";

export default {
  name: "SeparateMessage",
  props: ["message", 'getUserAvatar', 'myAvatar'],
  setup(props) {

    const userAvatar = computed(() => {
      return props.getUserAvatar();
    });

    return {
      messageTime: computed(() => {
        if (props.message?.createDate) {
          const createDate = dayjs(props.message.createDate).tz('Europe/Moscow').toDate();
          return (Date.now() - new Date(createDate).valueOf()) > 8.64e+7 ? new Date(createDate).toLocaleDateString('ru-RU', { dateStyle: 'short' }) : new Date(createDate).toLocaleTimeString('ru-RU', { timeStyle: 'short' });
        }
        return '';
      }),
      userAvatar
    }
  }
};
</script>

<style scoped>
.message {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  max-width: 90%;
}

.message-my {
  flex-direction: row-reverse;
  text-align: right;
  margin-left: auto;
}



.message-filling {
  padding: 0.75rem;
  max-width: 90%;
  width: 100%;
  background: #dde9ff;
  display: flex;
  align-items: flex-end;
  gap: 0.5rem;
  border-radius: 1rem 1rem 1rem 0;
}

.message-my .message-filling {
  max-width: 90%;
  max-width: 100%;
  width: 100%;
  background: #fff;
  border-radius: 1rem 1rem 0 1rem;
}

.message-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  color: #ffffff;
}


.message__time {
  font-size: 0.62rem;
  text-align: right;
  color: #86a8da;
}

.message__text {
  color: #5D6C85;
  font-weight: 600;
  font-size: 0.75rem;
  color: #2d4061;
  word-break: break-word;
}

.message__name {
  font-weight: 600;
  font-size: 0.85rem;
  color: #003099;
  word-break: break-word;
}

@media (max-width: 1000px) {

  .message-filling {
    padding: 0.5rem;
  }


  .message__name {
    font-size: 0.75rem;
  }

  .message__time {
    font-size: 0.5rem;
  }

  .message__text {
    margin-top: 0.25rem;
    font-size: 0.625rem;
  }
}
</style>
