import {
    getEventDedicatedTourById,
    getEventRecordedTourById,
    loadFile,
    saveFeedback,
    /* updateDedicatedTour, */
    saveStat,
    getScoreTable,
} from "@/store/application/serverInterationMethods";
import locations from "@/lib/locations.json"
import { appConfig } from "@/lib/appConfig";
// import dayjs from "dayjs";


const getSlotId = () => {
    const currentSlot = sessionStorage.getItem('currentSlot');

    if (currentSlot) {
        return JSON.parse(currentSlot).id ?? ''
    }

    return '';
}

const desktopUniqKeyCreator = (teleportId) => {
    const keys = {
        100: teleportId,
        101: '',
        102: teleportId,
        103: '',
        104: teleportId,
        105: '',
        106: teleportId,
        107: '',
    }


    return keys[teleportId] ?? '';

}

const getMapAndTag = (teleportId) => {
    switch (teleportId) {
        case 100:
            return locations.vdnhToSpace;
        case 101:
            return locations.spaceToVdnh;
        case 102:
            return locations.vdnhToFirstPavilion;
        case 103:
            return locations.firstPavilionToVdnh;
        case 104:
            return locations.vdnhToSibur;
        case 105:
            return locations.siburToVdnh;
        case 106:
            return locations.vdnhToSmartCity;
        case 107:
            return locations.smartCityToVdnh;
        default:
            return locations.spaceToVdnh;
    }
}


const mappings = {
    '00.00-02.05': '/Game/MetaVDNH/Cinematic/01_INT/0010/L_01_INT_0010',
    '02.05-3.42': '/Game/MetaVDNH/Cinematic/01_INT/0030/L_01_INT_0030',
    '03.42-6.37': '/Game/MetaVDNH/Cinematic/03_SPC/0010/L_03_SPC_0010',
    '06.37-8.35': '/Game/MetaVDNH/Cinematic/03_SPC/0020/L_03_SPC_0020',
    '08.35-8.53': '/Game/MetaVDNH/Cinematic/03_SPC/0030/L_03_SPC_0030_Elevator',
    '08.53-11.29': '/Game/MetaVDNH/Cinematic/03_SPC/0030/L_03_SPC_0030',
    '11.29-12.56': '/Game/MetaVDNH/Cinematic/02_ATM/0010/L_02_ATM_0010',
    '12.56-15.12': '/Game/MetaVDNH/Cinematic/02_ATM/0020/L_02_ATM_0020',
    '15.12-17.04': '/Game/MetaVDNH/Cinematic/02_ATM/0030/L_02_ATM_0030_Exterier',
    '17.04-20.06': '/Game/MetaVDNH/Cinematic/02_ATM/0030/L_02_ATM_0030_Reactor',
    '20.06-22.28': '/Game/MetaVDNH/Cinematic/02_ATM/0030/L_02_ATM_0030_Quantum',
    '22.28-24.00': '/Game/MetaVDNH/Cinematic/02_ATM/0040/L_02_ATM_0040',
    '24.00-24.50': '/Game/MetaVDNH/Cinematic/04_KINO/0010/L_04_KINO_0010',
    '24.50-27.21': '/Game/MetaVDNH/Cinematic/04_KINO/0020/L_04_KINO_0020',
    '27.21-27.44': '/Game/MetaVDNH/Cinematic/04_KINO/0030/L_04_KINO_0030',
    '27.44-28.41': '/Game/MetaVDNH/Cinematic/04_KINO/0030/L_04_KINO_0031',
    '28.41-30.00': '/Game/MetaVDNH/Cinematic/05_FNL/0010/L_05_FNL_0010'
};


const getMapAndTagWithRange = (locationId) => {
    const isAveleks = sessionStorage.getItem('aveleks') === '1';
    const slotStartTime = sessionStorage.getItem('slotStartTime');

    if (!isAveleks) {
        return getMapAndTag(locationId);
    }

    if (!slotStartTime) {
        return locations.aveleks;
    }

    return locations.aveleks;
};

export const application = {
    state: {
        iterationInfo: null,
        isReloadPage: false,
        isVdnh: true,
        locale: localStorage.getItem('locale'),
        scoreBoardData: null,
        showQuestionBoard: false,
    },
    mutations: {
        setIterationInfo(store, data) {
            store.iterationInfo = data;
        },
        reloadPage(state) {
            state.isReloadPage = true;
        },
        stIsVdnh(state, teleportId) {
            switch (teleportId) {
                case 100:
                    state.isVdnh = false;
                    break;
                case 102:
                    state.isVdnh = false;
                    break;
                default:
                    state.isVdnh = true;
                    break;
            }
        },
        setLocale(state, data) {
            state.locale = data;
            localStorage.setItem("locale", state.locale);
        },
        setScoreBoardState(state, data) {
            state.scoreBoardData = data;
        },
        setShowQuestionBoard(state, value) {
            state.showQuestionBoard = value;
        }
    },

    actions: {
        async toggleScoreBoard(state, value) {
            const uniqGeneratedKey = sessionStorage.getItem('UniqGeneratedKey');

            if (value && uniqGeneratedKey?.length) {
                const { data } = await getScoreTable(uniqGeneratedKey);

                console.log('SCORE BOARD DATA: ', data);

                if (!data?.length) {
                    return;
                }

                const sortedData = data.sort((a, b) => b.score - a.score);

                console.log('SORTED SCORE BOARD DATA: ', sortedData);

                state.commit('setScoreBoardState', sortedData);
            } else {
                state.commit('setScoreBoardState', null);
            }
        },
        async loadFile(_, { file, callback }) {
            const formData = new FormData();
            formData.append('file', file);
            const { data } = await loadFile(formData);
            if (callback) await callback(data.url);
        },
        async teleport({ /* getters, */ commit }, locationId) {
            commit('stIsVdnh', locationId);

            console.log(`%cteleport locationId: ${locationId}`, 'background-color: blue; color: white; font-weight: bold; font-size: 20px')

            console.log(`%cis dedicatedTour: ${sessionStorage.getItem('eventType') === 'dedicatedTour'}, isDesktopApp: ${localStorage.getItem('isDesktopApp') === 'true'}`, 'background-color: purple; color: white; font-weight: bold; font-size: 20px')

            if (sessionStorage.getItem('eventType') === 'dedicatedTour') {
                if (localStorage.getItem('isDesktopApp') === 'true') {
                    const locationData = getMapAndTag(locationId);


                    const eventId = sessionStorage.getItem('eventId');

                    const key = desktopUniqKeyCreator(locationId);

                    const data = {
                        UniqGeneratedKey: eventId + key,
                        MapPath: locationData.MapPath,
                        TAG: locationData.TAG,
                    }

                    document.dispatchEvent(new CustomEvent('DungeonTeleport', {
                        detail: JSON.stringify(data)
                    }));


                }

                // else {
                //     const locationData = getMapAndTagWithRange(locationId);
                //     const formData = new FormData();
                //     formData.append('mapPath', locationData.MapPath);
                //     formData.append('tag', locationData.TAG);
                //     const { data } = await updateDedicatedTour(
                //         getters.userToken,
                //         sessionStorage.getItem('eventId'),
                //         formData
                //     );
                // }



                // sessionStorage.setItem('internalId', data.internalId);
                // document.dispatchEvent(new CustomEvent('DungeonTeleport', {
                //     detail: JSON.stringify(data)
                // }));




            } else {
                document.dispatchEvent(new CustomEvent("Teleport", { detail: locationId }));
            }
        },

        enterDedicatedTourSolo(_, locationId) {
            // sessionStorage.setItem('isGuide', 'true');

            console.log(`SOLO $${locationId}`)
            const isAveleks = sessionStorage.getItem('aveleks') === '1';
            const mapTag = isAveleks ? locations.aveleks : locations.spaceToVdnh

            if (mapTag?.lectorId) {
                delete mapTag.lectorId;
            }

            sessionStorage.setItem('UniqGeneratedKey', locationId);

            if (mapTag.MapPath === locations.aveleks.MapPath && appConfig.isDesktopApp) {
                mapTag.MapPath = locations.aveleksLocal.MapPath
            }

            document.dispatchEvent(new CustomEvent("EnterDedicatedTour", {
                detail: JSON.stringify({ UniqGeneratedKey: locationId, ...mapTag })
            }));

            if (isAveleks) {
                sessionStorage.removeItem('aveleks')
            }
        },

        async saveFeedback({ getters }, data) {
            return await saveFeedback(getters.userToken, data);
        },

        async enterDedicatedTour({ getters, commit }, locationId) {


            commit('stIsVdnh', locationId);
            if (sessionStorage.getItem('eventType') === 'dedicatedTour') {
                const { data } = await getEventDedicatedTourById(
                    getters.userToken,
                    sessionStorage.getItem('eventId')
                );

                const { lectorId, ...UEData } = data;

                const isGuide = sessionStorage.getItem("userId") === lectorId;
                sessionStorage.setItem('isGuide', isGuide);

                let eventData = UEData;
                // if (isGuide) {
                //     const locationData = getMapAndTagWithRange(locationId);
                //     const formData = new FormData();
                //     formData.append('mapPath', locationData.MapPath);
                //     formData.append('tag', locationData.TAG);
                //     const {data} = await updateDedicatedTour(
                //         getters.userToken,
                //         sessionStorage.getItem('eventId'),
                //         formData
                //     )
                //     eventData = data;
                // }

                console.log('eventData: ', eventData)

                const UniqGeneratedKey = data.UniqGeneratedKey + getSlotId();

                console.log(`%c${UniqGeneratedKey}`, 'background-color: blue; color: white; font-weight: bold; font-size: 20px');

                sessionStorage.setItem('UniqGeneratedKey', UniqGeneratedKey);

                const eventDataWithSlotId = {
                    ...eventData,
                    UniqGeneratedKey,
                };

                if (eventDataWithSlotId?.lectorId) {
                    delete eventDataWithSlotId.lectorId;
                }

                if (eventDataWithSlotId.MapPath === locations.aveleks.MapPath && appConfig.isDesktopApp) {
                    eventDataWithSlotId.MapPath = locations.aveleksLocal.MapPath;
                }

                document.dispatchEvent(new CustomEvent("EnterDedicatedTour", {
                    detail: JSON.stringify(eventDataWithSlotId)
                }))
            }
        },

        async enterRecordedTour({ getters }) {
            if (sessionStorage.getItem('eventType') === 'recordedTour') {
                const { data } = await getEventRecordedTourById(
                    getters.userToken,
                    sessionStorage.getItem('eventId')
                );

                if (data?.MapPath === locations.aveleks.MapPath) {
                    document.dispatchEvent(new Event('isAveleks'));
                }


                if (data.MapPath === Object.values(mappings)[0]) {
                    sessionStorage.setItem('aveleks', '1');
                    data.MapPath = getMapAndTagWithRange(null)?.MapPath ?? data.MapPath
                }

                const UniqGeneratedKey = data.UniqGeneratedKey + getSlotId();

                console.log(`%c${UniqGeneratedKey}`, 'background-color: green; color: white; font-weight: bold; font-size: 20px');

                sessionStorage.setItem('UniqGeneratedKey', UniqGeneratedKey);

                const eventDataWithSlotId = {
                    ...data,
                    UniqGeneratedKey,
                };

                if (eventDataWithSlotId?.lectorId) {
                    delete eventDataWithSlotId.lectorId;
                }

                if (eventDataWithSlotId.MapPath === locations.aveleks.MapPath && appConfig.isDesktopApp) {
                    eventDataWithSlotId.MapPath = locations.aveleksLocal.MapPath
                }

                document.dispatchEvent(new CustomEvent("EnterDedicatedTour", {
                    detail: JSON.stringify(eventDataWithSlotId)
                }))
            }
        },

        async saveStat({ getters }, body) {
           try {
            const { data } = await saveStat(getters.userToken, body);

            console.log('[STAT DATA]: ', data)
           } catch (error) {
               console.log('[STAT ERROR]: ', error)
           }
        }
    },

    getters: {
        isVdnh: (state) => state.isVdnh,
        iterationInfo: (state) => state.iterationInfo,
        isReloadPage: state => state.isReloadPage,
        locale: state => state.locale,
        showScoreBoard: (state) => !!state.scoreBoardData,
        scoreBoardData: (state) => state.scoreBoardData,
        showQuestionBoard: (state) => state.showQuestionBoard,
    }
};