<template>
  <!-- BUTTON FOR TEST -->
  <!-- <button
    style="position: fixed; z-index: 100; top: 10px; right: 1000px"
    @click="onTest"
  >
    test
  </button> -->
  <LoaderElement v-if="viewLoaderWindow"></LoaderElement>
  <loading id="loader" class="loader" :active="loader" :can-cancel="false" :is-full-page="true">
    <template #default>
      <LoaderElement/>
    </template>
    <template #after>
      <p style="margin-top: 20px; font-size: 1.8em; color: #e51740">
        {{ $t("game.general.loader") }}
      </p>
    </template>
  </loading>
  <div id="game-main" class="game" v-if="user">
    <DisconnectGuideModal/>
    <!-- <LecureControls :closed-page="closedPage" v-if="
      (user.role === 'guide' || user.role === 'lecture') &&
      gameLoaded &&
      !viewAvatarConstruct &&
      !isSoloLobby
    ">
    </LecureControls> -->
    <PresentationWindow v-if="
      currentViewSlide && user.role !== 'guide' && user.role !== 'lecture'
    " :slide="currentViewSlide"></PresentationWindow>
    <!-- :training-step="trainingStep" @closeTraining="trainingStep = null" @nextStep="trainingStep++" @prevStep="trainingStep--"-->
    <RedesignAboutModal
        v-if="isEModal"
        @showAbout="onShowAbout"
        @hide="onHideEModal"
    />
    <!-- TODO Включай если экскурсия с Ваней Дмитриенко -->
    <GameVanyaHeader
        v-if="isAveleks || isVanya"
        :view-avatar-construct="viewAvatarConstruct"
        :can-use-buttons="canUseButtons" :view-chat="viewChat" :role="user?.role" @openChat="viewChat = !viewChat"
        :turn-off-microphone="turnOffMicrophone"
        :user-call="userCall"
        @robotGuideDisable="isRobotGuide = false" @openShareForm="viewShareForm = true" @openMap="viewMap = !viewMap"
        @changeAvatarConstructState="changeAvatarConstructState" :event-type="eventType" :game-loaded="gameLoaded"
        @changeTurnOffMicrophone="turnOffMicrophone = !turnOffMicrophone" :my-media-stream="myMediaStream"
    />
    <GameHeader
        v-else
        :event-type="eventType"
        :is-robot-guide="isRobotGuide"
        :game-loaded="gameLoaded"
        :can-use-buttons="canUseButtons"
        :view-chat="viewChat"
        :role="user?.role"
        :my-media-stream="myMediaStream"
        :turn-off-microphone="turnOffMicrophone"
        :user-call="userCall"
        @openChat="viewChat = !viewChat"
        @robotGuideDisable="isRobotGuide = false"
        @openShareForm="viewShareForm = true"
        @openMap="viewMap = !viewMap"
        @changeTurnOffMicrophone="turnOffMicrophone = !turnOffMicrophone"
    />
    <GameMain/>
    <ShareModal @closeShareForm="viewShareForm = false" v-if="viewShareForm && !viewAvatarConstruct">
    </ShareModal>
    <NewCosmosObject v-if="exhibit && !viewAvatarConstruct" :exhibit="exhibit" @closeWindow="exhibit = null"
                     :locale="locale">
    </NewCosmosObject>
    <ActionButtonNotification v-if="actionButton" :action-button="actionButton" @close="actionButton = null">
    </ActionButtonNotification>
    <TeleportAcceptNotification v-if="teleportId != null && canUseTeleport" :teleport-id="teleportId"
                                @closeTeleport="teleportId = null"/>
    <ChatInvitation :receiver-name="receiverName" :receiver-id="receiverId" :reason="callReason" v-if="callReason"
                    @closeInviteCall="callReason = null">
    </ChatInvitation>
    <MessagesChatInvitation :reason="chatReason" :receiver-name="receiverName" :receiver-id="receiverId"
                            :my-name="user?.name" v-if="chatInvite" @closeInviteChat="closeInviteChat"
                            @cancelInviteChat="cancelInviteChat">
    </MessagesChatInvitation>

    <!--    <GameFooter v-if="gameLoaded || trainingStep != null"-->
    <!-- :training-step="trainingStep" -->
    <GameFooter v-if="gameLoaded && !(isAveleks || isVanya)"
                @changeTurnOffMicrophone="turnOffMicrophone = !turnOffMicrophone"
                :my-media-stream="myMediaStream" @changeAvatarConstructState="changeAvatarConstructState"
                :view-avatar-construct="viewAvatarConstruct" :view-map="viewMap" @openMap="viewMap = !viewMap"
                :event-id="eventId"
                :event-type="eventType" :can-use-buttons="canUseButtons" :turn-off-microphone="turnOffMicrophone"
                ref="bottomMenu"
                :user-call="userCall" :role="user?.role" :game-loaded="gameLoaded" :is-vdnh="isVdnh"></GameFooter>
    <DialogueWindow v-if="expectedCall" @cancel="dropCall"></DialogueWindow>
    <RedesignTrainingComponent v-if="trainingStep != null" :training-step="trainingStep"
                               @changeAvatarConstructState="changeAvatarConstructState"
                               @closeTraining="trainingStep = null">
    </RedesignTrainingComponent>
    <!-- <NewTrainingComponent v-if="trainingStep != null" :training-step="trainingStep" @closeTraining="trainingStep = null"
      :is-aveleks="isAveleks" @nextStep="trainingStep++" @endEducation="endEducation" @prevStep="trainingStep--">
    </NewTrainingComponent> -->
    <MapComponent @openObjectInfo="openObjectInfo" @closeMap="viewMap = !viewMap" v-if="viewMap && !viewAvatarConstruct"
                  @robotGuideEnable="isRobotGuide = true"></MapComponent>
    <NewAboutCompany @openObjectInfo="openObjectInfo" @closeAbout="viewAbout = !viewAbout" v-if="false">
    </NewAboutCompany>
    <!-- :training-step="trainingStep" v-if="(gameLoaded || trainingStep)  -->
    <ChatWrapper v-if="gameLoaded && !viewAvatarConstruct && !isSoloLobby" :chat-invite="chatInvite" :event-id="eventId"
                 :role="user?.role" :my-avatar="myAvatar" :view-chat="viewChat" :near-players="nearPlayers"
                 :user-id="user?.id"
                 :receiver-id="receiverId" :user-call="userCall" @closeInviteChat="closeInviteChat"
                 @openChat="viewChat = !viewChat" @callUp="callReason = 'callUp'"></ChatWrapper>
    <HelpComponent v-if="
      isVdnh &&
      (isSoloLobby || (user.role !== 'guide' && user.role !== 'lecture')) &&
      (trainingStep != null || (canUseButtons && gameLoaded)) &&
      (isSoloLobby || eventType !== 'dedicatedTour') &&
      !viewAvatarConstruct" @openMap="viewMap = !viewMap" ref="helpComponent" :locale="locale"
                   @robotGuideEnable="isRobotGuide = true" @startTraining="trainingStep = 0"
                   :training-step="trainingStep"
                   @closeTraining="trainingStep = null">
    </HelpComponent>
    <MobileAlert></MobileAlert>
    <!-- <ChatTraining :training-step="trainingStep"></ChatTraining> -->
    <button class="link-default">Закрыть гид</button>
    <!-- <NewAvatarModal v-if="viewCreateUserAvatarModal && gameLoaded" @close="closeCreateAvatarModal"
      @changeAvatarConstructState="changeAvatarConstructState"></NewAvatarModal> -->
    <AvatarControls v-if="viewAvatarConstruct" @close="changeAvatarConstructState"></AvatarControls>
    <!-- TODO Модалка счёта -->
    <TotalScore />
    <QuestionBoard />
    <RedesignAvailablePlaces v-if="viewAvailablePlaces" @close="viewAvailablePlaces= false"/>
  </div>
  <div id="audioChatContainer" hidden="hidden"></div>
</template>

<script>
import LoaderElement from "@/components/general/LoaderElement.vue";
import GameHeader from "@/components/game/GameHeader.vue";
import GameVanyaHeader from "@/components/game/GameVanyaHeader.vue";
import RedesignAboutModal from "@/components/game/RedesignAboutModal.vue";
import GameFooter from "@/components/game/GameFooter.vue";
import MobileAlert from "@/components/game/MobileAlert.vue";
import ShareModal from "@/components/game/ShareModal.vue";
import HelpComponent from "@/components/game/HelpComponent.vue";
import MapComponent from "@/components/game/MapComponent.vue";
import NewAboutCompany from "@/components/game/NewAboutCompany.vue";
import ChatWrapper from "@/components/game/ChatWrapper.vue";
// import LecureControls from "@/components/game/LecureControls.vue";
import ChatInvitation from "@/components/game/ChatInvitation.vue";
import NewCosmosObject from "@/components/game/NewCosmosObject.vue";
import PresentationWindow from "@/components/game/PresentationWindow.vue";
import TeleportAcceptNotification from "@/components/game/TeleportAcceptNotification.vue";
// import NewAvatarModal from "@/components/game/NewAvatarModal.vue";
// import NewTrainingComponent from "@/components/game/NewTrainingComponent.vue";
import RedesignTrainingComponent from "@/components/game/RedesignTrainingComponent.vue";
import GameMain from "@/components/game/GameMain";
import TotalScore from "@/components/game/TotalScore.vue";
import QuestionBoard from "@/components/game/QuestionBoard.vue";
import {
  computed,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
  shallowRef,
} from "vue";
import {useStore} from "vuex";
import {onBeforeRouteLeave, useRouter} from "vue-router";
import Loading from "vue-loading-overlay";
import DialogueWindow from "@/components/game/DialogueWindow";
import ActionButtonNotification from "@/components/game/ActionButtonNotification";
import MessagesChatInvitation from "@/components/game/MessagesChatInvitation.vue";
import DisconnectGuideModal from "@/components/game/DisconnectGuideModal.vue";
import AvatarControls from "@/components/game/AvatarControls.vue";
import spaceObjects from "@/lib/spaceData.json";
import vdnxObjects from "@/lib/data.json";
import {parseEventPlacement} from "@/lib/utils";
// import ChatTraining from "@/components/game/ChatTraining.vue";
import {appConfig} from "@/lib/appConfig";
import dayjs from "dayjs";
import {defaultModel} from "@/lib/userModelSkins";
import RedesignAvailablePlaces from "@/components/game/RedesignAvailablePlaces.vue";

export default {
  name: "GamePage",
  components: {
    // ChatTraining,
    NewCosmosObject,
    LoaderElement,
    DialogueWindow,
    GameHeader,
    GameVanyaHeader,
    GameFooter,
    ShareModal,
    // NewTrainingComponent,
    RedesignTrainingComponent,
    GameMain,
    Loading,
    // LecureControls,
    ChatWrapper,
    PresentationWindow,
    MapComponent,
    HelpComponent,
    TeleportAcceptNotification,
    ActionButtonNotification,
    ChatInvitation,
    MobileAlert,
    MessagesChatInvitation,
    NewAboutCompany,
    DisconnectGuideModal,
    // NewAvatarModal,
    AvatarControls,
    TotalScore,
    QuestionBoard,
    RedesignAboutModal,
    RedesignAvailablePlaces
  },
  setup() {
    //----------------VARIABLES--------------------

    // import locations from "@/lib/locations.json";    vanya:
    //   locations.aveleks.MapPath?.trim().toLowerCase() ===
    //   currentEvent.value?.mapPath?.trim().toLowerCase(),

    const isEModal = shallowRef(null);

    const store = useStore();

    const isVanya = shallowRef(false);

    const router = useRouter();

    const closedPage = ref(false);

    const currentEvent = computed(() => store.getters.currentEvent);

    // const fromUserId = ref(123);
    const viewChat = ref(false);

    const viewMap = ref(false);

    const viewAbout = ref(true);

    const teleportId = ref(null);

    const actionButton = ref(null);

    const user = computed(() => store.getters.userData);

    const pingAliveTimer = ref(null);

    const infoLoaded = ref(false);

    const bottomMenu = ref(null);

    const turnOffMicrophone = ref(true);

    const myMediaStream = computed(() => store.getters.myMediaStream);

    const trainingStep = shallowRef(null);

    const viewAvailablePlaces = shallowRef(false);

    const sideData = ref(JSON.parse(user.value.sideData));

    const receiverName = computed(() => store.getters.receiverName);

    const vdnxTimerId = ref(null);

    const chatInvite = computed(() => store.getters.chatInvite);

    const viewLoaderWindow = ref(false);

    const exhibit = ref(null);

    const helpComponent = ref(null);

    const gameLoaded = ref(false);

    const isRobotGuide = ref(false);

    const eventType = ref(sessionStorage.getItem("eventType"));

    const eventId = ref(sessionStorage.getItem("eventId"));

    const eventPlacement = ref(
        sessionStorage.getItem("eventPlacement")
            ? JSON.parse(sessionStorage.getItem("eventPlacement"))
            : null,
    );

    const loadSections = ref(false);

    const locale = computed(() => store.getters.locale);


    // const viewCreateUserAvatarModal = ref(false);

    const viewAvatarConstruct = ref(false);

    const canUseButtons = computed(() => {
      if (
          eventId.value &&
          !["dedicatedTour", "exhibition", 'vdnh_excursion'].includes(eventType.value)
      ) {
        return user?.value?.role === "guide" || user?.value?.role === "lecture";
      }
      return true;
    });

    const canUseTeleport = computed(() => {
      if (
          eventType.value === "dedicatedTour" &&
          sessionStorage.getItem("isGuide") !== "true"
      ) {
        return false;
      }
      return canUseButtons.value;
    });

    const myAvatar = computed(() => {
      return user.value.avatarUrl ?? require("@/assets/face-poster.svg");
    });

    const nearPlayers = ref([]);

    const callReason = ref(null);
    const chatReason = computed(() => store.getters.chatReason);

    const userCall = computed(() => store.getters.userCall);

    const isVdnh = computed(() => store.getters.isVdnh);

    const isAveleks = shallowRef(false);

    const currentSlot = ref(
        JSON.parse(sessionStorage.getItem("currentSlot" ?? null)),
    );

    const isSoloLobby = ref(sessionStorage.getItem("soloLobby") === "true");
    const isPersonalLobby = ref(
        sessionStorage.getItem("personalLobby") === "true",
    );


    function onHideEModal() {
      isEModal.value = null;
    }


    function onShowAbout() {
      exhibit.value = isEModal.value.value;

      if (isEModal.value.event === 'KosmosExhibit') {
        openObjectInfo({val: exhibit.value});
      }

      onHideEModal();
    }


    function onTest() {
      // isEModal.value = {
      //   value: vdnxObjects[0],
      //   event: "VDHNExhibit",
      // };
      document.dispatchEvent(new CustomEvent('ShowScoreboard', {detail: !!Number("1")}))
    }


    watch(
        () => trainingStep.value,
        (val) => {
          // if (val === 7) {
          //   viewChat.value = true;
          // }
          // if (val === 8) {
          //   store.commit("setChatParams", {
          //     receiverId: 'educationTestUser',
          //     chatType: "user",
          //     receiverName: 'Алексей Петров'
          //   });
          // }
          // if (viewCreateUserAvatarModal.value) {
          //   viewCreateUserAvatarModal.value = false;
          // }
          if (val === null) {
            sessionStorage.removeItem("education");
            document.dispatchEvent(new Event('OpenAvailablePlaces'));
            // viewCreateUserAvatarModal.value = true;
            // store.commit("clearChat");
          }
        },
    );

    watch(
        () => viewMap.value,
        (val) => {
          if (!val) document.dispatchEvent(new Event("CloseGameModal"));
        },
    );

    //----------------METHODS--------------------

    const eventInitialization = () => {
      store.commit("setClearInstanceStatus", false);

      // document.addEventListener("connectedWS", connectedWS, {once: true});

      document.addEventListener("ShowLoadingScreen", (event) => {
        // if (!canUseButtons.value) return;
        viewLoaderWindow.value = event.detail;

        if (!viewLoaderWindow.value) {
          if (sessionStorage.getItem("teleportId")) {
            document.dispatchEvent(
                new CustomEvent("Teleport", {
                  detail: sessionStorage.getItem("teleportId"),
                }),
            );
            sessionStorage.removeItem("teleportId");
          }
        }
      });

      document.addEventListener("InviteChat", (event) => {
        store.commit("setChatInvite", true);
        if (event?.detail?.type === "request") {
          store.commit("setChatReason", "answer");
          store.commit("setChatParams", {
            receiverId: event?.detail?.receiverId,
            chatType: "user",
            receiverName: event?.detail?.receiverName,
          });
        }
        if (event?.detail?.type === "cancel") {
          store.commit("setChatReason", "cancel");
        }
        if (event?.detail?.type === "answer") {
          if (event?.detail?.isAccepted) {
            store.commit("setChatParams", {
              receiverId: event?.detail?.receiverId,
              chatType: "user",
              receiverName: event?.detail?.receiverName,
            });
            store.commit("setChatInvite", false);
            store.commit("setChatReason", null);
          } else {
            store.commit("setChatReason", "cancel");
          }
        }
      });

      document.addEventListener("InitCompleteApp", () => {
        if (infoLoaded.value) return;

        document.dispatchEvent(
            new CustomEvent("PlayerId", {
              detail: user.value?.id,
            }),
        );

        document.addEventListener("GetUserID", () => {
          const sex = sideData.value?.userModel?.Sex ?? null;
          const headSkin = sideData.value?.userModel?.SkinHead ?? null;

          sideData.value?.userModel?.SkinHead
          document.dispatchEvent(
              new CustomEvent("UserID", {
                detail: JSON.stringify({
                  id: user.value.id,
                  name: user.value.name,
                  sex,
                  headSkin,
                }),
              }),
          );
        });

        document.dispatchEvent(
            new CustomEvent("SelfInstanceAddress", {
              detail: localStorage.getItem("game_socket"),
            }),
        );

        pingAliveTimer.value = setInterval(() => {
          document.dispatchEvent(new Event("Heartbeat"));
        }, 30000);

        if (eventId.value && eventType.value === "lecture") {
          vdnxTimerId.value = setInterval(() => {
            document.dispatchEvent(
                new CustomEvent("EnterLection", {
                  detail: user?.value?.role === "lecture" ? "lector" : "user",
                }),
            );

            if (user?.value?.role !== "lecture" && loadSections.value) {
              document.addEventListener("InitCompleteApp", () => {
                document.dispatchEvent(
                    new CustomEvent("SECTION", {
                      detail: parseEventPlacement(eventPlacement.value),
                    }),
                );
              });
            }
          }, 5000);
        } else if (eventType.value === "exhibition") {
          vdnxTimerId.value = setInterval(() => {
            document.dispatchEvent(new Event("EnterExpo"));
          }, 5000);
        } else if (eventType.value === "dedicatedTour") {
          vdnxTimerId.value = setInterval(() => {
            if (isSoloLobby.value) {
              store.dispatch("enterDedicatedTourSolo", String(eventId.value));
            } else {
              store.dispatch("enterDedicatedTour", 1);
            }
          }, 5000);
        } else if (eventType.value === "recordedTour") {
          vdnxTimerId.value = setInterval(() => {
            store.dispatch("enterRecordedTour");
          }, 5000);
        } else {
          vdnxTimerId.value = setInterval(() => {
            document.dispatchEvent(new Event("VDNH"));
          }, 5000);
        }

        if (
            user.value?.role === "lecture" &&
            sessionStorage.getItem("eventId")
        ) {
          document.dispatchEvent(new Event("IsLecture"));
        }

        infoLoaded.value = true;
      });

      document.addEventListener("NearPlayers", (event) => {
        try {
          nearPlayers.value = JSON.parse(event.detail);
        } catch (e) {
          console.log(e);
        }
      });

      document.addEventListener("ConnectingToDSSSuccess", () => {
        clearInterval(vdnxTimerId.value);
        gameLoaded.value = true;

        if (
            eventId.value &&
            (user.value?.role === "guide" || user.value?.role === "lecture")
        ) {
          store.dispatch("joinEvent", {eventId: eventId.value});
        }
        trainingStep.value = 0;

        // if (sessionStorage.getItem("education")) {
        //   // sessionStorage.removeItem('education');
        //   trainingStep.value = 0;
        // }
      });

      document.addEventListener("GetCharacter", () => {
        // if (!canUseButtons.value) return;
        console.log("GetCharacter: ", sideData.value?.userModel)
        document.dispatchEvent(
            new CustomEvent("ConstructCharacter", {
              detail: JSON.stringify({
                SkinHead:
                    sideData.value?.userModel?.SkinHead ?? defaultModel.SkinHead,
                SkinTorso:
                    sideData.value?.userModel?.SkinTorso ?? defaultModel.SkinTorso,
                SkinLegs:
                    sideData.value?.userModel?.SkinLegs ?? defaultModel.SkinLegs,
                SkinShoes:
                    sideData.value?.userModel?.SkinShoes ?? defaultModel.SkinShoes,
                Sex: sideData.value?.userModel?.Sex ?? defaultModel.Sex,
                Character: "",
              }),
            }),
        );

        document.dispatchEvent(
            new CustomEvent("GetNickName", {
              detail: user.value?.name,
            }),
        );

        document.dispatchEvent(
            new CustomEvent("IsVIP", {
              detail: user.value?.vip,
            }),
        );
      });

      document.addEventListener("OpenMap", (event) => {
        if (!canUseButtons.value) return;

        viewMap.value = event.detail;
      });

      document.addEventListener("ShowTeleportTo", (e) => {
        if (!canUseButtons.value) return;
        teleportId.value = e.detail;
      });
      document.addEventListener("InteractiveObjectOverlap", (e) => {
        if (!canUseButtons.value) return;

        actionButton.value = e.detail;
      });
      document.addEventListener("ChangeViewportInput", (e) => {
        bottomMenu.value?.changeTurnOffCamera(e?.detail);
      });

      document.addEventListener("CloseModal", () => {
        actionButton.value = null;
        teleportId.value = null;
        exhibit.value = null;
        isEModal.value = null;
      });

      window.addEventListener("closeCommunicationWebSocket", () => {
        closeAll();
      });
      window.addEventListener("deleteInstance", (event) => {
        if (closedPage.value) return;
        store.dispatch("deleteInstance", event.detail);
      });
      window.addEventListener("setCallModalReason", (event) => {
        if (eventId.value) return;
        callReason.value = event?.detail;
      });

      document.addEventListener("KosmosExhibit", (event) => {
        const index = spaceObjects
            ?.map((item) => item?.Id)
            .indexOf(event.detail);
        if (index > -1) {

          if (exhibit.value && exhibit.value?.Id  === spaceObjects[index]?.Id) {
            onHideEModal();
            return
          }

          isEModal.value = {
            value: spaceObjects[index],
            event: "KosmosExhibit",
          };
        }
      });
      document.addEventListener("VDHNExhibit", (event) => {
        const index = vdnxObjects
            ?.map((item) => item?.identificator)
            .indexOf(event.detail);
        if (index > -1) {
          isEModal.value = {
            value: vdnxObjects[index],
            event: "VDHNExhibit",
          };
        }
      });

      document.addEventListener('OpenAvailablePlaces', () => {
        if (isVanya.value || isAveleks.value) {
          return;
        }

        viewAvailablePlaces.value = true;
      }, {once: true})
    };

    document.addEventListener('ExitToLobby', exitLobbyHandler);
    document.addEventListener('ShowScoreboard', showScoreBoardHandler);

    document.addEventListener("eventInitialization", eventInitialization, {
      once: true,
    });
    document.addEventListener(
        "gameLoaded",
        () => {
          gameLoaded.value = true;
        },
        {once: true},
    );
    document.addEventListener(
        "LoadSection",
        () => {
          if (gameLoaded.value && user?.value?.role !== "lecture") {
            document.dispatchEvent(
                new CustomEvent("SECTION", {
                  detail: parseEventPlacement(eventPlacement.value),
                }),
            );
          }
          loadSections.value = true;
        },
        {once: true},
    );
    document.addEventListener(
        "isAveleks",
        () => {
          isAveleks.value = true;
        },
        {once: true},
    );

    const connected = (socket) => {
      if (socket) {
        setTimeout(() => {
          document.dispatchEvent(
              new CustomEvent("launchGame", {detail: false}),
          );

          //TODO send test event join
          // if (eventId.value && (user.value?.role === 'guide' || user.value?.role === 'lecture')) {
          //   store.dispatch('joinEvent', {eventId: eventId.value});
          // }
          // store.dispatch('enterDedicatedTour', 1);
        }, 1000);
        store.commit("setLoader", false);
      } else {
        setTimeout(function () {
          connected(localStorage.getItem("game_socket"));
        }, 1000);
      }
    };

    // function closeCreateAvatarModal() {
    // if (!sideData.value) {
    //   sideData.value = {};1
    // }

    // sideData.value["userModel"] = {...defaultModel};
    // console.log('closeCreateAvatarModal: ',  sideData.value.userModel)
    // const userData = JSON.parse(JSON.stringify(user.value));

    // userData.sideData = JSON.stringify(sideData.value);
    // store.dispatch("saveUserData", userData);
    // store.dispatch("fetchUserData");

    // viewCreateUserAvatarModal.value = false;
    // }

    const closeAll = async (callback) => {
      if (closedPage.value) return;
      closedPage.value = true;

      if (!isPersonalLobby.value && !isSoloLobby.value) {
        if (
            eventId.value &&
            (user.value?.role === "lecture" || user.value?.role === "guide")
        ) {
          await store.dispatch("eventEnd", eventId.value);
        }
      }
      callback?.();
      clearInterval(pingAliveTimer.value);
      clearInterval(vdnxTimerId.value);
      store.commit("closeAudioChat");
      document.dispatchEvent(new Event("closeGameConnection"));
      window.dispatchEvent(new Event("closeLocalWS"));
      store.commit("closeWS");
      store.commit("reloadPage");
      window.removeEventListener("beforeunload", closeForUpdatePage);
      gameLoaded.value = false;
    };

    const closeForUpdatePage = (event) => {
      closeAll();
      event.preventDefault();
      return (event.returnValue = "");
    };

    const cancelInviteChat = () => {
      store.commit("setChatInvite", false);
      store.commit("setChatReason", null);
      store.commit("clearChat");
    };
    const closeInviteChat = () => {
      store.commit("setChatReason", null);
      store.commit("setChatInvite", false);
    };

    const openObjectInfo = ({val, openNavigation}) => {
      viewMap.value = false;
      helpComponent.value?.openObjectInfo(val);
      if (helpComponent.value && openNavigation) {
        helpComponent.value.viewOptions = true;
        helpComponent.value.viewNavigation = true;
      }
    };

    const endEducation = () => {
      const userData = JSON.parse(JSON.stringify(user.value));
      const sideData = JSON.parse(userData?.sideData);
      if (sideData) {
        sideData["educationPassed"] = true;
        userData.sideData = JSON.stringify(sideData);
        store.dispatch("saveUserData", userData);
        store.dispatch("fetchUserData");
      }
      trainingStep.value = null;
    };

    function changeAvatarConstructState() {
      viewAvatarConstruct.value = !viewAvatarConstruct.value;
      document.dispatchEvent(
          new Event(
              viewAvatarConstruct.value
                  ? "VDNHCreateCharacter"
                  : "SpawnCharacterVDNH",
          ),
      );

      if (!viewAvatarConstruct.value) {
        sideData.value = JSON.parse(user.value?.sideData);
        console.log(sideData.value)
        document.dispatchEvent(
            new CustomEvent("ConstructCharacter", {
              detail: JSON.stringify({
                SkinHead:
                    sideData.value?.userModel?.SkinHead ?? defaultModel.SkinHead,
                SkinTorso:
                    sideData.value?.userModel?.SkinTorso ?? defaultModel.SkinTorso,
                SkinLegs:
                    sideData.value?.userModel?.SkinLegs ?? defaultModel.SkinLegs,
                SkinShoes:
                    sideData.value?.userModel?.SkinShoes ?? defaultModel.SkinShoes,
                Sex: sideData.value?.userModel?.Sex ?? defaultModel.Sex,
                Character: "",
              }),
            }),
        );
      }
      // else {
      //   viewCreateUserAvatarModal.value = false;
      // }
    }

    watch(
        () => viewMap.value,
        (val) => {
          if (val && helpComponent.value) {
            helpComponent.value.viewNavigation = false;
          }
        },
    );
    //
    // watch(gameLoaded, (value) => {
    //   if (value) {
    //     if (!sideData.value?.userModel) {
    //       viewCreateUserAvatarModal.value = true;
    //     }
    //   }
    // });

    //----------------HOOKS--------------------


    async function saveStat() {
      if (sessionStorage.getItem('stat') === '1') {
        return
      }

      const formData = new FormData();
      formData.append("eventName", isVanya.value ? 'Ваня' : 'ВДНХ');
      formData.append("type", localStorage.getItem('isDesktopApp') === 'true' ? 'desktop' : 'web');
      formData.append("clientId", store.getters.userData.id)

      await store.dispatch('saveStat', formData);

      sessionStorage.setItem('stat', 1);
    }

    onMounted(async () => {
      if (!eventId.value) {
        return router.push({name: "mainPage"});
      }

      await store.dispatch('fetchCurrentEvent', eventId.value);

      isVanya.value = sessionStorage.getItem('isVanya') === 'true'
      isSoloLobby.value = sessionStorage.getItem("soloLobby") === 'true'



      document.addEventListener('SaveStat', saveStat);

      store.commit("createWS", function () {
        store.dispatch("joinEvent", {eventId: sessionStorage.getItem('eventId')});
      });

      if (
          (isSoloLobby.value || isPersonalLobby.value) &&
          appConfig.isDesktopAppChecked &&
          !appConfig.isDesktopApp
      ) {
        return router.push({name: "mainPage"});
      } else {
        if (isPersonalLobby.value) {
          if (currentEvent.value.leaderId === user?.value?.id) {
            store.commit("changeUserRole", "guide");
          }
        }
      }


      if (localStorage.getItem("notification")) {
        return store.dispatch("disconnectRedirect");
      }

      sessionStorage.setItem("userId", user.value.id);
      if (!eventId.value || (eventId.value && (user.value.role === "guide" || user.value.role === "lecture")) || ["exhibition", "dedicatedTour", "recordedTour", 'vdnh_excursion'].includes(eventType.value,)) {
        connected(localStorage.getItem("game_socket"));
      }


      if (currentSlot.value) {
        let slotTimeCount =
            Math.floor((dayjs(currentSlot.value?.endTime) - dayjs()) / 1000) - 60;
        const timer = setInterval(() => {
          if (slotTimeCount <= 0) {
            clearInterval(timer);
            store.dispatch("disconnect", "slotTimeExpired");
            return;
          }

          slotTimeCount--;
        }, 1000);
      }

      if (document.getElementById("gameMediaRequests")) return;
      const element = document.createElement("link");
      element.rel = "stylesheet";
      element.href = "/style/gameMediaRequests.css";
      element.id = "gameMediaRequests";
      document.body.append(element);
    });
    onBeforeUnmount(() => {
      closeAll();
      document.getElementById("gameMediaRequests")?.remove();
    });
    onBeforeRouteLeave(() => {
      closeAll(() => {
        sessionStorage.removeItem("eventId");
        sessionStorage.removeItem("eventType");
        sessionStorage.removeItem("eventPlacement");
        sessionStorage.removeItem("currentSlot");
        sessionStorage.removeItem("soloLobby");
        sessionStorage.removeItem("personalLobby");
        localStorage.removeItem("game_socket");
      });
    });

    const slotForGuide = ref();

    watch(
        currentEvent,
        (value) => {
          if (value?.eventSlots) {
            const current = dayjs().tz("Europe/Moscow").unix();

            const slot = value.eventSlots.availableSlots?.find(
                ({endTime, startTime}) => {
                  const start = dayjs(startTime).tz("Europe/Moscow").unix();
                  const end = dayjs(endTime).tz("Europe/Moscow").unix();

                  return start <= current && end >= current;
                },
            );

            if (
                !sessionStorage.getItem("currentSlot") &&
                /* sessionStorage.getItem("isGuide") === "true" && */
                slot
            ) {
              sessionStorage.setItem("currentSlot", JSON.stringify(slot));
              slotForGuide.value = slot;
            }
          }
        },
    );

    const time = shallowRef(dayjs().unix());

    const timerSlot = shallowRef();

    if (
        !sessionStorage.getItem("currentSlot") /* &&
        sessionStorage.getItem("isGuide") === "true" */
    ) {
      timerSlot.value = setInterval(() => {
        time.value = new Date(Date.now() + 60 * 1000);
      }, 1000);
    }

    // TODO Temp
    // watch(time, (value) => {
    //   if (slotForGuide.value) {
    //     if (
    //         value >=
    //         new Date(dayjs(slotForGuide.value.endTime).toDate())
    //     )
    //       store.dispatch("disconnect")
    //           .then(() => clearInterval(timerSlot.value))
    //   }
    // });

    onBeforeUnmount(() => {
      document.removeEventListener('ExitToLobby', exitLobbyHandler);
      document.removeEventListener('ShowScoreboard', showScoreBoardHandler);
      document.removeEventListener('SaveStat', saveStat);
      clearInterval(timerSlot.value)
    });

    if (!appConfig.isDesktopApp) {
      window.addEventListener("beforeunload", closeForUpdatePage);
    }

    function exitLobbyHandler() {
      store.dispatch('disconnect')
    }

    function showScoreBoardHandler({detail}) {
      store.dispatch('toggleScoreBoard', detail)
    }

    return {
      viewShareForm: ref(false),
      viewMap,
      viewAbout,
      loader: computed(() => store.getters.loader),
      user,
      viewChat,
      isEModal,
      // fromUserId,
      // acceptOrCancelConnection,
      receiverId: computed(() => store.getters.receiverId),
      currentViewSlide: computed(() => store.getters.currentViewSlide),
      closedPage,
      teleportId,
      actionButton,
      bottomMenu,
      trainingStep,

      userCall,
      nearPlayers,
      callReason,
      isVdnh,
      expectedCall: computed(() => store.getters.expectedCall),
      dropCall: () => store.dispatch("dropCall"),
      turnOffMicrophone,
      myMediaStream,
      myAvatar,
      receiverName,
      chatInvite,
      cancelInviteChat,
      closeInviteChat,
      chatReason,
      viewLoaderWindow,
      exhibit,
      canUseButtons,
      canUseTeleport,
      openObjectInfo,
      helpComponent,
      gameLoaded,
      eventId,
      eventType,
      endEducation,
      isRobotGuide,
      locale,
      // viewCreateUserAvatarModal,
      // closeCreateAvatarModal,
      viewAvatarConstruct,
      changeAvatarConstructState,
      isSoloLobby,
      isAveleks,
      isVanya,
      onHideEModal,
      onShowAbout,
      onTest,
      viewAvailablePlaces
    };
  },
};
</script>

<style scoped>
.game {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  text-align: center;
  position: fixed;
  z-index: 10000;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e6e9ed;
}

.loader-img {
  max-height: 75px;
  max-width: 75px;
  width: 100%;
  height: 100%;
}
</style>
