<template>
  <div class="explanation">
    <img
      src="@/assets/icons/triangle-blue.svg"
      alt=""
      class="explanation__triangle"
    />
    <p class="explanation__text">Нажмите на чат</p>
  </div>
</template>

<script>
export default {
  name: "ExplanationElement",
};
</script>

<style scoped>
.explanation {
  position: absolute;
  top: -76px;
  right: 0;
  background: #ddecff;
  border-radius: 16px;
  backdrop-filter: blur(45px);
  display: inline;
  padding: 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #003099;
  width: 158px;
  text-align: center;
}
.explanation__triangle {
  position: absolute;
  right: 19px;
  bottom: -7px;
}
@media (max-width: 1800px) {
  .explanation {
    top: -51px;
    right: -6px;
    border-radius: 14px;
    padding: 12px;
    font-size: 14px;
    line-height: 20px;
    width: 138px;
  }
  .explanation__triangle {
    position: absolute;
    right: 19px;
    bottom: -7px;
  }
}
</style>
