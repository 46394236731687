export function parseEventPlacement({column, row}) {
    console.table({ column, row });
    switch (row) {
        case 1:
            return column;
        case 2 :
            return 3 + column;
        case  3 :
            return 7 + column;
        case 4 :
            return 13 + column;
        case 5 :
            return 19 + column;
    }
}