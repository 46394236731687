<script setup>
import {shallowRef, computed} from "vue";
import {useStore} from "vuex";
import {declination} from '@/lib/declination'

const secondWords = ['секунд', 'секунда', 'секунды'];

const {getters, dispatch} = useStore();

const disabled = shallowRef(false);

const disconnectReasons = {
  eventEnd: 'Экскурсия завершится через:',
  slotTimeExpired: 'Время брони закончилось. Экскурсия завершится через:',
}

const reason = disconnectReasons[localStorage.getItem('notification')];

const showDisconnectModal = computed(() => getters.showDisconnectModal);
const disconnectTimer = computed(() => {
  const time = getters.disconnectTimer ?? 0;

  return `${time} ${declination(time, secondWords)}.`;
});

const onDisconnect = () => {
  disabled.value = true;
  dispatch("disconnectRedirect").finally(() => {
    disabled.value = false;
  });
};
</script>

<template>
  <Transition name="fade">
    <div class="disconnect-modal" v-if="showDisconnectModal">
      <p>{{ reason ?? disconnectReasons?.eventEnd }}</p>
      <h3>{{ disconnectTimer }}</h3>
      <button
          class="link-default"
          :disabled="disabled"
          @click="onDisconnect"
      >Выйти
      </button>
    </div>
  </Transition>
</template>

<style scoped>
.disconnect-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  z-index: 10000001;
  padding: 2.5rem 2rem 2rem 2rem;
  position: absolute;
  top: 100px;
  right: 50px;
  border-radius: 1.5rem;
  gap: 10px;
  background: #fff;
}
</style>
