<template>
  <loading color="#fff" class="loader" :active="loader" :can-cancel="false" :is-full-page="true"></loading>
  <section class="section purple-bg">
    <div class="block filling container">
      <NewCaptionComponent>
      </NewCaptionComponent>
      <p class="subtitle text text-small">
        {{ $t("lectureCreation.info") }}</p>
      <div class="content" v-if="presentation">
        <div class="lectures-caption">
          <label for="" class="name" :class="{ 'training-backlight training-backlight--active': educationStep === 1 }">
            <input :value="presentation.name" @change="commit('setPresentationName', $event.target.value)" type="text"
              class="name-input" placeholder="Название презентации" />
            <img src="@/assets/icons/change-name.svg" alt="" class="caption-element__icon" />
          </label>
          <div class="pdf-wrapper" v-if="false">
            <button class="pdf link-default" @click="$refs.pdf.click()">
              <input accept="application/pdf" @change="loadPDF" type="file" hidden ref="pdf" />
              {{ $t("lectureCreation.pdf") }}
            </button>
            <p class="text text-small pdf-text">
              {{ $t("lectureCreation.warning") }}</p>
          </div>
        </div>
        <p class="list-title text text-small">
          {{ $t("lectureCreation.list") }}</p>
        <div class="creation">
          <div class="slides">

            <NewSeparateLectionPreview @pickSlide="pickSlide" :slide="item" :current-slide="currentSlide"
              v-for="item in presentation?.slides?.sort((a, b) => a?.order - b?.order)" :key="item.order"
              :slides-length="presentation?.slides.length"></NewSeparateLectionPreview>

            <NewSeparateLectionPreview></NewSeparateLectionPreview>
          </div>
          <div class="slide-wrapper">
            <div class="slide-filling">
              <video class="slide__img" controls v-if="presentation.slides[currentSlide - 1]?.videoLink"
                :src="presentation.slides[currentSlide - 1]?.videoLink"></video>

              <img v-if="presentation.slides[currentSlide - 1]?.translationLink" src="@/assets/icons/stream.svg" alt=""
                class="slide__img" />
              <img v-if="presentation.slides[currentSlide - 1]?.fileLink" :src="presentation.slides[currentSlide - 1]?.fileLink
                ? presentation.slides[currentSlide - 1]?.fileLink
                : ''" alt="" class="slide__img" />

              <!--            <div class="slide-description">-->
              <!--              <div class="slide-info">-->
              <!--                <p class="slide-number">Слайд #1</p>-->
              <!--                <input type="text" placeholder="Название слайда в презентации" class="slide-title">-->
              <!--                <input type="text" placeholder="Краткое описание слайда" class="slide-text">-->
              <!--              </div>-->
              <!--              <div class="slide-btns">-->
              <!--                <button class="slide-btn">-->
              <!--                  <img src="@/assets/icons/ok-green.svg" alt="" class="slide-btn-icon">-->
              <!--                </button>-->
              <!--                <button class="slide-btn">-->
              <!--                  <img src="@/assets/icons/delete-red.svg" alt="" class="slide-btn-icon">-->
              <!--                </button>-->
              <!--              </div>-->
              <!--            </div>-->
              <div class="interactment"
                :class="{ 'training-backlight training-backlight--active': educationStep === 2 }">
                <img
                  v-if="!presentation.slides[currentSlide - 1]?.fileLink && !presentation.slides[currentSlide - 1]?.videoLink && !presentation.slides[currentSlide - 1]?.translationLink"
                  src="@/assets/icons/robot.png" alt="" class="interactment-img">
                <p v-if="!presentation.slides[currentSlide - 1]?.fileLink && !presentation.slides[currentSlide - 1]?.videoLink && !presentation.slides[currentSlide - 1]?.translationLink"
                  class="interactment-title title">{{ $t("lectureCreation.interactment.info") }}
                </p>
                <p v-if="!presentation.slides[currentSlide - 1]?.fileLink && !presentation.slides[currentSlide - 1]?.videoLink && !presentation.slides[currentSlide - 1]?.translationLink"
                  class="interactment-text text">
                  {{ $t("lectureCreation.interactment.format") }}
                </p>
                <div class="interactment-list">
                  <button class="link-default">
                    {{ $t("lectureCreation.interactment.image") }}

                    <input type="file" accept="image/*" @change="loadPhotoFile" class="img-file" />
                  </button>
                  <button class="link-default">
                    <input type="file" accept="video/*" @change="loadVideoFile" class="img-file" />
                    {{ $t("lectureCreation.interactment.url") }}
                  </button>
                  <button class="link-default" @click="viewTranslationLink = true">
                    {{ $t("lectureCreation.interactment.stream") }}
                  </button>
                  <div class="stream-add" v-if="viewTranslationLink">
                    <input type="text" placeholder="Вставьте ссылку трансляции" class="stream-add__input"
                      v-model.trim="translationLink">
                    <div class="buttons">
                      <button class="link-default" @click="saveTranslationLink">
                        Добавить
                      </button>
                      <button class="link-default link-default__cancel" @click="viewTranslationLink = false">
                        Отмена
                      </button>
                    </div>
                  </div>
                  <p class="interactment-list__text">Максимальный размер файла: 1.5 Гб </p>
                </div>
                <p v-if="!presentation.slides[currentSlide - 1]?.fileLink && !presentation.slides[currentSlide - 1]?.videoLink && !presentation.slides[currentSlide - 1]?.translationLink"
                  class="interactment-end-text text">
                  {{ $t("lectureCreation.interactment.nothing") }}</p>
              </div>
            </div>
            <button class="end-btn link-default" @click="savePresentation">
              {{ $t("lectureCreation.interactment.save") }}
            </button>
          </div>
        </div>
      </div>

      <div class="save-wrapper" v-if="closeAlertView">
        <div class="save">
          <div class="save-caption">
            <p class="save-caption__text">{{ $t("lectureCreation.saving.title") }}</p>
            <button class="save-caption__close" @click="
              closeAlertView = !closeAlertView; exitRoute = null;"></button>
          </div>
          <p class="save-text text">
            {{ $t("lectureCreation.saving.question") }}
          </p>
          <div class="save-end">
            <button class="link-default" @click="savePresentation">
              {{ $t("lectureCreation.saving.end") }}
            </button>
            <button class="save-end-exit link-default link-default__disabled" @click="exitFromPage">
              {{ $t("lectureCreation.saving.exit") }}
            </button>
          </div>
        </div>
      </div>
      <new-page-training v-if="educationStep != null" :education-step="educationStep" @next="nextStep(false)"
        @close="nextStep(true)" page="presentations"></new-page-training>
    </div>
  </section>
</template>

<script>
import NewSeparateLectionPreview from "@/components/guide/NewSeparateLectionPreview.vue";
import { computed, onBeforeMount, onUnmounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { onBeforeRouteLeave } from "vue-router";
import router from "@/router";
import Loading from "vue-loading-overlay";
import NewCaptionComponent from '@/components/general/NewCaptionComponent.vue';
import NewPageTraining from "@/components/general/NewPageTraining.vue";

export default {
  name: "NewLecturesCreationPage",
  components: {
    NewPageTraining,
    NewSeparateLectionPreview,
    Loading,
    NewCaptionComponent
  },
  props: ["id"],
  setup(props) {
    const store = useStore();

    const commit = (name, data) => {
      saveTrigger.value = false;
      store.commit(name, data);
    };

    const presentation = computed(() => store.getters.presentation);
    const currentSlide = ref(1);
    const saveTrigger = ref(true);
    const closeAlertView = ref(false);
    const exitRoute = ref(null);

    const translationLink = ref('');

    const saveTranslationLink = () => {
      store.commit('setFileUrl', { index: currentSlide.value - 1, url: translationLink.value, type: 'translation' });
      viewTranslationLink.value = false;
    }

    const viewTranslationLink = ref(false);

    watch(() => viewTranslationLink.value, (val) => {
      if (!val) {
        translationLink.value = '';
      }
    });


    const educationStep = ref(sessionStorage.getItem('education') === 'true' ? 1 : null);

    const nextStep = (skipEducation) => {
      if (skipEducation) {
        sessionStorage.removeItem('education');
        educationStep.value = null;
        return;
      }

      if (educationStep.value === 2) {
        router?.push({ name: 'gamePage' });
        return
      }
      educationStep.value++;
    }

    onBeforeMount(() => store.dispatch("fetchPresentations")
      .then(() => store.commit("setCurrentPresentation", props.id)));

    const loadPhotoFile = (e) => {
      if (!e?.target?.files?.length) return;
      commit("addPresentationFilesQueue", {
        index: currentSlide.value,
        file: e.target.files[e.target.files.length - 1],
        type: "file",
      });
    };
    const loadVideoFile = (e) => {
      if (!e?.target?.files?.length) return;
      commit("addPresentationFilesQueue", {
        index: currentSlide.value,
        file: e.target.files[e.target.files.length - 1],
        type: "video",
      });
    };
    const loadPDF = (e) => {
      saveTrigger.value = false;
      store.dispatch("loadPDF", e.target.files[e.target.files.length - 1]);
    };

    watch(() => presentation?.value?.slides?.length, (value, oldValue) => {
      if ((value > oldValue) || (value < oldValue && currentSlide.value >= oldValue)) {
        currentSlide.value = value;
      }
    })

    window.onbeforeunload = (event) => {
      if (!saveTrigger.value) {
        closeAlertView.value = true;
        event.preventDefault();
        return (event.returnValue = "");
      }
    };


    onBeforeRouteLeave((to) => {
      if (!saveTrigger.value) {
        exitRoute.value = to;
        closeAlertView.value = true;
        return false;
      }
    });

    onUnmounted(() => {
      window.onbeforeunload = null;
    });

    return {
      closeAlertView,
      slideEditorView: ref(false),
      presentation,
      loadPhotoFile,
      loadPDF,
      loadVideoFile,
      currentSlide,
      commit,
      pickSlide: (order) => (currentSlide.value = order),
      savePresentation: () => {
        saveTrigger.value = true;
        store.dispatch("savePresentations");
      },
      exitFromPage: () => {
        saveTrigger.value = true;
        router.push({ name: exitRoute.value.name });
      },
      exitRoute,
      loader: computed(() => store.getters.loader),

      educationStep,
      nextStep,

      translationLink,
      viewTranslationLink,
      saveTranslationLink
    };
  },
};
</script>

<style scoped>
.section {
  min-height: calc(100vh - 189px);
  padding: 3rem 0;
}

.filling {
  padding: 2.5rem 2rem 2rem 2rem;
  border-radius: 1.5rem;
  background: #FFF;
}

.loader {
  position: fixed;
  z-index: 10;
  width: 100vw;
  height: 100dvh;
  background: rgba(0, 0, 0, 0.3);
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}


video {
  position: relative;
}

.slides {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 20rem;
  width: 100%;
}

.subtitle {
  margin-top: 1rem;
}

.lectures-caption {
  margin-top: 1.5rem;
  display: flex;
  gap: 1.25rem;
  max-width: 100%;
  width: 100%;
  align-items: flex-start;
}

.name {
  display: flex;
  align-items: center;
  max-width: 100%;
  width: 100%;
  border-radius: 1rem;
  background: #ECF2FA;
  gap: 1rem;
  padding: 1.5rem;
}

.name-input {
  margin-top: 0;
  padding: 0;
  border: none;
  background: none;
}

.pdf-text {
  margin-top: 0.5rem;
  text-align: center;
}

.creation {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  margin-top: 1rem;
}

.list-title {
  margin-top: 1.5rem;
  font-weight: 600;
  color: #000;
}

.caption-element__icon {
  width: 24px;
  height: 24px;
  padding: 3px;
}

.caption-element__download {
  border-color: #f94b25;
  max-width: 246px;
}

.caption-element__text {
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #f94b25;
}

.end {
  display: flex;
  align-items: center;
  gap: 77px;
  margin-top: 13px;
  justify-content: flex-end;
}

.end__btn {
  padding: 14px 16px;
  background: #003099;
  border: 1px solid #003099;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 345px;
  width: 100%;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
}

.slide-filling {
  position: relative;
  max-width: 100%;
  width: 100%;
}

.slide__img {
  border-radius: 1rem 1rem 0 0;
  height: auto;
  width: 100%;
  max-width: 100%;
}

.slide-wrapper {
  max-width: 100%;
  width: 100%;
  border-radius: 20px;
  border-radius: 1rem;
  background: #ECF2FA;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.interactment {
  padding: 3rem 1rem 2rem 1rem;
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  width: 100%;
}

.interactment-title {
  margin-top: 1.5rem;
}

.interactment-text {
  margin-top: 0.5rem;
}

.interactment-list {
  margin-top: 2rem;
  max-width: 23.625rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.interactment-list__text {
  text-align: center;
  color: rgba(0, 0, 0, 0.60);
}

.buttons {
  margin-top: 0.5rem;
  display: flex;
  gap: 0.5rem;
}

.link-default__cancel {
  background: #fff;
  color: #8B98AA;
}

.link-default {
  position: relative;
}

.link-default input {
  top: 0;
  margin-top: 0;
}

.interactment-img {
  width: 4.5rem;
  height: 4.5rem;
}

.interactment-end-text {
  margin-top: 4rem;
  color: #9098A5;
  font-size: 0.875rem;
}

.img-file {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  margin-top: 0;
  left: 0;
  cursor: pointer;
}

.interactment__back {
  width: 37px;
  height: 37px;
  background: url("@/assets/icons/slide-back.svg");
  background-size: cover;
  background-position: center;
}

.interactment__input {
  margin-top: 0;
  border: none;
  padding: 0;
  color: #007bfc;
}

.interactment__input::placeholder {
  color: rgba(0, 123, 252, 0.44999998807907104);
}

.slide-description {
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
  border-radius: 0rem 0rem 1rem 1rem;
  background: #FFF;
  padding: 1rem 1.5rem 1.5rem 1.5rem;
}

.slide-btns {
  display: flex;
  gap: 0.5rem;
}

.slide-info {
  max-width: 40rem;
  width: 100%;
}

/* save */
.save-wrapper {
  background: rgba(16, 24, 40, 0.60);
  z-index: 100;
  position: fixed;
  max-width: 100%;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  left: 0;
  top: 0;
  display: flex;
}

.save {
  margin: auto;
  max-width: 41.25rem;
  width: 100%;
  border-radius: 1.5rem;
  background: #FFF;
  box-shadow: 0px 10px 24px 0px rgba(69, 94, 128, 0.67);
  padding: 2.5rem 2rem;
}

.save-caption {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.save-caption__text {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #13151a;
}

.save-caption__close {
  background-image: url("@/assets/icons/close-default.svg");
  background-size: contain;
  background-color: #f5f6f7;
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
}

.save-filling {
  padding: 16px 0 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid #e6e9ed;
  border-bottom: 1px solid #e6e9ed;
  gap: 32px;
}

.save-text {
  margin-top: 2rem;
}

.save-end {
  margin-top: 2.5rem;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
}

.save-end__agree {
  background: #003099;
  border: 1px solid #0c6bf2;
  border-radius: 8px;
  padding: 12px 36px 14px 36px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
}

.save-end__close {
  padding: 12px 36px 14px 36px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #003099;
  border: 1px solid #0c6bf2;
  border-radius: 8px;
}

.training-backlight {
  position: relative;
  transition: all 0.5s ease;
}

.training-backlight--active {
  z-index: 8;
  background: #fff;
  border-radius: 1rem;
  padding: 1rem;
}

.save-end-exit {
  pointer-events: all;
}
</style>
