<template>
  <div class="prevue-container">
    <button @click="pickPresentation" class="prevue" :class="{ 'prevue-selected': selected }">
      <img :src="presentation?.slides?.length ? (presentation?.slides[0]?.fileLink ? presentation?.slides[0]?.fileLink : presentation?.slides[0]?.videoLink ? require('@/assets/icons/Play.png')  : presentation?.slides[0]?.translationLink ? require('@/assets/icons/stream.svg') : require('@/assets/icons/small-prevue.svg')) : require(`@/assets/icons/small-prevue.svg`)" :class="{'video':presentation?.slides[0]?.videoLink}" alt="Превью" class="prevue__img" />
<!--      <img v-if="presentation.slides.length && presentation.slides[0].videoLink" src="@/assets/icons/Play.png"-->
<!--        alt="Превью" class="prevue__img video" />-->
      <div class="prevue-selected__bg">

      </div>
      <img src="@/assets/icons/selected.svg" alt="" class="prevue-selected__bg-icon" />
    </button>
    <div class="prevue-info">
      <div class="prevue-info-left">
        <p class="prevue-info-left__title">{{ presentation.name }}</p>
        <p class="prevue-info-left__text">{{ role === 'lecture' ? 'Зеленый театр' : role === 'guide' ? 'Экскурсия' : ''}}</p>
      </div>
      <button @click="
        router.push({
          name: 'presentationEditorPage',
          params: { id: presentation.id },
        })
        " class="prevue-info__change"></button>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";

export default {
  name: "SeparateLectionPreviewBig",
  props: ["presentation", "selected",'role'],
  setup(props, { emit }) {
    return {
      router: useRouter(),
      pickPresentation: () => emit("pickPresentation", props.presentation.id),
    };
  },
};
</script>

<style scoped>
.prevue-container {
  max-width: 100%;
  width: 100%;
  position: relative;
  min-height: 194px;
  border-radius: 12px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
}

.prevue {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
}

.prevue__img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 12px;
}

.video {
  background: #000;
  object-fit: contain;
  object-position: center;
}

.prevue-info {
  min-height: 4.9375rem;
  margin-top: auto;
  z-index: 5;
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  background: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(11px);
  color: #fff;
  padding: 1rem;
  border-radius: 0rem 0rem 1rem 1rem;
}

.prevue-info-left {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
  gap: 0.5rem;
}

.prevue-info-left__title {
  font-size: 1.125rem;
  font-weight: 700;
}

.prevue-info-left__text {
  font-size: 0.875rem;
}

.prevue-info__change {
  width: 24px;
  height: 24px;
  background: url("@/assets/icons/change.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.prevue-selected__bg {
  display: none;
}

.prevue-selected__bg-icon {
  display: none;
}

.prevue-selected .prevue-selected__bg {
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 5;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%);
  border-radius: 12px;
}

.prevue-selected .prevue-selected__bg-icon {
  display: flex;
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 2rem;
  height: 2rem;
  z-index: 6;
}
</style>
