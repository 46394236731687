<template>
  <div class="info-wrapper">
    <div class="info-content">
      <div class="info">
        <div class="img-wrapper">
          <button class="close desktop" @click="closeWindow">
            <img src="@/assets/icons/close-main.svg" alt="Закрыть" class="close-white"/>
          </button>
          <p class="category">{{ exhibit?.category }}</p>
          <img :src="exhibit?.imgUrl" alt="" class="img"/>
        </div>
        <div class="content">
          <div class="caption">
            <p class="title">{{ locale === 'en' ? exhibit?.name_eng : exhibit?.name }}</p>
            <button class="close mobile" @click="closeWindow">
              <img src="@/assets/icons/close-main.svg" alt="Закрыть" class="close-white"/>
            </button>
          </div>
          <p class="text">{{
              (locale === 'en' ? exhibit?.description_eng : exhibit?.description)
            }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {appConfig} from "@/lib/appConfig";

export default {
  name: "NewCosmosObject",
  props: ['exhibit', 'locale'],
  setup(props, {emit}) {
    const closeWindow = () => {
      emit('closeWindow');
    }

    return {
      closeWindow,
      appConfig
    }
  }
}
</script>

<style scoped>
.info-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  pointer-events: none;
}

.info-content {
  pointer-events: all;
  position: absolute;
  z-index: 100;
  left: 1rem;
  bottom: 5.5rem;
  max-width: 25rem;
  width: 100%;
  border-radius: 0.5rem;
  background: rgba(157, 196, 248, 0.30);
  backdrop-filter: blur(4px);
  padding: 0.62rem;
  height: 75vh;
  height: 75dvh;
}

.img-wrapper {
  position: relative;
  max-width: 100%;
  width: 100%;
  padding-bottom: 56.5%;
}

.img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  max-width: 100%;
  width: 100%;
  background: #ECF2FA;
  border-radius: 0.5rem;
  object-fit: cover;
  object-position: center;
}

.info {
  border-radius: 0.5rem;
  background: #FFF;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}

.close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 2;
}


.category {
  display: inline;
  padding: 0.5rem;
  border-radius: 0.5rem;
  color: #FFF;
  font-size: 0.75rem;
  line-height: 1;
  left: 0.5rem;
  top: 0.5rem;
  position: absolute;
  z-index: 2;
  background: #003099;
}

.title {
  margin-top: 0.5rem;
  font-size: 1rem;
}

.text {
  margin-top: 0.5rem;
  height: 100%;
  overflow: auto;
  scrollbar-width: thin;
  font-size: 0.75rem;
}

.content {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

@media (max-width: 1000px) {
  .info-wrapper {
    padding: 0 3rem;
    align-items: center;
    display: flex;
    z-index: 6;
  }

  .info-content {
    bottom: unset;
    left: unset;
    max-width: 100%;
    width: 100%;
    position: relative;
    height: calc(100% - 8rem);
    padding: 0.37rem;
  }

  .info {
    align-items: flex-start;
    flex-direction: row;
    padding: 0.5rem;
    gap: 0.81rem;
  }

  .close {
    position: relative;
    top: unset;
    right: unset;
  }

  .content {
    height: 100%;
  }

  .caption {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }

  .img-wrapper {
    max-width: 10rem;
    flex-shrink: 0;
    padding-bottom: 0;
    height: 10rem;
  }

  .category {
    padding: 0.4rem;
    font-size: 0.6rem;
    line-height: 1;
    left: 0.25rem;
    top: 0.25rem;
  }

  .title {
    margin-top: 0;
  }


}
</style>
