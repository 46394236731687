export function convertTimeInMinuteAndSeconds(time) {
    let hours = Math.floor(time / 60 / 60);

    let minutes = Math.floor(time / 60) - (hours * 60);

    let seconds = time % 60;

    return (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes) + ':' + (seconds < 10 ? '0' + seconds : seconds);
}

export const ageCalculation = function (birth) {
    if (!birth) return 'Не известно';
    let years = Math.abs(
        new Date(
            Date.now() - new Date(birth).getTime() // 1. Get the difference as new Date object

        ).getUTCFullYear() - 1970); // 2. Calculate the years

    const residue = years % 10;

    if (residue === 1) years += ' год';
    else if (residue > 1 && residue < 5) years += ' года';
    else years += ' лет';

    return years
}

export function convertSecondsToMinutes(time) {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    return `${minutes}:${seconds}`
}


export function timeStringFormat(ms) {
    const minutes = Math.floor((ms / 1000) / 60);

    if (minutes === 0) {
        const seconds = Math.floor((ms / 1000) % 60);
        return `${seconds} сек`
    }

    if (minutes >= 60) {
        const hour = Math.floor(minutes / 60);
        if (hour >= 24) {
            const days = Math.floor(hour / 24);
            if (days > 0) {
                return `${days} д`
            }
        }
        return `${hour} ч`;
    }

    return `${minutes} мин`
}