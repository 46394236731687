<template>
  <div class="caption">
    <div class="caption-filling">
      <div class="name-wrapper">
        <button class="back" @click="router.go(-1)">
          <img src="@/assets/icons/back.svg" alt="" class="back-icon"/>
        </button>
        <h1 class="name">
          {{
            route.name === "calendarPage"
                ? $t("components.caption.eventsTitle") :
                route.name === "newMapPage"
                    ? $t("components.caption.mapTitle") :
                    route.name === "settingsPage"
                        ? $t("components.caption.settingsTitle") :
                        route.name === "presentationEditorPage"
                            ? $t("components.caption.creationTitle") :
                            route.name === "presentationsPage"
                                ? $t("components.caption.editorTitle") : route.name === "placeSelect"
                                    ? $t("components.caption.placeSelectTitle") : route.name === "newEventCreation"
                                        ? 'Мероприятия' : ''
          }}
        </h1>
      </div>
      <p class="text caption__text" v-if="$route.name === 'newMapPage'">
        {{ $t("components.caption.info") }}
      </p>
      <div class="navigation-wrapper" v-if="route.name === 'calendarPage'">
        <nav class="navigation" v-if="route.name === 'calendarPage'">
          <button v-if="role!=='guide'" @click="emit('selectCalendarType', 'lecture')" class="navigation-element"
                  :class="{ 'navigation-selected': calendarType === 'lecture' }">
            {{ $t("components.caption.lecture") }}
            <!--            <p class="navigation-element__number" v-if="calendarType === 'lecture'">42</p>-->
          </button>
          <button v-if="role!=='lecture'" @click="emit('selectCalendarType', 'excursion')" class="navigation-element"
                  :class="{ 'navigation-selected': calendarType === 'excursion' }">
            {{ $t("components.caption.excursion") }}
            <!--            <p class="navigation-element__number" v-if="calendarType === 'excursion'">4</p>-->
          </button>
          <button v-if="role!=='lecture' && role !=='guide'" @click="emit('selectCalendarType', 'broadcast')"
                  class="navigation-element"
                  :class="{ 'navigation-selected': calendarType === 'broadcast' }">
            {{ $t("components.caption.broadcast") }}
            <!--            <p class="navigation-element__number" v-if="calendarType === 'broadcast'">4</p>-->
          </button>
         <button v-if="role!=='lecture' && role !=='guide'" @click="emit('selectCalendarType', 'exhibition')"
                  class="navigation-element"
                  :class="{ 'navigation-selected': calendarType === 'exhibition' }">
            {{ $t("components.caption.exhibition") }}
            <!--            <p class="navigation-element__number" v-if="calendarType === 'broadcast'">4</p>-->
          </button>
          <button v-if="role!=='lecture'" @click="emit('selectCalendarType', 'record')" class="navigation-element"
                  :class="{ 'navigation-selected': calendarType === 'record' }">
            {{ $t("components.caption.record") }}
          </button>
          <button @click="emit('selectCalendarType', 'dedicatedTour')"
                  class="navigation-element"
                  :class="{ 'navigation-selected': calendarType === 'dedicatedTour' }">
            {{ $t("components.caption.dedicatedTour") }}
            <!--            <p class="navigation-element__number" v-if="calendarType === 'broadcast'">4</p>-->
          </button>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import {useRoute, useRouter} from "vue-router";
import {ref} from "vue";

export default {
  name: "NewCaptionComponent",
  props: ["calendarType", "role"],
  setup(props, {emit}) {
    return {
      route: useRoute(),
      router: useRouter(),
      viewSortParams: ref(false),
      emit,
    };
  },
};
</script>

<style scoped>
.caption-filling {
  max-width: 100%;
  width: 100%;
}

.back {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  flex-shrink: 0;
  align-items: center;
}

.name-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.name {
  font-weight: 700;
  font-size: 2rem;
  line-height: 1;
  color: #13151a;
}

.caption__text {
  margin-top: 1rem;
}

.navigation-wrapper {
  max-width: 100%;
  width: 100%;
  overflow: auto;
  scrollbar-width: thin;
  margin-top: 2rem;
}

.navigation {
  display: flex;
  flex-shrink: 0;
  gap: 3.5rem;

}

.navigation-element {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0 0 1rem 0;
  font-weight: 400;
  font-size: 1.25rem;
  flex-shrink: 0;
}

.navigation-element__number {
  display: none;
}

.navigation-selected {
  color: #e61740;
  border-bottom: 2px solid #D32136;
}

.navigation-element__number {
  font-size: 0.75rem;
  font-weight: 500;
  color: #ffffff;
  background: #e51740;
  border-radius: 100%;
  width: 1.5rem;
  height: 1.4375rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1000px) {

  .name {
    font-size: 1.5rem;
  }

  .navigation-wrapper {
    margin-top: 1rem;
  }

  .navigation {
    gap: 1rem;
  }

  .navigation-element {
    font-size: 0.875rem;
    padding-bottom: 0.5rem;
  }
}
</style>
